import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import Layout1 from "./Layout Component/Layout1";
import Layout2 from "./Layout Component/Layout2";
import { getProfileData, getProfileB2C } from "./services/ProfileService";
import { setUser, setLoader } from "./Store/Reducers/UserReducer";


export default function App() {
  const [rout, setRout] = useState();
  const [log, setLog] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const profileDetail = async () => {
    try{
      
      dispatch(setLoader(true));
      const data = await getProfileB2C();
      const res = await getProfileData(data.content.id)
      dispatch(setUser(res.content));
      if(res.content)
        dispatch(setLoader(false));
    }catch(e){
    }
  }

  useEffect(() => {
    
    setLog(sessionStorage.getItem('isLoggedIn'))
    if(log){
      profileDetail();
    }
  }, [rout, log])

  if (log) {
    return <Layout1 setRout={setRout} isLoading={isLoading}/>
  }
  else {
    return <Layout2 setRout={setRout}/>
  }
}
