import React, { useState, useEffect } from 'react'
import './stepHead.css';

export default function StepHead(props) {

    const [steps, setSteps] = useState([
        { key: 'firstStep', label: 'Step1' },
        { key: 'thirdStep', label: 'Step2' },
        { key: 'fourthStep', label: 'Step3' },
        { key: 'fifth', label: 'Step4' },
        { key: 'sixthStep', label: 'Step5' },
    ]);

    useEffect(() => {
    }, []);
    

    return (
        <>
            <div className="wrapper ">
                <div className="arrow-steps clearfix pb-1  m-2  ">
                    {
                        steps.map((step, i) => {
                            return (
                                <div key={i}  className={`step ${props.step > i ? 'current' : (props.step === i) ? 'done' : props.PP ? 'current' : ''}  pointer`}> <span>{step.label}</span> </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}
