import axios from "axios";
import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'react-phone-number-input/style.css';
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import LoadingSpinner from './Layout Component/LoadingSpinner';
import "./Register.css";
import { useNavigate } from "react-router";
import $ from "jquery";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GettheHmToken } from "./services/AllApiCall";
import { setLoader } from './Store/Reducers/UserReducer';
import { useDispatch } from "react-redux";

export default function Register() {
    const regex = /^[a-zA-Z0-9\s]*$/;
    const regexEmail = /[<>&"']/;
    const dispatch = useDispatch();
    //For Checkbox fields //
    const [Show, setShow] = useState(true);
    const [Source, setSource] = useState();
    const [Width, setWidth] = useState();
    const [ImgUrl, setImgUrl] = useState();
    const [ivf, setivf] = useState("");
    const [icsi, seticsi] = useState("");
    const [iui, setiui] = useState("");
    const [spermdonor, setspermdonor] = useState("");
    const [surgicalspermdonor, setsurgicalspermdonor] = useState("");
    const [bloodtest, setbloodtest] = useState("");
    const [spermtest, setspermtest] = useState("");
    const [eggdonor, seteggdonor] = useState("");
    const [patientevening, setpatientevening] = useState("");
    const [eggrecipient, seteggrecipient] = useState("");
    const [consultation, setconsultation] = useState("");
    const [donateyoureggs, setdonateyoureggs] = useState("");
    const [investigation, setinvestigation] = useState("")

    //state for switch button content 
    const [content, setcontent] = useState(false);
    //state for address lookup field
    const [address, setAddress] = React.useState("");
    const [addressGP, setaddress1] = React.useState("");
    const [addressPGP, setaddressPGP] = React.useState("");
    //state for address to,locate at different fileds
    const [street, setStreet] = React.useState("");
    const [houseno, sethouseno] = React.useState("");
    const [country, setcountry] = React.useState("");
    const [city, setcity] = React.useState("");
    const [stat, setstate] = React.useState("");
    const [postal, setpostal] = React.useState("");

    //state for address to,locate at different fileds
    const [street1, setStreet1] = React.useState("");
    const [country1, setcountry1] = React.useState("");
    const [city1, setcity1] = React.useState("");
    const [state1, setstate1] = React.useState("");
    const [postal1, setpostal1] = React.useState("");
    const [county, setcounty] = React.useState("");


    // partner GP address
    const [streetPGP, setStreetPGP] = React.useState("");
    const [countryPGP, setcountryPGP] = React.useState("");
    const [cityPGP, setcityPGP] = React.useState("");
    const [statePGP, setstatePGP] = React.useState("");
    const [postalPGP, setpostalPGP] = React.useState("");


    const [checkstreet, getStreet] = React.useState("");
    const [checkhouseno, gethouseno] = React.useState("");
    const [checkcountry, getcountry] = React.useState("");
    const [checkcity, getcity] = React.useState("");
    const [checkstate, getstate] = React.useState("");
    const [checkpostal, getpostal] = React.useState("");
    const [checkcounty, getcounty] = React.useState("");
    //state for partner GP autofill with patient gp
    // state for form field to submit
    const url = process.env.REACT_APP_API_URL;
    const [mainInterest, setmainInterest] = React.useState("");
    const [tiTitletle, setTitle] = React.useState("");
    const [firstName, setfirstName] = React.useState("");
    const [lastName, setlastName] = React.useState("");
    const [emailaddress1, setemailaddress1] = React.useState("");
    const [gendercode, setgendercode] = React.useState("");
    const [bcrm_whatgenderdoyouidentifiedas, setbcrm_whatgenderdoyouidentifiedas] = React.useState("");
    const [birthdate, setbirthdate] = React.useState("");
    const [mobilephone, setmobilephone] = React.useState("");
    const [homePhone, sethomePhone] = React.useState("");
    const [businessPhone, setbusinessPhone] = React.useState("");
    const [patientFunding, setpatientFunding] = React.useState("");
    const [nhsNumber, setnhsNumber] = React.useState("");
    const [previousTreatment, setpreviousTreatment] = React.useState("");
    const [patientGroups, setpatientGroups] = React.useState("");
    const [Password, setPassword] = React.useState("");
    const [GPName, setGPName] = React.useState("");
    const [PatientGPSurgeryName, setPatientGPSurgeryName] = React.useState("");
    const [confirmPassword, setconfirmPassword] = React.useState("");
    const [PName, setPName] = React.useState("");
    const [PLName, setPLName] = React.useState("");
    const [Pdob, setPdob] = React.useState("");
    const [PGender, setPGender] = React.useState("");
    const [PREfered, setPREfered] = React.useState("");
    const [PReferraladdress, setPReferraladdress] = React.useState("");
    const [PEmail, setPEmail] = React.useState("");
    const [PartnerAddressSameasPatient] = React.useState("");
    const navigate = useNavigate();
    //mail password authentication
    const [passwordType, setPasswordType] = useState("password");
    const handlePasswordChange = (e) => {
        setconfirmPassword(e.target.value);
    }

    //partner's GP
    const [checkPartnerGPName, getcheckPartnerGPName] = React.useState("");
    const [checkPartnerGPSurgeryName, getcheckPartnerGPSurgeryName] = React.useState("");
    // state for phonesleect
    // for loading spinner
    const [isLoading, setIsLoading] = useState(false);
    const { useEffect } = React;
    useEffect(() => {
        
        GettheHmToken();
    }, []);


    //checkbox value 
    const setdata = (e) => {
        
        if (e === true) {
            gethouseno(houseno);
            $("#floatingInputGrid22").val(houseno);
            getStreet(street);
            $("#floatingInputGrid23").val(street);
            getcountry(country);
            $("#country_Region").val(country);
            getcity(city);
            $("#floatingInputGrid24").val(city);
            getstate(stat)
            $("#floatingInputGrid25").val(stat);
            getpostal(postal);
            $("#floatingInputGrid26").val(postal);
            getcounty(county);
            $("#floatingInputGridcounty").val(county);
        } else {
            
            gethouseno("");
            $("#floatingInputGrid22").val("");
            getStreet("");
            $("#floatingInputGrid23").val("");
            getcountry("");
            $("#country_Region").val("");
            getcity("");
            $("#floatingInputGrid24").val("");
            getstate("")
            $("#floatingInputGrid25").val("");
            getpostal("");
            $("#floatingInputGrid26").val("");
            getcounty("");
            $("#floatingInputGridcounty").val("");
        }
    }
    const setGPData = (e) => {
        
        if (e === true) {
            setStreetPGP(street1);
            $("#floatingInputGrid30").val(street1);
            setcountryPGP(country1);
            $("#floatingInputGrid33").val(country1);
            setcityPGP(city1);
            $("#floatingInputGrid31").val(city1);
            setstatePGP(state1);
            $("#floatingInputGrid32").val(state1);
            setpostalPGP(postal1);
            $("#floatingInputGrid34").val(postal1);
            setaddressPGP(addressGP);
            getcheckPartnerGPName(GPName)
            $("#floatingInputGrid28").val(GPName);
            getcheckPartnerGPSurgeryName(PatientGPSurgeryName)
            $("#floatingInputGrid29").val(PatientGPSurgeryName);

        } else {
            setStreetPGP("");
            $("#floatingInputGrid30").val("");
            setcountryPGP("");
            $("#floatingInputGrid33").val("");
            setcityPGP("");
            $("#floatingInputGrid31").val("");
            setstatePGP("");
            $("#floatingInputGrid32").val("");
            setpostalPGP("");
            $("#floatingInputGrid34").val("");
            setaddressPGP("");
            getcheckPartnerGPName("");
            $("#floatingInputGrid28").val("");
            getcheckPartnerGPSurgeryName("");
            $("#floatingInputGrid29").val("");
        }
    }

    // eye pass and mail
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")


    }

    // First Patient My Address Lookup Lookup //
    const handleSelect = async value => {
        
        const results = await geocodeByAddress(value);
        const setAddressvalue2 = results[0].address_components;
        var houseno1 = "";
        var Street1 = "";
        var country1 = "";
        var city1 = "";
        var State1 = "";
        var postalCode1 = "";

        setAddressvalue2.map((elm, i) => {

            if (elm.types[0] === "street_number") {
                sethouseno(setAddressvalue2[i].short_name);
                houseno1 = setAddressvalue2[i].short_name;
                $("#floatingInputGrid1").val(houseno1);
            }

            if (houseno1 === undefined || houseno1 === "") {
                sethouseno("");
                $("#floatingInputGrid1").val("");
            }

            if (elm.types[0] === "route") {
                setStreet(setAddressvalue2[i].short_name);
                Street1 = setAddressvalue2[i].short_name;
                $("#floatingInputGrid2").val(Street1);
            }

            if (Street1 === undefined || Street1 === "") {
                setStreet("");
                $("#floatingInputGrid2").val("");
            }


            if (elm.types[0] === "locality") {
                setcity(setAddressvalue2[i].long_name);
                city1 = setAddressvalue2[i].long_name;
                $("#floatingInputGrid3").val(city1);
            }

            if (city1 === undefined || city1 === "") {
                setcity("");
                $("#floatingInputGrid3").val("");
            }


            if (elm.types[0] === "administrative_area_level_1") {
                setstate(setAddressvalue2[i].long_name);
                State1 = setAddressvalue2[i].long_name
                $("#floatingInputGrid5").val(State1);
            }

            if (State1 === undefined || State1 === "") {
                setstate("");
                $("#floatingInputGrid5").val("");
            }


            if (elm.types[0] === "postal_code") {
                setpostal(setAddressvalue2[i].long_name);
                postalCode1 = setAddressvalue2[i].long_name;
                $("#floatingInputGrid6").val(postalCode1);
            }

            if (postalCode1 === undefined || postalCode1 === "") {
                setpostal("");
                $("#floatingInputGrid6").val("");
            }


            if (elm.types[0] === "country") {
                setcountry(setAddressvalue2[i].long_name);
                country1 = setAddressvalue2[i].long_name;
                $("#floatingInputGrid4").val(country1);
            }

            if (country1 === undefined || country1 === "") {
                setcountry("");
                $("#floatingInputGrid4").val("");
            }

            $("#floatingInputGrid7").val("");
            setcounty("");
            return true
        })
        setAddress(value);
    }
    // Second patient My GP information Lokkup//
    const handleSelect1 = async value => {
        
        const results = await geocodeByAddress(value);
        const setAddressvalue = results[0].address_components;
        var houseno2 = "";
        var Street2 = "";
        var country2 = "";
        var city2 = "";
        var State2 = "";
        var postalCode2 = "";
        setAddressvalue.map((elm, i) => {

            if (elm.types[0] === "street_number") {
                houseno2 = setAddressvalue[i].short_name;
            }

            if (houseno2 === undefined || houseno2 === "") {
                houseno2 = "";
            }

            if (elm.types[0] === "route") {
                Street2 = setAddressvalue[i].short_name
                setStreet1(houseno2 + " " + setAddressvalue[i].short_name);
                $("#floatingInputGrid11").val(houseno2 + " " + Street2);
            }

            if (Street2 === undefined || Street2 === "") {
                setStreet1(houseno2 + "");
                $("#floatingInputGrid11").val(houseno2 + "");
            }

            if (elm.types[0] === "locality") {
                setcity1(setAddressvalue[i].long_name);
                city2 = setAddressvalue[i].long_name;
                $("#floatingInputGrid12").val(city2);
            }

            if (city2 === undefined || city2 === "") {
                setcity1("");
                $("#floatingInputGrid12").val("");
            }

            if (elm.types[0] === "administrative_area_level_1") {
                setstate1(setAddressvalue[i].long_name);
                State2 = setAddressvalue[i].long_name;
                $("#floatingInputGrid13").val(State2);
            }

            if (State2 === undefined || State2 === "") {
                setstate1("");
                $("#floatingInputGrid13").val("");
            }

            if (elm.types[0] === "postal_code") {
                setpostal1(setAddressvalue[i].long_name);
                postalCode2 = setAddressvalue[i].long_name;
                $("#floatingInputGrid15").val(postalCode2);
            }

            if (postalCode2 === undefined || postalCode2 === "") {
                setpostal1("");
                $("#floatingInputGrid15").val("");
            }

            if (elm.types[0] === "country") {
                setcountry1(setAddressvalue[i].long_name);
                country2 = setAddressvalue[i].long_name;
                $("#floatingInputGrid14").val(country2);
            }

            if (country2 === undefined || country2 === "") {
                setcountry1("");
                $("#floatingInputGrid14").val("");
            }

            return true
        })
        setaddress1(value);
    }
    const handleSelectPGP = async value => {
        
        const results = await geocodeByAddress(value);
        const setAddressvalue = results[0].address_components;

        var houseno3 = "";
        var Street3 = "";
        var country3 = "";
        var city3 = "";
        var State3 = "";
        var postalCode3 = "";

        setAddressvalue.map((elm, i) => {

            if (elm.types[0] === "street_number") {
                houseno3 = setAddressvalue[i].short_name;
            }

            if (houseno3 === undefined || houseno3 === "") {
                houseno3 = "";
            }

            if (elm.types[0] === "route") {
                Street3 = setAddressvalue[i].short_name
                setStreetPGP(houseno3 + " " + setAddressvalue[i].short_name);
                $("#floatingInputGrid30").val(houseno3 + " " + setAddressvalue[i].short_name);
            }

            if (Street3 === undefined || Street3 === "") {
                setStreetPGP(houseno3 + "");
                $("#floatingInputGrid30").val(houseno3 + "");
            }


            if (elm.types[0] === "administrative_area_level_1") {
                setstatePGP(setAddressvalue[i].long_name);
                State3 = setAddressvalue[i].long_name
                $("#floatingInputGrid32").val(State3);
            }

            if (State3 === undefined || State3 === "") {
                setstatePGP("");
                $("#floatingInputGrid32").val("");
            }


            if (elm.types[0] === "locality") {
                setcityPGP(setAddressvalue[i].long_name);
                city3 = setAddressvalue[i].long_name;
                $("#floatingInputGrid31").val(city3);
            }

            if (city3 === undefined || city3 === "") {
                setcityPGP("");
                $("#floatingInputGrid31").val("");
            }

            if (elm.types[0] === "postal_code") {
                setpostalPGP(setAddressvalue[i].long_name);
                postalCode3 = setAddressvalue[i].long_name;
                $("#floatingInputGrid34").val(postalCode3);
            }

            if (postalCode3 === undefined || postalCode3 === "") {
                setpostalPGP("");
                $("#floatingInputGrid34").val("");
            }


            if (elm.types[0] === "country") {
                setcountryPGP(setAddressvalue[i].long_name);
                country3 = setAddressvalue[i].long_name;
                $("#floatingInputGrid33").val(country3);
            }

            if (country3 === undefined || country3 === "") {
                setcountryPGP("");
                $("#floatingInputGrid33").val("");
            }

            return true
        })
        setaddressPGP(value);
    }

    // funxtion call for gender autocomplete 
    const ChangetheValueforgender = (data) => {
        setgendercode(data)
        if (data === "1") {
            setbcrm_whatgenderdoyouidentifiedas('271400000');
        }
        else if (data === "2") {
            setbcrm_whatgenderdoyouidentifiedas('271400001');
        }
        else {
            setbcrm_whatgenderdoyouidentifiedas('271400002');
        }

    }
    // -------------------------------------------------------* API section* -------------------------------------------------------------------//
    const handleSubmit = async () => {
        
        if (emailaddress1 !== "") {
            dispatch(setLoader(true));
            let UserEmail = emailaddress1;
            let TokenHM = localStorage.getItem("HMTokenForApis");
            axios.get(`${url}/api/Leads/CheckEmailExist?email=${UserEmail}`,
                { headers: { "Authorization": TokenHM } }).then((response) => {
                    if (response.data.statusCode === 200) {
                        CheckPatientExist()
                    }
                    //  setIsLoading(false);

                }, (error) => {
                    if (error.response.data["errorMessage"] === "False") {
                        toast.error('Patient already exist', {
                            autoClose: 5000,
                            hideProgressBar: true,
                            newestOnTop: false,
                            closeOnClick: true,
                            rtl: false,
                            pauseOnFocusLoss: true,
                            draggable: true,
                            pauseOnHover: true,
                            theme: 'colored'
                        })
                        dispatch(setLoader(false));
                    }
                    if (error.response.data["errorMessage"] === "Token Expire") {
                        GettheHmToken();
                        dispatch(setLoader(false));
                    }

                    if (error.response.data["statusCode"] === 401) {
                        toast.error('Something went wrong', {
                            autoClose: 5000,
                            hideProgressBar: true,
                            newestOnTop: false,
                            closeOnClick: true,
                            rtl: false,
                            pauseOnFocusLoss: true,
                            draggable: true,
                            pauseOnHover: true,
                            theme: 'colored'
                        })
                        dispatch(setLoader(false));
                    }


                    dispatch(setLoader(false));
                });
        }

        else {
            toast.warn('Please fill mandatory fields', {
                autoClose: 5000,
                hideProgressBar: true,
                newestOnTop: false,
                closeOnClick: true,
                rtl: false,
                pauseOnFocusLoss: true,
                draggable: true,
                pauseOnHover: true,
                className: 'toast-warn'
            }
            )
            dispatch(setLoader(false));
            return
        }
    };

    const CheckPatientExist = async () => {
        

        if (tiTitletle !== "" && firstName !== "" && lastName !== "" && emailaddress1 !== "") {

            let postdata = {
                ivf: ivf,
                icsi: icsi,
                iui: iui,
                spermDonor: spermdonor,
                surgicalSpermRemoval: surgicalspermdonor,
                bloodTest: bloodtest,
                spermTest: spermtest,
                eggDonor: eggdonor,
                patientEvening: patientevening,
                eggRecipient: eggrecipient,
                consultation: consultation,
                donateYourEggs: donateyoureggs,
                Investigation: investigation,
                MainInterest: mainInterest,
                Title: tiTitletle,
                Forenames: firstName,
                Surname: lastName,
                EmailAddress: emailaddress1,
                Gender: gendercode,
                whatgenderdoyouidentifiedas: bcrm_whatgenderdoyouidentifiedas,
                DateofBirth: birthdate,
                MobilePhone: mobilephone,
                HomePhone: homePhone,
                BusinessPhone: businessPhone,
                PatientFunding: patientFunding,
                NHSNumber: nhsNumber,
                PreviousTreatment: previousTreatment,
                PatientGroups: patientGroups,
                Password: Password,
                ConfirmPassword: confirmPassword,
                HouseNo: houseno,
                Street: street,
                City: city,
                State: stat,
                Country: country,
                ZIP: postal,
                GPName: GPName,
                PatientGPSurgeryName: PatientGPSurgeryName,
                GPStreet: street1,
                GPCity: city1,
                GPState: state1,
                GPCountry: country1,
                GPZIP: postal1,
                county: county,
                // partner data
                PartnerAddressSameasPatient: PartnerAddressSameasPatient,
                PartnerFirstName: PName,
                PartnerSurname: PLName,
                PartnerDateOfBirth: Pdob,
                PartnerEmail: PEmail,
                PartnerGender: PGender,
                ReferralBy: PREfered,
                Referraladdress: PReferraladdress,
                //partner's  GP
                PartnerGPName: checkPartnerGPName,
                PartnerGPSurgeryName: checkPartnerGPSurgeryName,
                PartnerGPStreet: streetPGP,
                PartnerGPCity: cityPGP,
                PartnerGPState: statePGP,
                PartnerGPCountry: countryPGP,
                PartnerGPZIP: postalPGP,
                Source: Source,
                PartnerHouseNo: checkhouseno,
                PartnerStreet: checkstreet,
                PartnerCity: checkcity,
                PartnerCounty: checkcounty,
                PartnerState: checkstate,
                PartnerCountry: checkcountry,
                PartnerZIP: checkpostal,
            }

            dispatch(setLoader(true));
            axios.post(`${url}/api/Leads/Create`, postdata, { headers: { "Authorization": localStorage.getItem("HMTokenForApis") } }).then(
                (response) => {
                    var result = response.data;
                    if (result.statusCode === 200) {
                        toast.success('We will contact you shortly ', {
                            autoClose: 5000,
                            hideProgressBar: true,
                            newestOnTop: false,
                            closeOnClick: true,
                            rtl: false,
                            pauseOnFocusLoss: true,
                            draggable: true,
                            pauseOnHover: true,
                            className: 'toast-message'
                        }
                        )
                        // setIsLoading(true);
                        navigate('/Home');
                    }
                    dispatch(setLoader(false));
                },
                (error) => {
                    dispatch(setLoader(false));
                    if (error.response.data["errorMessage"] === "Token Expire") {
                        
                        GettheHmToken();
                    }
                    else {
                        toast.error('Error in register patient', {
                            autoClose: 5000,
                            hideProgressBar: true,
                            newestOnTop: false,
                            closeOnClick: true,
                            rtl: false,
                            pauseOnFocusLoss: true,
                            draggable: true,
                            pauseOnHover: true,
                            className: 'colored'
                        }
                        )
                    }
                    dispatch(setLoader(false));
                }
            );
        } else {
            toast.warn('Please fill mandatory fields', {
                autoClose: 5000,
                hideProgressBar: true,
                newestOnTop: false,
                closeOnClick: true,
                rtl: false,
                pauseOnFocusLoss: true,
                draggable: true,
                pauseOnHover: true,
                className: 'toast-warn'
            }
            )
            dispatch(setLoader(false));
            return
        }

    };

    const ClearDataonPartner = async (Event) => {
        
        if (Event.target.value !== "") {
            setPName("");
            setPLName("");
            setPEmail("");
            setPdob("");
            setPGender("");
            setPREfered("");
            gethouseno("");
            getStreet("");
            getcity("");
            getstate("");
            getcountry("");
            getpostal("");
            getcounty("");
            getcheckPartnerGPName("");
            getcheckPartnerGPSurgeryName("");
            setStreetPGP("");
            setcityPGP("");
            setstatePGP("");
            setcountryPGP("");
            setpostalPGP("");
            setaddressPGP("");
        }
    }
    const SelectClinic = async (imgU, source, autoWidth) => {
        setSource(source)
        setImgUrl(imgU)
        setShow(false);
        setWidth(autoWidth)
    }
    return (
        <div>
            {Show ?
                <div id="Profiledetails" class="" style={{ marginTop: '90px', marginBottom: '30px' }}>
                    <div className="card m-3">
                        <div className='card-header m-2 border contactusfont text-dark'>
                            <div className="row">
                                <div className="col-6">
                                    <h5></h5>
                                </div>
                                <div className="col-6 text-right">
                                    &nbsp;    <a type="submit" onClick={() => navigate(-1)}>
                                        <i style={{ fontSize: "24px" }} class="fa-solid fa-circle-arrow-left"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="row  justify-content-start" style={{ overflow: 'auto' }} >

                            <div className="col-md-2"></div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12 large" onClick={() => SelectClinic('assets/img/BCRM_logo_new.jpg', 1, "w-100")} 
                            >

                                <div class="card testimonial-card"> 
                                    <div class="card-footer">
                                        <div role="group" aria-label="..." class="btn-group special">
                                            Contact BCRM </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12 large" onClick={() => SelectClinic('assets/img/GOJ-Logo.png', 6, "w-75")}>
                                <div class="card testimonial-card">
                                    <div class="card-footer">
                                        <div role="group" aria-label="..." class="btn-group special">
                                            Contact GOJ </div>
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12 large" onClick={() => SelectClinic('assets/img/Logo-Jersey-Private-Patients.png', 7, "w-75")}>
                                <div class="card testimonial-card">
                                    <div class="card-footer">
                                        <div role="group" aria-label="..." class="btn-group special">
                                            Contact GOJP </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12 large" onClick={() => SelectClinic('assets/img/Cambridge-logo%20(1).png', 9, "w-100")}>
                                <div class="card testimonial-card">
                                    <div class="card-footer">
                                        <div role="group" aria-label="..." class="btn-group special"> Contact Cambridge IVF </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-2 pt-4 justify-content-start" style={{ overflow: 'auto' }}>
                            <div className="col-md-2"></div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12 large" onClick={() => SelectClinic('assets/img/Barts%20Health.jpg', 5, "w-60")}>
                                <div class="card testimonial-card"><div class="card-footer">
                                    <div role="group" aria-label="..." class="btn-group special">
                                        Contact Barts Health </div>
                                </div>
                                </div>
                            </div>


                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12 large" onClick={() => SelectClinic('assets/img/Monash-removebg-preview.png', 13, "w-65")}>
                                <div class="card testimonial-card">
                                    <div class="card-footer">
                                        <div role="group" aria-label="..." class="btn-group special">
                                            Contact Monash IVF </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12 large" onClick={() => SelectClinic('assets/img/kingston_logo.png', 14, "w-70")}>
                                <div class="card testimonial-card">
                                    <div class="card-footer">
                                        <div role="group" aria-label="..." class="btn-group special">
                                            Contact Kingston </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12 large" onClick={() => SelectClinic('assets/img/Access fertiltiy V2.png', 8, "")}>
                                <div class="card testimonial-card">
                                    <div class="card-footer">
                                        <div role="group" aria-label="..." class="btn-group special">
                                            Contact Access Fertility </div>
                                    </div>
                                </div>
                            </div><br />
                        </div>

                        <div className="row mb-2 pt-4 justify-content-start" style={{ overflow: 'auto' }}>
                            <div className="col-md-2"></div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12 large" onClick={() => SelectClinic('assets/img/Medistica%20Logo.png', 10, "w-75")}>
                                <div class="card testimonial-card">
                                    <div class="card-footer"><div role="group" aria-label="..." class="btn-group special">
                                        Contact Medistica
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div> :
                <div className='container ' style={{ marginTop: '90px', marginBottom: '30px' }}>
                    <div className='row mb-3'>
                        <div className='col-7 d-flex fs-5 '>
                            <br />
                            <a type="submit" onClick={() => setShow(true)}>
                                <i style={{ fontSize: "30px" }} class="fa-solid mt-4 fa-arrow-circle-left"></i>
                            </a><br /><br /><br />
                            <div className=''><img className={Width} src={ImgUrl} alt="" /></div>
                        </div>
                        <div class="card">
                            <div className="card-body">
                                <div className="card-text">
                                    <form>
                                        <div className="row mt-4">

                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-1">
                                                        <input type="checkbox" id="IVF" name="IVF" value="ivf" onChange={(e) => setivf(e.target.checked)} />
                                                    </div>
                                                    <div className="col-md-8">
                                                        <label for="IVF">IVF</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-1">
                                                        <input type="checkbox" id="IUI" name="IUI" value="iui" onChange={(e) => setiui(e.target.checked)} />
                                                    </div>
                                                    <div className="col-md-8">
                                                        <label for="IUI">IUI</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-1">
                                                        <input type="checkbox" id="ICSI" name="ICSI" value="icsi" onChange={(e) => seticsi(e.target.checked)} />
                                                    </div>
                                                    <div className="col-md-8">
                                                        <label for="ICSI">ICSI</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-1">
                                                        <input type="checkbox" id="SpermDonor" name="SpermDonor" value="spermdonor" onChange={(e) => setspermdonor(e.target.checked)} />
                                                    </div>
                                                    <div className="col-md-8">
                                                        <label for="SpermDonor">Sperm Donor</label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row mt-4">

                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-1">
                                                        <input type="checkbox" id="SurgicalSpermRemoval" name="SurgicalSpermRemoval" value="surgicalspermdonor" onChange={(e) => setsurgicalspermdonor(e.target.checked)} />
                                                    </div>
                                                    <div className="col-md-8">
                                                        <label for="SurgicalSpermRemoval">Surgical Sperm Removal</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-1">
                                                        <input type="checkbox" id="BloodTest" name="BloodTest" value="setbloodtest" onChange={(e) => setbloodtest(e.target.checked)} />
                                                    </div>
                                                    <div className="col-md-8">
                                                        <label for="BloodTest">Blood Test</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-1">
                                                        <input type="checkbox" id="SpermTest" name="SpermTest" value="spermtest" onChange={(e) => setspermtest(e.target.checked)} />
                                                    </div>
                                                    <div className="col-md-8">
                                                        <label for="SpermTest">Sperm Test</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-1">
                                                        <input type="checkbox" id="EggDonor" name="EggDonor" value="eggdonor" onChange={(e) => seteggdonor(e.target.checked)} />
                                                    </div>
                                                    <div className="col-md-8">
                                                        <label for="EggDonor">Egg Donor</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-1">
                                                        <input type="checkbox" id="PatientEvening" name="PatientEvening" value="Bike" onChange={(e) => setpatientevening(e.target.checked)} />
                                                    </div>
                                                    <div className="col-md-8">
                                                        <label for="PatientEvening">Patient Evening</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-1">
                                                        <input type="checkbox" id="Egg Recipient" name="EggRecipient" value="eggrecipient" onChange={(e) => seteggrecipient(e.target.checked)} />
                                                    </div>
                                                    <div className="col-md-8">
                                                        <label for="Egg Recipient">EggRecipient</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-1">
                                                        <input type="checkbox" id="Consultation" name="Consultation" value="consultation" onChange={(e) => setconsultation(e.target.checked)} />
                                                    </div>
                                                    <div className="col-md-8">
                                                        <label for="Consultation">Consultation</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-1">
                                                        <input type="checkbox" id="DonateYourEggs" name="DonateYourEggs" value="donateyoureggs" onChange={(e) => setdonateyoureggs(e.target.checked)} />
                                                    </div>
                                                    <div className="col-md-8">
                                                        <label for="DonateYourEggs">Donate Your Eggs</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-md-3">

                                                <div className="row">
                                                    <div className="col-md-1">

                                                        <input type="checkbox" id="Investigation" name="Investigation" value="investigation" onChange={(e) => setinvestigation(e.target.checked)} />
                                                    </div>
                                                    <div className="col-md-8">
                                                        <label for="Investigation">Investigation</label>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>


                                        <div className='row mt-3 '>
                                            <div className='col-md-6'>
                                                <div className="form-floating">
                                                    <textarea className="form-control h-25" placeholder="Main Interest" id="floatingTextarea" value={mainInterest}
                                                        onChange={(e) => setmainInterest(e.target.value)}></textarea>
                                                    <label htmlFor="floatingTextarea">Main Interest</label>
                                                </div>
                                                {/* form for next component */}
                                                <div className="card-body">
                                                    <div className="row g-2 mt-2">
                                                        <div className="col-md-6">
                                                            <div class="form-floating">
                                                                <select class="form-select " id="floatingSelect" aria-label="Floating label select example" value={tiTitletle}
                                                                    onChange={(e) => setTitle(e.target.value)}  >
                                                                    <option value="">---Select Title---</option>
                                                                    <option value="4">Ms</option>
                                                                    <option value="5">Dr</option>
                                                                    <option value="1">Miss</option>
                                                                    <option value="2">Mrs.</option>
                                                                    <option value="3">Mr.</option>
                                                                    <option value="9">Mx</option>
                                                                </select>
                                                                <label for="floatingSelect" >Title <span className="required"></span></label>
                                                            </div>

                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-floating text-dark">
                                                                <input type="text" className="form-control  " id="floatingInputGrid" placeholder="Firstname" value={firstName}
                                                                    onChange={(e) => { regex.test(e.target.value) && setfirstName(e.target.value) }} />
                                                                <label htmlFor="floatingInputGrid">First Name <span className="required"></span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-2 mt-2">
                                                        <div className="col-md-6">
                                                            <div className="form-floating">
                                                                <input type="text" className="form-control " id="floatingInputGrid" placeholder="Last Name" value={lastName}
                                                                    onChange={(e) => { regex.test(e.target.value) && setlastName(e.target.value) }} />
                                                                <label htmlFor="floatingInputGrid">Last Name <span className="required"></span></label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-floating">
                                                                <input type="email" className="form-control " id="floatingInputGrid" placeholder="text Address " value={emailaddress1}
                                                                    onChange={(e) => { !regexEmail.test(e.target.value) && setemailaddress1(e.target.value) }} />
                                                                <label htmlFor="floatingInputGrid">Email Addressss <span className="required"></span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row g-2 mt-2">
                                                        <div class="col-md-6">
                                                            <div class="form-floating">
                                                                <select class="form-select " id="floatingSelect" aria-label="Floating label select example" value={gendercode}
                                                                    onChange={(e) => ChangetheValueforgender(e.target.value)}>
                                                                    <option value="">--Select--</option>
                                                                    <option value="1">Male</option>
                                                                    <option value="2">Female</option>
                                                                    {/* <option value="3">Other</option> */}

                                                                </select>

                                                                <label htmlFor="floatingInputGrid">Gender at birth</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-floating">
                                                                <select class="form-select " id="floatingSelect" aria-label="Floating label select example" value={bcrm_whatgenderdoyouidentifiedas}
                                                                    onChange={(e) => setbcrm_whatgenderdoyouidentifiedas(e.target.value)}>
                                                                    <option selected>Select gender identified as</option>
                                                                    <option value="271400000">Male</option>
                                                                    <option value="271400001">Female</option>
                                                                    <option value="271400002">Transgender</option>
                                                                </select>
                                                                <label htmlFor="floatingInputGrid">What gender do you identified as?</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row g-2 mt-2">
                                                        <div class="col-md-6">
                                                            <div class="form-floating">
                                                                <input type="date" className="form-control " id="floatingInputGrid" placeholder="Date of Birth" value={birthdate}
                                                                    onChange={(e) => setbirthdate(e.target.value)} />
                                                                <label htmlFor="floatingInputGrid">Date of Birth</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-floating registerPI">
                                                                <div className="form-floating">
                                                                    <PhoneInput
                                                                        country={'gb'}
                                                                        className="form-control  "
                                                                        id="floatingInputGrid"
                                                                        value={mobilephone}
                                                                        onChange={setmobilephone}
                                                                    />
                                                                    <label htmlFor="floatingInputGrid">Mobile Phone</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-2 mt-2">
                                                        <div className="col-md-6">
                                                            <div className="form-floating registerPI">
                                                                <PhoneInput
                                                                    country={'gb'}
                                                                    className="form-control  "
                                                                    id="floatingInputGrid "
                                                                    value={homePhone}
                                                                    onChange={sethomePhone}
                                                                />
                                                                <label htmlFor="floatingInputGrid">Home Phone</label>

                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-floating registerPI">

                                                                <PhoneInput
                                                                    country={'gb'}
                                                                    className="form-control "
                                                                    id="floatingInputGrid "
                                                                    value={businessPhone}
                                                                    onChange={setbusinessPhone}
                                                                />
                                                                <label htmlFor="floatingInputGrid">Business Phone</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-2 mt-2">
                                                        <div className="col-md-6">
                                                            <div className="form-floating">
                                                                <select class="form-select " id="floatingSelect" aria-label="Floating label select example" value={patientFunding}
                                                                    onChange={(e) => setpatientFunding(e.target.value)} >
                                                                    <option value="" disabled >Select Funding Type</option>
                                                                    <option value="false">NHS</option>
                                                                    <option value="true">Self Funded</option>
                                                                </select>
                                                                <label htmlFor="floatingInputGrid">Patient Funding</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-floating">
                                                                <input type="text" class="form-control  " id="floatingInputGrid" placeholder=" Enter NHS/CHI/HCN/PSEL Number" value={nhsNumber}
                                                                    onChange={(e) => { regex.test(e.target.value) && setnhsNumber(e.target.value) }} />
                                                                <label htmlFor="floatingInputGrid">NHS/CHI/HCN/PSEL Number</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-2 mt-2">
                                                        <div className="col-md-6">
                                                            <div className="form-floating">
                                                                <select class="form-select " id="floatingSelect" aria-label="Floating label select example" value={previousTreatment}
                                                                    onChange={(e) => setpreviousTreatment(e.target.value)}>
                                                                    <option value="" disabled>Select Previous Treatment</option>
                                                                    <option value="true">Yes</option>
                                                                    <option value="false">No</option>


                                                                </select>
                                                                <label htmlFor="floatingInputGrid">Previous Treatment</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-floating">
                                                                <select class="form-select " id="floatingSelect" aria-label="Floating label select example" value={patientGroups}
                                                                    onChange={(e) => setpatientGroups(e.target.value)}>
                                                                    <option value="" disabled>Select Patient Groups</option>
                                                                    <option value="271400001">NHS couple</option>
                                                                    <option value="271400002">Heterosexual Couple</option>
                                                                    <option value="271400003">Single Women</option>
                                                                    <option value="271400004">Same Sex Couple</option>
                                                                    <option value="271400005">Pre Chemo Oocyte</option>
                                                                    <option value="271400006">Pre Chemo Sperm</option>
                                                                    <option value="271400007">Egg Recipient </option>
                                                                    <option value="271400008">Returning Patient </option>
                                                                    <option value="271400009">Non Clinic Test </option>
                                                                    <option value="271400010">Sperm Donor </option>
                                                                    <option value="271400000">Egg Donor </option>
                                                                </select>
                                                                <label htmlFor="floatingInputGrid">Patient Groups</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* area of address lookup */}
                                            <div className='col-md-6 d-flex h-75 '>
                                                <div className="card w-100" >
                                                    <div className="card-body ">
                                                        <h5 className="card-title">Address Lookup</h5>
                                                        <div className="row g-2 mt-2">
                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <PlacesAutocomplete
                                                                        value={address}
                                                                        onChange={(e) => {
                                                                            regex.test(e) &&
                                                                                setAddress(e)
                                                                        }}
                                                                        onSelect={handleSelect}
                                                                    >
                                                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                                            <div className="autocomplete-root">
                                                                                <div className="form-floating">
                                                                                    <input  {...getInputProps({
                                                                                        placeholder: "Enter Adress lookup",
                                                                                        class: "form-control", id: "floatingInputGrid", type: "text"
                                                                                    })} />
                                                                                    <label htmlFor="floatingInputGrid">Enter Address </label>
                                                                                </div>
                                                                                <div className="autocomplete-dropdown-container">
                                                                                    {loading ? <div>...loading</div> : null}

                                                                                    {suggestions.map(suggestion => {
                                                                                        const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';

                                                                                        const style = suggestion.active
                                                                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };



                                                                                        return (
                                                                                            <div {...getSuggestionItemProps(suggestion, { style, className })}>
                                                                                                <sapn>{suggestion.description}</sapn>
                                                                                            </div>
                                                                                        );
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </PlacesAutocomplete>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <input type="text" className="form-control" id="floatingInputGrid1" placeholder="House No/Flat No." name="houseforpatient"
                                                                        onChange={(e) => {
                                                                            debugger;
                                                                            regex.test(e.target.value) && sethouseno(e.target.value)
                                                                        }} value={houseno} />
                                                                    <label htmlFor="floatingInputGrid1">House No/Flat No.</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row g-2 mt-2">
                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <input type="text" className="form-control" id="floatingInputGrid2" placeholder="Street"
                                                                        name="streetforpatient" onChange={(e) => { regex.test(e.target.value) && setStreet(e.target.value) }} value={street} />
                                                                    <label htmlFor="floatingInputGrid2">Street</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <input type="text" className="form-control" id="floatingInputGrid3" placeholder="City"
                                                                        name="cityforpatient" value={city} onChange={(e) => { regex.test(e.target.value) && setcity(e.target.value) }} />
                                                                    <label htmlFor="floatingInputGrid3">City</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row g-2 mt-2">
                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <input type="text" className="form-control" id="floatingInputGrid4" placeholder="Country/Region"
                                                                        name="countryforpatient" value={country} onChange={(e) => { regex.test(e.target.value) && setcountry(e.target.value) }} />
                                                                    <label htmlFor="floatingInputGrid4">Country/Region</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <input type="text" className="form-control" id="floatingInputGrid5" placeholder="State/Province" name="stateforpatient"
                                                                        onChange={(e) => { regex.test(e.target.value) && setstate(e.target.value) }} value={stat} />
                                                                    <label htmlFor="floatingInputGrid5">State/Province</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row g-2 mt-2">
                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <input type="text" className="form-control" id="floatingInputGrid6" placeholder="ZIP/Postal Code" name='postalcodeforpatient'
                                                                        value={postal} onChange={(e) => { regex.test(e.target.value) && setpostal(e.target.value) }} />
                                                                    <label htmlFor="floatingInputGrid6">ZIP/Postal Code</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <input type="text" className="form-control" id="floatingInputGrid7" placeholder="County"
                                                                        name="county" onChange={(e) => { regex.test(e.target.value) && setcounty(e.target.value) }} value={county} />
                                                                    <label htmlFor="floatingInputGrid7">County</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        {/* My gp information */}
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div class="card w-100 h-100" >
                                                    <div class="card-body w-100">
                                                        <h5 class="card-title">GP information </h5>
                                                        <div class="row g-2 mt-2">
                                                            <div class="col-md-6">
                                                                <div class="form-floating">
                                                                    <input type="text" class="form-control" id="floatingInputGrid8" placeholder="GPs Name" value={GPName}
                                                                        onChange={(e) => { regex.test(e.target.value) && setGPName(e.target.value) }} />
                                                                    <label htmlFor="floatingInputGrid8">GPs Name</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-floating">
                                                                    <input type="text" class="form-control" id="floatingInputGrid9" placeholder="GP Surgery Name" value={PatientGPSurgeryName}
                                                                        onChange={(e) => { regex.test(e.target.value) && setPatientGPSurgeryName(e.target.value) }} />
                                                                    <label htmlFor="floatingInputGrid9">GP Surgery Name</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row g-2 mt-2">
                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <PlacesAutocomplete
                                                                        value={addressGP}
                                                                        onChange={(e) => {
                                                                            regex.test(e) && setaddress1(e)
                                                                        }}
                                                                        onSelect={handleSelect1} >
                                                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                                            <div className="autocomplete-root">
                                                                                <div className="form-floating">
                                                                                    <input  {...getInputProps({
                                                                                        placeholder: "Enter Adress lookup",
                                                                                        class: "form-control", id: "floatingInputGrid110", type: "text"
                                                                                    })} />
                                                                                    <label htmlFor="floatingInputGrid110">Enter Address </label>
                                                                                </div>

                                                                                <div className="autocomplete-dropdown-container">
                                                                                    {loading ? <div>...loading</div> : null}

                                                                                    {suggestions.map(suggestion => {
                                                                                        const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';

                                                                                        const style = suggestion.active
                                                                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };



                                                                                        return (
                                                                                            <div {...getSuggestionItemProps(suggestion, { style })}>
                                                                                                <sapn>{suggestion.description}</sapn>
                                                                                            </div>
                                                                                        );
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </PlacesAutocomplete>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <input type="text" className="form-control" id="floatingInputGrid11" placeholder="GP Street" value={street1}
                                                                        onChange={(e) => { regex.test(e.target.value) && setStreet1(e.target.value) }} />
                                                                    <label htmlFor="floatingInputGrid11">GP Street</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row g-2 mt-2">
                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <input type="text" className="form-control" id="floatingInputGrid12" placeholder="GP City" value={city1}
                                                                        onChange={(e) => { regex.test(e.target.value) && setcity1(e.target.value) }} />
                                                                    <label htmlFor="floatingInputGrid12">GP City</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <input type="text" className="form-control" id="floatingInputGrid13" placeholder="GP State/Province" value={state1}
                                                                        onChange={(e) => { regex.test(e.target.value) && setstate1(e.target.value) }} />
                                                                    <label htmlFor="floatingInputGrid13">GP State/Province</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row g-2 mt-2">
                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <input type="text" className="form-control" id="floatingInputGrid14" placeholder="GP Country/Region"
                                                                        onChange={(e) => { regex.test(e.target.value) && setcountry1(e.target.value) }} value={country1} />
                                                                    <label htmlFor="floatingInputGrid14">GP Country/Region</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <input type="text" className="form-control" id="floatingInputGrid15" placeholder="GPs ZIP/Postal Code"
                                                                        onChange={(e) => { regex.test(e.target.value) && setpostal1(e.target.value) }} value={postal1} />
                                                                    <label htmlFor="floatingInputGrid15">GPs ZIP/Postal Code</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                        {/* Switch button  */}
                                        <div className='row mt-3'>
                                            <div className="form-check form-switch  d-flex">
                                                <div className='col-md-5 fs-4'>
                                                    <label className="form-check-label " htmlFor="flexSwitchCheckDefault">
                                                        <h4>Do you have any partner details you would
                                                        like to enter?</h4></label>
                                                </div>
                                                <div className='col-md-4 ms-3'>
                                                    <label className="switch">
                                                        <input className="switch-input" type="checkbox" onClick={(e) => ClearDataonPartner(e, setcontent(!content))} />
                                                        <span className="switch-label" data-on="Yes" data-off="No"  ></span>
                                                        <span className="switch-handle"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            {/* content  */}
                                            {content ?

                                                <>
                                                    <div className='row d-flex mt-4'>
                                                        <div className='col-md-6  me-2 '>
                                                            <div className="card-body w-100">
                                                                <div className="row g-2 mt-2">
                                                                    <div className="col-md-6">
                                                                        <div className="form-floating">
                                                                            <input type="text" className="form-control" id="floatingInputGrid15" placeholder="Partner First Name" value={PName}
                                                                                onChange={(e) => { regex.test(e.target.value) && setPName(e.target.value) }} />
                                                                            <label htmlFor="floatingInputGrid15">Partner First Name</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-floating">
                                                                            <input type="text" className="form-control" id="floatingInputGrid16" placeholder="Partner Last Name" value={PLName}
                                                                                onChange={(e) => { regex.test(e.target.value) && setPLName(e.target.value) }} />
                                                                            <label htmlFor="floatingInputGrid16">Partner Last Name</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row g-2 mt-2">
                                                                    <div className="col-md-6">
                                                                        <div className="form-floating">
                                                                            <input type="email" className="form-control" id="floatingInputGrid17" placeholder="Partner text" value={PEmail}
                                                                                onChange={(e) => { !regexEmail.test(e.target.value) && setPEmail(e.target.value) }} />
                                                                            <label htmlFor="floatingInputGrid17">Partner Email</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-floating">
                                                                            <input type="date" className="form-control" id="floatingInputGrid18" placeholder="Partner Date Of Birth" value={Pdob}
                                                                                onChange={(e) => { regex.test(e.target.value) && setPdob(e.target.value) }} />
                                                                            <label htmlFor="floatingInputGrid18">Partner Date Of Birth</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row g-2 mt-2">
                                                                    <div className="col-md-6">
                                                                        <div className="form-floating">
                                                                            <select className="patientinputs form-select  " id="floatingSelectGrid19" name="gender" aria-label="Floating label select example" value={PGender}
                                                                                onChange={(e) => setPGender(e.target.value)}>
                                                                                <option value>--Select Gender--</option>
                                                                                <option value="1">Male</option>
                                                                                <option value="2">Female</option>
                                                                                <option value="3">Other</option>
                                                                            </select>
                                                                            <label htmlFor="floatingSelectGrid19">Partner Gender at birth</label>
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <div class="form-floating">
                                                                            <input type="text" class="form-control" id="floatingInputGrid20" placeholder="Referral By" value={PREfered}
                                                                                onChange={(e) => { regex.test(e.target.value) && setPREfered(e.target.value) }} />
                                                                            <label htmlFor="floatingInputGrid20">Referral By</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* partner addres with check boc info */}
                                                            <div className="row g-2 mt-2 mb-3">
                                                                
                                                                <div className="col-md-9" id="patientgpsameadd">
                                                                    <input className="form-check-input mt-2" type="checkbox"
                                                                        id="flexCheckChecked21" onClick={(e) => { setdata(e.target.checked) }} value={PartnerAddressSameasPatient} />

                                                                    <label className="form-check-label fs-5 " htmlFor="flexCheckChecked21">
                                                                        Use same address as patient
                                                                    </label>
                                                                </div>

                                                            </div>
                                                            <div className="card w-100  " >
                                                                <div className="card-body w-100">

                                                                    <div className="row g-2 mt-2">
                                                                        <div className="col-md-6">
                                                                            <div className="form-floating">
                                                                                <input type="text" className="form-control" id="floatingInputGrid22" placeholder="Partner House No/Flat No."
                                                                                    value={checkhouseno} onChange={(e) => { regex.test(e.target.value) && gethouseno(e.target.value) }} />
                                                                                <label htmlFor="floatingInputGrid22">Partner House No/Flat No.</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-floating">
                                                                                <input type="text" className="form-control" id="floatingInputGrid23" placeholder="Partner Street"
                                                                                    value={checkstreet} onChange={(e) => { regex.test(e.target.value) && getStreet(e.target.value) }} />
                                                                                <label htmlFor="floatingInputGrid23">Partner Street</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row g-2 mt-2">
                                                                        <div className="col-md-6">
                                                                            <div className="form-floating">
                                                                                <input type="text" className="form-control" id="floatingInputGrid24" placeholder="Partner City"
                                                                                    value={checkcity} onChange={(e) => { regex.test(e.target.value) && getcity(e.target.value) }} />
                                                                                <label htmlFor="floatingInputGrid24">Partner City</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-floating">
                                                                                <input type="text" className="form-control" id="floatingInputGrid25" placeholder="Partner State/Province"
                                                                                    value={checkstate} onChange={(e) => { regex.test(e.target.value) && getstate(e.target.value) }} />
                                                                                <label htmlFor="floatingInputGrid25">Partner State/Province</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row g-2 mt-2">
                                                                        <div className="col-md-6">
                                                                            <div className="form-floating">
                                                                                <input type="text" className="form-control" id="country_Region" placeholder="Partner Country/Region"
                                                                                    value={checkcountry} onChange={(e) => { regex.test(e.target.value) && getcountry(e.target.value) }} />
                                                                                <label htmlFor="country_Region">Partner Country/Region</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-floating">
                                                                                <input type="text" className="form-control" id="floatingInputGrid26" placeholder="Partner ZIP/Postal Code"
                                                                                    value={checkpostal} onChange={(e) => { regex.test(e.target.value) && getpostal(e.target.value) }} />
                                                                                <label htmlFor="floatingInputGrid26">Partner ZIP/Postal Code</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row g-2 mt-2">
                                                                        <div className="col-md-6">
                                                                            <div className="form-floating">
                                                                                <input type="text" className="form-control" id="floatingInputGridcounty" name='county' placeholder="Partner County"
                                                                                    value={checkcounty} onChange={(e) => { regex.test(e.target.value) && getcounty(e.target.value) }} />
                                                                                <label htmlFor="floatingInputGridcounty">Partner County</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* partner addres with check boc info */}

                                                        <div className="col-md-5 mb-4">
                                                            <div className="row mb-3 g-2 mt-2 "> &nbsp; &nbsp;
                                                                <div className='col-md-9' id="margingp">
                                                                    <input className="form-check-input mt-2" type="checkbox" value=""
                                                                        id="flexCheckChecked27" onClick={(e) => { setGPData(e.target.checked) }} />
                                                                    <label className="form-check-label fs-5 " htmlFor="flexCheckChecked27">
                                                                        Use same address as patient GP
                                                                    </label>
                                                                </div>
                                                                
                                                            </div>

                                                            {/* Partner gp information */}
                                                            <div className="row w-100">
                                                                <div className="card w-100">
                                                                    <div className="card-body w-100">
                                                                        <div className="row g-2 mt-2">
                                                                            <div className="col-md-6">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control" id="floatingInputGrid28" placeholder="GPs Name" value={checkPartnerGPName}
                                                                                        onChange={(e) => { regex.test(e.target.value) && getcheckPartnerGPName(e.target.value) }} />
                                                                                    <label htmlFor="floatingInputGrid28">GPs Name</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control" id="floatingInputGrid29" placeholder="GP Surgery Name" value={checkPartnerGPSurgeryName}
                                                                                        onChange={(e) => { regex.test(e.target.value) && getcheckPartnerGPSurgeryName(e.target.value) }} />
                                                                                    <label htmlFor="floatingInputGrid29">GP Surgery Name</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row g-2 mt-2">
                                                                            <div className="col-md-6">
                                                                                <div className="form-floating">
                                                                                    <PlacesAutocomplete
                                                                                        value={addressPGP}
                                                                                        onChange={(e) => {
                                                                                            regex.test(e) && setaddressPGP(e)
                                                                                        }}
                                                                                        onSelect={handleSelectPGP}
                                                                                    >
                                                                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                                                            <div className="autocomplete-root">
                                                                                                <div className="form-floating">
                                                                                                    <input  {...getInputProps({
                                                                                                        placeholder: "Enter Adress lookup",
                                                                                                        class: "form-control", id: "floatingInputGrid-1", type: "text"
                                                                                                    })} />
                                                                                                    <label htmlFor="floatingInputGrid-1">Enter Address </label>
                                                                                                </div>
                                                                                                <div className="autocomplete-dropdown-container">
                                                                                                    {loading ? <div>...loading</div> : null}

                                                                                                    {suggestions.map(suggestion => {
                                                                                                        const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';

                                                                                                        const style = suggestion.active
                                                                                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };


                                                                                                        return (
                                                                                                            <div {...getSuggestionItemProps(suggestion, { style })}>
                                                                                                                <sapn>{suggestion.description}</sapn>
                                                                                                            </div>
                                                                                                        );
                                                                                                    })}
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </PlacesAutocomplete>


                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control" id="floatingInputGrid30" placeholder="GP Street"
                                                                                        value={streetPGP} onChange={(e) => { regex.test(e.target.value) && setStreetPGP(e.target.value) }} />
                                                                                    <label htmlFor="floatingInputGrid30">GP Street</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row g-2 mt-2">
                                                                            <div className="col-md-6">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control" id="floatingInputGrid31" placeholder="GP City"
                                                                                        value={cityPGP} onChange={(e) => { regex.test(e.target.value) && setcityPGP(e.target.value) }} />
                                                                                    <label htmlFor="floatingInputGrid31">GP City</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control" id="floatingInputGrid32" placeholder="GP State/Province"
                                                                                        value={statePGP} onChange={(e) => { regex.test(e.target.value) && setstatePGP(e.target.value) }} />
                                                                                    <label htmlFor="floatingInputGrid32">GP State/Province</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row g-2 mt-2">
                                                                            <div className="col-md-6">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control" id="floatingInputGrid33" placeholder="GP Country/Region"
                                                                                        value={countryPGP} onChange={(e) => { regex.test(e.target.value) && setcountryPGP(e.target.value) }} />
                                                                                    <label htmlFor="floatingInputGrid33">GP Country/Region</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control" id="floatingInputGrid34" placeholder="GPs ZIP/Postal Code"
                                                                                        value={postalPGP} onChange={(e) => { regex.test(e.target.value) && setpostalPGP(e.target.value) }} />
                                                                                    <label htmlFor="floatingInputGrid34">GPs ZIP/Postal Code</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>

                                                </>
                                                : null
                                            }
                                        </div>
                                    </form>

                                </div>
                                {/* recaptcha */}
                                {/* <div className='row  mt-4 ms-2'>
                                    <ReCAPTCHA
                                        sitekey="6LePbq4UAAAAAPqwJU8u5g1Of1TIEMyoPpJQpyaD"
                                    />
                                </div> */}
                            </div>
                            {isLoading ? <LoadingSpinner /> :
                                <div className='card-footer contactusfont fs-4 fw-b text-center'>
                                    <button className='btn btn-lg btn-primary' type="button" style={{ backgroundColor: '#0088cc' }} onClick={handleSubmit}>Submit</button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}



