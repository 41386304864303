
import axios from "axios";

const url = process.env.REACT_APP_OXAR_API_URL;


const getProfileData = async (id) => {

  try {
 
    const res = await axios.get(`${url}/api/Profile/GetById?id=${id}`);
    return res.data;
  }

  catch (e) {
   
   // console.log("Error --> ", e.message);
    throw new Error(e);
  }
};

const getProfileB2C = async () => {
  try {

    const res = await axios.get(`${url}/api/CrmUser/GetByB2CId`);
    return res.data;

  } catch (e) {
    console.log("Error --> ", e.message);
    throw new Error(e);
  }
};
const GetQuestionairebyPatientId = async (MainUserId) => {
  try {
    var res = await axios(`${url}api/Profile/GetQuestionairebyPatientId?id=${MainUserId}`)

    if(res.data.content.whatDateWasYourLastMenstrualPeriod ){
      res.data.content.whatDateWasYourLastMenstrualPeriod = res.data.content.whatDateWasYourLastMenstrualPeriod.substring(0, 10);
    }
    if(res.data.content.whenWasYourLastSmearTest ){
      res.data.content.whenWasYourLastSmearTest = res.data.content.whenWasYourLastSmearTest.substring(0, 10);
    }
    return res.data;
  } catch {
  }
}
const getAllprevPregnancy = async (MainUserId) => {
  try {
    var res =await axios(`${url}api/PreviousPregnancy/GetByContactId?id=${MainUserId}`)
    
    return res.data;
  }
  catch {

  }

};
const getAllfertilityTreat = async (MainUserId) => {
  try {
    var res =await axios(`${url}api/Treatment/GetFertilityTreatmentByContactId?id=${MainUserId}`);
    return res.data;
  }
  catch {
  }
}
const getAllergies = async (MainUserId) => {
  try {
    var res = await axios(`${url}api/Allergy/GetByContactId?id=${MainUserId}`)
    return res.data;
  }
  catch {
  }
}

const getAllprevProcedure = async (MainUserId) => {

  try {
  
     var res= await axios(`${url}api/PreviousProcedure/GetByContactId?id=${MainUserId}`)
       
       for (var i = 0; i < res.data.content.length; i++){
        if(res.data.content[i].procedureDate != null){
          res.data.content[i].procedureDate = res.data.content[i]['procedureDate'].substring(0, 10)
        }
  }
  return res.data;
}

  catch {
  }
}

export { getProfileData, getProfileB2C, GetQuestionairebyPatientId,getAllergies,getAllfertilityTreat,getAllprevPregnancy ,getAllprevProcedure};
