import React, { useEffect } from 'react'
import { Card } from 'react-bootstrap'
import { useState } from "react";
import { useLocation, useNavigate } from 'react-router';
import axios from "axios";
import moment from "moment";
import LoginFun from '../Layout Component/Loginfun';
import Swal from "sweetalert2";
import './Checklist.css';
import { useDispatch } from "react-redux";
import { setLoader } from '../Store/Reducers/UserReducer';
import $ from 'jquery';
var UserData;
export default function CheckListDetails() {
    UserData = JSON.parse(localStorage.getItem("UData"));
    const dispatch = useDispatch();
    const url = process.env.REACT_APP_OXAR_API_URL;
    const datalogin = LoginFun();
    const navigate = useNavigate();
    const [CheckListDetails, setCheckListDetails] = useState([]);
    const location = useLocation();

    useEffect(() => {
        dispatch(setLoader(true));
        setTimeout(() => {
            getCheckListDetails(location.state.rowId);
        }, 1000);
        var currentTime = '';
        currentTime = (Math.round(new Date().getTime() / 1000));
        var Expiretime = localStorage.getItem("ExpirretokenTime");
        if (Expiretime < currentTime) {
            sessionStorage.getItem('isLoggedIn', false)
            Swal.fire({
                title: 'Session Expired',
                text: "Your session has been expired! Please login again",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Okay',
            }).then(result => {
                datalogin.logout();
            })
        }

    }, []);

    const getCheckListDetails = async (id) => {
        try {
            dispatch(setLoader(true));
            let ID = localStorage.getItem("CheckListID");
            await axios(`${url}api/Profile/GetPatientChecklistbyCheckListBundle?id=${ID}`).then((res) => {
                for (var i = 0; i < res['data']['content'].length; i++) {
                    if (res.data.content[i].date === "0001-01-01T00:00:00") {
                        res.data.content[i].date = "";
                    }
                    else{
                        var date = res.data.content[i].date
                        res.data.content[i].date =  moment(date).format("DD-MMM-yyyy") 
                    }
                }
                setCheckListDetails(res.data.content);
                $("#PrevTreatmentCycleTable").DataTable();
                dispatch(setLoader(false));
            });
        }
        catch {
            dispatch(setLoader(false));
        }
    };

    return (
        <>
            <div className='row m-0  mb-3 justify-content-center'>
                <div className='col-md-11  mt-3'>
                    <Card className="  cardcolor">
                        <Card.Header className="  -6 d-flex justify-content-between">
                            <div className='col-md-4'>
                                <h5>Checklist Detail</h5>
                            </div>
                            &nbsp;
                            <div className='col-md-8 text-end'>
                                ( {UserData?.firstName} {UserData?.lastName} , {moment(UserData?.birthday).format("DD-MMM-YYYY")} ({UserData?.age}) ,{UserData?.gendercodeName} )

                                <a type="submit" onClick={() => navigate(-1)}>
                                    <i class="fa-solid fs-5 fa-arrow-circle-left"></i>
                                </a>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                {/* step wizard for Medical History */}
                                <div className='col-md-12'>
                                    <div className='row'>
                                        <div className="col-md-12 text-left bg-white-200 mt-2 ">
                                            <div className="table-responsive">
                                                <table class="table border-0" id="PrevTreatmentCycleTable">
                                                    <thead class="">
                                                        <tr>
                                                            <th> Name</th>
                                                            <th>Date </th>
                                                            <th>Checklist Bundle</th>
                                                            <th>Comment</th>
                                                            <th>Completed</th>
                                                            <th>Created On</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {CheckListDetails && CheckListDetails.map((listValue, index) => {
                                                            return (
                                                                <tr className={`${listValue.bcrm_complete === 271400000 ? "custom-class" : ""}`}>
                                                                    <td>{listValue.name}</td>
                                                                   
                                                                    <td>
                                                                        {listValue.date}
                                                                    </td>
                                                                     
                                                                    
                                                                    <td>{listValue.patientchecklistbundleName}</td>
                                                                    <td>{listValue.comment}</td>

                                                                    <td>{listValue?.bcrm_complete === "271400000" ? 'Yes' : 'No'}</td>
                                                                    <td>{moment(listValue.createdOn).format("DD-MMM-yyyy") ? moment(listValue.createdOn).format("DD-MMM-yyyy") : moment(listValue.createdOn).format("DD-MMM-yyyy")}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>


                                        </div>
                                    </div>

                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>

            </div>
        </>
    )
}
