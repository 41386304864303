import React from 'react'
import { HashRouter, Route, BrowserRouter, Routes } from "react-router-dom";
import Header from './Header';
import Home from '../Home';
import Register from '../Register';
import Footer from './Footer';
import Cookies from './cookies';
import { getLoader } from '../Store/Reducers/UserReducer';
import CookiesDetails from './CookiesDetails';
import { useSelector } from 'react-redux';
import Help from '../Profile Component/Help';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

export default function Layout2({ setRout }) {
    const Loader = useSelector(getLoader);
    return (
        <>
            <HashRouter>
                <Header setRout={setRout} />
                {Loader &&
                    <div className="loader-container" >
                        <div className="spinner"></div>
                    </div>
                }
                {
                    window.location.pathname !== "/Home"
                }
                < Routes >
                    <Route path="/">
                        <Route path="" element={<Home setRout={setRout} />} />
                        <Route path="register" element={<Register />} />
                        <Route path="*" element={<Home setRout={setRout} />} />
                        <Route path="cookies" element={<Cookies />} />
                        <Route path="cookiesdetails" element={<CookiesDetails />} />
                        <Route path="Help" element={<Help />} />
                    </Route>
                </Routes >
                <Footer />
            </HashRouter >
            <ToastContainer />
        </>
    )
}
