import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-input-2';
import axios from "axios";
import { useDispatch } from "react-redux";
import PlacesAutocomplete, { geocodeByAddress, } from "react-places-autocomplete";
import './stepper.css';
import { setLoader } from "../Store/Reducers/UserReducer"
import Select from 'react-select';
import './step1.css';
import Swal from "sweetalert2";
import LoginFun from '../Layout Component/Loginfun';

import $ from "jquery";
export default function Step1(props) {
    const regex = /^[a-zA-Z0-9\s]*$/;
    const regexEmail = /[<>&"']/;
    const ProfileData = props?.ProfileData;
    const setProfileData = props?.setProfileData;
    const dispatch = useDispatch();
    const [step3adress, setstep3adress] = React.useState("");
    //state for address to,locate at different fileds
    const [Ethnicity, setEthnicity] = useState([]);
    const url = process.env.REACT_APP_OXAR_API_URL;
    const data1 = LoginFun();
    // setAddress(ProfileData?.address);
    let ProfileData2 = { ...ProfileData }

    useEffect(() => {
        dispatch(setLoader(true));
        handleEthnicity();
        var currentTime = ''
        currentTime = (Math.round(new Date().getTime() / 1000));
        var Expiretime = localStorage.getItem("ExpirretokenTime");
        if (Expiretime < currentTime) {
            sessionStorage.getItem('isLoggedIn', false)
            Swal.fire({
                title: 'Session Expired',
                text: "Your session has been expired! Please login again",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Okay',
            }).then(result => {
                data1.logout();
            })
        }
    }, []);

    // API section for getting the  Country of Birth
    const handleEthnicity = async () => {
        try {
            dispatch(setLoader(true));
            await axios(`${url}/api/Profile/GetEthnicity`).then((res) => {
                setEthnicity(res.data.content);
            });
            dispatch(setLoader(false));
        }
        catch {
        }
    }

    // API section for getting the  Country of Birth
    const handleEthnicityValueassaign = async data => {
        var EthenticityValueid = "";
        EthenticityValueid = data.target.value
        ProfileData2.ethnicityId = EthenticityValueid
        setProfileData(ProfileData2);
    }

    //  Patient  Address Lookup  //
    const handleSelect1 = async value => {
        const results = await geocodeByAddress(value);
        const setAddressvalue2 = results[0].address_components;
        var houseno1 = "";
        var Street1 = "";
        var country1 = "";
        var city1 = "";
        var State1 = "";
        var postalCode1 = "";

        setAddressvalue2.map((elm, i) => {

            if (elm.types[0] === "street_number") {
                let HouseNumber = setAddressvalue2[i].short_name;
                $("#HouseNo").val(HouseNumber);
                // ProfileData.patientStreet = HouseNumber;
                ProfileData2['patientStreet'] = HouseNumber;
                houseno1 = HouseNumber;
            }

            if (houseno1 === undefined || houseno1 === "") {
                $("#HouseNo").val('');
            }

            if (elm.types[0] === "route") {
                $("#street").val(setAddressvalue2[i].short_name);
                var Street = $("#street").val();
                ProfileData2['street'] = Street;
                Street1 = Street;
            }

            if (Street1 === undefined || Street1 === "") {
                $("#street").val('');
            }

            if (elm.types[0] === "locality") {
                $("#city").val(setAddressvalue2[i].long_name);
                var City = $("#city").val();
                setProfileData({ ...ProfileData, ['city']: City });
                ProfileData2['city'] = City;
                city1 = City;
            }

            if (city1 === undefined || city1 === "") {
                $("#city").val('');
            }

            if (elm.types[0] === "administrative_area_level_1") {
                $("#floatingInputGrid5").val(setAddressvalue2[i].long_name);
                var State = $("#floatingInputGrid5").val();
                ProfileData2['state'] = State;
                State1 = State;
            }

            if (State1 === undefined || State1 === "") {
                $("#floatingInputGrid5").val('');
            }
            if (elm.types[0] === "postal_code") {
                $("#Postalcode").val(setAddressvalue2[i].long_name);
                var Postalcode = $("#Postalcode").val();
                ProfileData2['postalCode'] = Postalcode;
                postalCode1 = Postalcode;
            }

            if (postalCode1 === undefined || postalCode1 === "") {
                $("#Postalcode").val('');
            }


            if (elm.types[0] === "country") {
                $("#country").val(setAddressvalue2[i].long_name);
                var Country = $("#country").val();
                ProfileData2['country'] = Country;
                country1 = Country;
            }

            if (country1 === undefined || country1 === "") {
                $("#country").val('');
            }

            return true
        })
        setstep3adress(value);
        setProfileData(ProfileData2);
    }

    const updateProfileData = (data) => {
        var name = data.target.name;
        var value = data.target.value;

        if (name === "doYouconsenttoreceivingremindersbytextmes") {
            var value1 = data.target.checked;
            value = value1
        }

        if (name === "gender" && value === "1") {
            ProfileData2['whatgenderdoyouidentifiedas'] = "271400000"
            setProfileData(ProfileData2);
        }

        if (name === "gender" && value === "2") {
            ProfileData2['whatgenderdoyouidentifiedas'] = "271400001"
            setProfileData(ProfileData2);
        }

        else if (name === "gender" && value === "") {
            ProfileData2['whatgenderdoyouidentifiedas'] = "271400002"
            setProfileData(ProfileData2);
        }

        setProfileData(values => ({ ...values, [name]: value, 'id': ProfileData?.id }));
    }

    const updateInput = (value, name, data) => {
         name = data.target.placeholder;
         value = data.target.value;
        setProfileData(values => ({ ...values, [name]: value, 'id': ProfileData?.id }));
    }
    const updateDate = (data) => {
        var name = data.target.name;
        var value = data.target.value;
        var date = new Date(value);
        setProfileData(values => ({ ...values, [name]: date, 'id': ProfileData?.id }));
    }

    return (
        <Card.Body>
            <Card.Text>
                <form>
                    <div className='row'>
                        <div className='col-md-6 mb-2'>
                            <div className='row '>
                                <div className='col-md-5'>
                                    <label> Title <span className="required"></span>  </label>
                                </div>
                                <div className='col-md-7'>
                                    <select className="form-select " id="floatingSelect" name="title" disabled={ProfileData?.areYousureYouwanttosubmit === true} value={ProfileData?.title} onChange={(e) => updateProfileData(e)}  >
                                        <option value="null">--Select Title--</option>
                                        <option value="4" selected={ProfileData?.title === 4}>Ms</option>
                                        <option value="5" selected={ProfileData?.title === 5}>Dr</option>
                                        <option value="1" selected={ProfileData?.title === 1}>Miss</option>
                                        <option value="2" selected={ProfileData?.title === 2}>Mrs.</option>
                                        <option value="3" selected={ProfileData?.title === 3}>Mr</option>
                                        <option value="9" selected={ProfileData?.title === 9}>Mx</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <label>First Name <span className="required"></span> </label>
                                </div>
                                <div className='col-md-7'>
                                    <input type="text" className="form-control " placeholder="Firstname" name="firstName" disabled={ProfileData?.areYousureYouwanttosubmit === true} value={ProfileData?.firstName}
                                        onChange={(e) => { regex.test(e.target.value) && updateProfileData(e) }} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mb-2'>
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <label >Last Name <span className="required"></span> </label>
                                </div>
                                <div className='col-md-7'>
                                    <input type="text" className="form-control " placeholder="Last Name" name="lastName" disabled={ProfileData?.areYousureYouwanttosubmit === true}
                                        value={ProfileData?.lastName} onChange={(e) => { regex.test(e.target.value) && updateProfileData(e) }} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <label >Previous Surname </label>
                                </div>
                                <div className='col-md-7'>
                                    <input type="text" className="form-control " placeholder="Previous Surname " name="previousName" value={ProfileData?.previousName}
                                        disabled={ProfileData?.areYousureYouwanttosubmit === true} onChange={(e) => { regex.test(e.target.value) && updateProfileData(e) }} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-6 mb-2'>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <label >Gender at birth <span className="required"></span> </label>
                                </div>
                                <div className='col-md-7'>
                                    <select className="form-select " id="floatingSelect" disabled={ProfileData?.areYousureYouwanttosubmit === true}
                                        aria-label="Floating label select example" name="gender"
                                        value={ProfileData?.gender} onChange={(e) => updateProfileData(e)}>
                                        <option value="null">---Select---</option>
                                        <option value="1" selected={ProfileData?.gender === 1}>Male</option>
                                        <option value="2" selected={ProfileData?.gender === 2}>Female</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <label >What gender do you identified as?</label>
                                </div>
                                <div className='col-md-7'>
                                    <select className="form-select " id="floatingSelect" aria-label="Floating label select example"
                                        disabled={ProfileData?.areYousureYouwanttosubmit === true} name="whatgenderdoyouidentifiedas" value={ProfileData?.whatgenderdoyouidentifiedas} onChange={(e) => updateProfileData(e)}>
                                        <option value='null'>---Select---</option>
                                        <option value="271400000" selected={ProfileData?.whatgenderdoyouidentifiedas === 271400000}>Male</option>
                                        <option value="271400001" selected={ProfileData?.whatgenderdoyouidentifiedas === 271400001}>Female</option>
                                        <option value="271400002" selected={ProfileData?.whatgenderdoyouidentifiedas === 271400002}>Transgender</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mb-2'>
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <label >Date of Birth </label>
                                </div>
                                <div className='col-md-7'>
                                    <input type='Date' className="form-control" id='birthday' placeholder="Date of Birth" name="birthday" disabled={ProfileData?.areYousureYouwanttosubmit === true}
                                        defaultValue={ProfileData?.birthday} onChange={(e) => updateDate(e)} />


                                </div>
                            </div>

                        </div>
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <label >Occupation</label>
                                </div>
                                <div className='col-md-7'>
                                    <input type="text" disabled={ProfileData?.areYousureYouwanttosubmit === true} className="form-control " placeholder="Occupation"
                                        name="occupation" value={ProfileData?.occupation} onChange={(e) => { regex.test(e.target.value) && updateProfileData(e) }} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mb-2'>
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <label >Relationship status <span className="required"></span> </label>
                                </div>
                                <div className='col-md-7'>
                                    <select className="form-select " id="floatingSelect" aria-label="Floating label select example" name="relationShipStatus"
                                        disabled={ProfileData?.areYousureYouwanttosubmit === true} defaultValue={ProfileData?.relationShipStatus} onChange={(e) => updateProfileData(e)}>
                                        <option value="null">---Select---</option>
                                        <option value="3" selected={ProfileData?.relationShipStatus === 3}>Married/Civil Partner to the person having treatment </option>
                                        <option value="4" selected={ProfileData?.relationShipStatus === 4}>Married/Civil Partner to another person not having treatment with </option>
                                        <option value="5" selected={ProfileData?.relationShipStatus === 5}>Divorced/civil partnership dissolved</option>
                                        <option value="6" selected={ProfileData?.relationShipStatus === 6}>Widowed/surviving civil partner</option>
                                        <option value="2" selected={ProfileData?.relationShipStatus === 2}>Separated</option>
                                        <option value="1" selected={ProfileData?.relationShipStatus === 1}>Legally Single</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='row'>

                                <div className='col-md-5'>
                                    <label >Preferred Name</label>
                                </div>
                                <div className='col-md-7'>
                                    <input type="text" className="form-control " placeholder="Preferred Name" name="preferredName" disabled={ProfileData?.areYousureYouwanttosubmit === true}
                                        value={ProfileData?.preferredName} onChange={(e) => { regex.test(e.target.value) && updateProfileData(e) }} />
                                </div>



                            </div>
                        </div>
                    </div>

                    <div className='row mb-2'>
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-5'>

                                    <label >Patient Groups</label>
                                </div>
                                <div className='col-md-7'>
                                    <select className="form-select " id="floatingSelect" aria-label="Floating label select example" name="patientGroups"
                                        disabled={ProfileData?.areYousureYouwanttosubmit === true} value={ProfileData?.patientGroups} onChange={(e) => updateProfileData(e)}>
                                        <option value={null}>---Select---</option>
                                        <option value="271400001" selected={ProfileData?.patientGroups === 271400001}>NHS couple</option>
                                        <option value="271400002" selected={ProfileData?.patientGroups === 271400002}>Heterosexual Couple</option>
                                        <option value="271400003" selected={ProfileData?.patientGroups === 271400003}>Single Women</option>
                                        <option value="271400004" selected={ProfileData?.patientGroups === 271400004}>Same Sex Couple</option>
                                        <option value="271400005" selected={ProfileData?.patientGroups === 271400005}>Pre Chemo Oocyte</option>
                                        <option value="271400006" selected={ProfileData?.patientGroups === 271400006}>Pre Chemo Sperm</option>
                                        <option value="271400007" selected={ProfileData?.patientGroups === 271400007}>Egg Recipient </option>
                                        <option value="271400008" selected={ProfileData?.patientGroups === 271400008}>Returning Patient </option>
                                        <option value="271400009" selected={ProfileData?.patientGroups === 271400009}>Non Clinic Test </option>
                                        <option value="271400010" selected={ProfileData?.patientGroups === 271400010}>Sperm Donor </option>
                                        <option value="271400000" selected={ProfileData?.patientGroups === 271400000}>Egg Donor </option>
                                    </select>
                                </div>
                            </div>

                        </div>
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <label >Ethnicity</label>
                                </div>
                                <div className='col-md-7'>
                                    <select className='form-control form-select ' onChange={(e) => handleEthnicityValueassaign(e)}
                                        disabled={ProfileData?.areYousureYouwanttosubmit === true} value={ProfileData?.ethnicityId}>
                                        <option value="">--Select--</option>
                                        <Select onChange={(e) => handleEthnicityValueassaign(e)} />
                                        {Ethnicity ? (Ethnicity?.map((data, index) => {
                                            return (
                                                <option value={data.id}>{data.name}</option>
                                            );
                                        })
                                        ) : null
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mb-2'>
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <label htmlFor="floatingInputGrid">Email Address <span className="required"></span>  </label>
                                </div>

                                <div className='col-md-7 '>
                                    <input type="email" disabled={ProfileData?.areYousureYouwanttosubmit === true} className="form-control "
                                        id="floatingInputGrid" placeholder="Email Address" name="email" value={ProfileData?.email} onChange={(e) => { !regexEmail.test(e.target.value) && updateProfileData(e) }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <label >Mobile Phone</label>
                                </div>
                                <div className='col-md-7 phonesymbol'>
                                    <PhoneInput
                                        country={'gb'} disabled={ProfileData?.areYousureYouwanttosubmit === true} className='form-control ' id='floatingInputGrid'
                                        placeholder='mobileNumber' value={ProfileData?.mobileNumber} onChange={updateInput}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mb-2'>
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <label >Home Phone</label>
                                </div>
                                <div className='col-md-7 phonesymbol'>
                                    <PhoneInput
                                        country={'gb'} className="form-control " id="floatingInputGrid " name='homePhone' value={ProfileData?.homePhone}
                                        placeholder='homePhone' onChange={updateInput} disabled={ProfileData?.areYousureYouwanttosubmit === true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <label >Business Phone</label>
                                </div>
                                <div className='col-md-7 phonesymbol'>
                                    <PhoneInput disabled={ProfileData?.areYousureYouwanttosubmit === true} country={'gb'} className="form-control "
                                        name='businessPhone' id="floatingInputGrid" placeholder='businessPhone' value={ProfileData?.businessPhone} onChange={updateInput}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mb-2'>
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <label >Enter Address lookup</label>
                                </div>
                                <div className='col-md-7' disabled={ProfileData?.areYousureYouwanttosubmit === true}>
                                    <PlacesAutocomplete
                                        value={step3adress}
                                        onChange={(e) => { { regex.test(e) && setstep3adress(e) } }}
                                        onSelect={handleSelect1}
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <div className="autocomplete-root">
                                                <div className="form-floating">
                                                    <input {...getInputProps({
                                                        placeholder: "Enter Adress lookup", autoComplete: "on",
                                                        className: "form-control", id: "floatingInputGrid10", type: "text"
                                                    })}
                                                    />
                                                </div>
                                                {ProfileData?.areYousureYouwanttosubmit === false ?
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading ? <div>...loading</div> : null}
                                                        {suggestions.map(suggestion => {
                                                            const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';

                                                            const style = suggestion.active
                                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                            return (
                                                                <div {...getSuggestionItemProps(suggestion, { style, className })}>
                                                                    <sapn>{suggestion.description}</sapn>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                    : null}
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <label >House No/Flat No.</label>
                                </div>
                                <div className='col-md-7'>
                                    <input type="text" className="form-control" id='HouseNo' placeholder="House No/Flat No." disabled={ProfileData?.areYousureYouwanttosubmit === true}
                                        name="patientStreet" value={ProfileData?.patientStreet} onChange={(e) => { regex.test(e.target.value) && updateProfileData(e) }} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mb-2'>
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <label >City</label>
                                </div>
                                <div className='col-md-7'>
                                    <input type="text" className="form-control" id='city' placeholder="City" name="city" value={ProfileData?.city}
                                        disabled={ProfileData?.areYousureYouwanttosubmit === true} onChange={(e) => { regex.test(e.target.value) && updateProfileData(e) }} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <label >Country/Region</label>
                                </div>
                                <div className='col-md-7'>
                                    <input type="text" className="form-control" id='country' placeholder="Country/Region" name="country"
                                        disabled={ProfileData?.areYousureYouwanttosubmit === true} value={ProfileData?.country} onChange={(e) => { regex.test(e.target.value) && updateProfileData(e) }} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mb-2'>
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <label htmlFor="floatingInputGrid5">State/Province</label>
                                </div>
                                <div className='col-md-7'>
                                    <input type="text" className="form-control" id="floatingInputGrid5" placeholder="State/Province" name="state"
                                        disabled={ProfileData?.areYousureYouwanttosubmit === true} value={ProfileData?.state} onChange={(e) => { regex.test(e.target.value) && updateProfileData(e) }} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <label >Street</label>
                                </div>
                                <div className='col-md-7'>
                                    <input type="text" name='street' id='street' className="form-control" placeholder="Street" value={ProfileData?.street}
                                        disabled={ProfileData?.areYousureYouwanttosubmit === true} onChange={(e) => { regex.test(e.target.value) && updateProfileData(e) }} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mb-2'>
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <label >Zip/Postalcode</label>
                                </div>
                                <div className='col-md-7'>
                                    <input type="text" name='postalCode' id='Postalcode' className="form-control" placeholder="Zip/Postalcode"
                                        disabled={ProfileData?.areYousureYouwanttosubmit === true} value={ProfileData?.postalCode} onChange={(e) => { regex.test(e.target.value) && updateProfileData(e) }} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <label >Resident In UK Last 12 Months?</label>
                                </div>
                                <div className='col-md-7'>
                                    <select className="form-select " id="floatingSelect" disabled={ProfileData?.areYousureYouwanttosubmit === true}
                                        aria-label="Floating label select example"
                                        name='residentInUKLast'
                                        defaultValue={ProfileData?.residentInUKLast}
                                        onChange={(e) => {
                                            updateProfileData(e)
                                        }}>
                                        <option value="null">--Select--</option>
                                        <option value='271400001' selected={ProfileData?.residentInUKLast === 271400001}>No</option>
                                        <option value='271400000' selected={ProfileData?.residentInUKLast === 271400000}>Yes</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mb-2'>
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <label className="form-check-label  mt-2 " for="flexSwitchCheckDefault">Do you consent to receiving reminders by text message?</label>
                                </div>
                                <div className='col-md-7'>
                                    <div className=" form-check form-switch p-0 mt-1">
                                        <label class="switch">
                                            <input type="checkbox" name='doYouconsenttoreceivingremindersbytextmes' disabled={ProfileData?.areYousureYouwanttosubmit === true}
                                                checked={ProfileData?.doYouconsenttoreceivingremindersbytextmes === true}
                                                onChange={(e) => updateProfileData(e)} />
                                            <div class="slider round">
                                                <span class="on">Yes</span>
                                                <span class="off">No</span>
                                            </div>
                                        </label>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                        </div>
                    </div>
                </form>
            </Card.Text >
        </Card.Body >
    )
}
