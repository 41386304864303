import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { Card, Button } from "react-bootstrap";
import axios from "axios";
import { useState } from "react";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setLoader } from "../../Store/Reducers/UserReducer";
import LoginFun from "../../Layout Component/Loginfun";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

var UserData;
export default function CaseCustomer() {
  const navigate = useNavigate();
  UserData = JSON.parse(localStorage.getItem("UData"));
  const dispatch = useDispatch();
  const url = process.env.REACT_APP_OXAR_API_URL;
  const logindata = LoginFun();
  const [show, setShow] = useState(false);
  const [getcase, setgetcase] = useState([]);
  const [Description, setDescription] = useState();
  const [Title, setTitle] = useState();
  const [image, setimage] = useState();
  const [Type, setType] = useState();
  const [saveBtn, setsaveBtn] = useState(false);
  const [updateBtn, setupdateBtn] = useState(false);
  const [updateCaseData, setupdateCaseData] = useState([]);
  const [data, setData] = useState();
  const [newstate, setnewstate] = useState({});

  const tocomponentchat = (Casedata) => {
    localStorage.setItem("Caseid", Casedata?.id);
    navigate("/ChatCase", { state: { Casedata } });
  };

  const updateNewState = (value) => {
    setnewstate({ ...newstate, ...value });
  };

  const handleClose = () => setShow(false);

  const handleShow = (Dataall) => {
    setShow(true);
    setnewstate([]);
    if (Dataall !== undefined) {
      setnewstate(Dataall);
      setData(Dataall);
    }
    setnewstate({ ...Dataall });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    createCase();
    //handleClose();
  };

  const sessionCheck = async () => {
    var currentTime = "";
    currentTime = Math.round(new Date().getTime() / 1000);
    var Expiretime = localStorage.getItem("ExpirretokenTime");
    if (Expiretime < currentTime) {
      sessionStorage.getItem("isLoggedIn", false);
      Swal.fire({
        title: "Session Expired",
        text: "Your session has been expired! Please login again",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Okay",
      }).then((result) => {
        logindata.logout();
      });
    }
  };
  // Getting case
  const getAllcase = async () => {
    debugger;
    try {
      let MainUserId = localStorage.getItem("UserID");
      $("#CaseTable").DataTable().destroy();
      dispatch(setLoader(true));
      await axios(`${url}api/Profile/GetCaseForPatient?id=${MainUserId}`).then(
        (res) => {
          setgetcase(res.data.content);
          setTimeout(function () {
            $('#CaseTable').DataTable( {
              order: [[ 5, 'desc' ]]
          } );
          }, 500);
        }
      );
      dispatch(setLoader(false));
    } catch {
      dispatch(setLoader(false));
    }
  };
  useEffect(() => {
    setTimeout(() => {
      getAllcase();
    }, 1000);
    sessionCheck();
  }, []);



  const createCase = async () => {
    //dispatch(setLoader(true));
    debugger;
    var payload = new FormData();
    payload.append("file", newstate?.image);
    let fileExtension = newstate?.image?.name.split(".").pop();
    let MainUserId = localStorage.getItem("UserID");
    if (newstate?.title !== undefined && newstate?.description !== undefined && newstate?.casetypecode !== undefined) {
      try {
        dispatch(setLoader(true));
        await axios
          .post(
            `${url}api/Profile/CreateCaseNew?CaseTitle=${newstate?.title}&caseDescription=${newstate?.description}&casetypecode=${newstate?.casetypecode}&UserID=${MainUserId}`,
            payload
          )
          .then((res) => {
            if (res.status === 200) {
              toast.success("Case created successfully!", {
                autoClose: 5000,
                hideProgressBar: true,
                newestOnTop: false,
                closeOnClick: true,
                rtl: false,
                pauseOnFocusLoss: true,
                draggable: true,
                pauseOnHover: true,
                className: "toast-message",
              });
          
              getAllcase();
              setTitle("");
              setDescription("");
              setType("");
              handleClose();
            }
          });
        dispatch(setLoader(false));
      } catch(e) {
        if(e.response.data.errorMessage == "Invalid json"){
          toast.error('Please dont use special characters in fields ', {
            autoClose: 5000,
            hideProgressBar: true,
            newestOnTop: false,
            closeOnClick: true,
            rtl: false,
            pauseOnFocusLoss: true,
            draggable: true,
            pauseOnHover: true,
            theme: "colored"
          }
          )
          dispatch(setLoader(false));
        return
        }
        else{
          toast.error('Error while updating', {
            autoClose: 5000,
            hideProgressBar: true,
            newestOnTop: false,
            closeOnClick: true,
            rtl: false,
            pauseOnFocusLoss: true,
            draggable: true,
            pauseOnHover: true,
            theme: "colored"
          }
          )
          dispatch(setLoader(false));
        return
        }
        dispatch(setLoader(false));
        return
      }
    } else {
      toast.warn("Please fill mandatory fields", {
        autoClose: 5000,
        hideProgressBar: true,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        className: "toast-warn",
      });
      dispatch(setLoader(false));
      return;
    }
  };

  const updateCase = async (updatedvalue) => {
    var name = updatedvalue.target.name;
    var value = updatedvalue.target.value;
    setupdateCaseData((values) => ({
      ...values,
      [name]: value,
      id: data?.id,
    }));
  };

  const UpdatetoCase = async () => {
    debugger;
    dispatch(setLoader(true));
    if (newstate?.title !== "" && newstate?.description !== "" && newstate?.casetypecode !== "") {
      try {
        dispatch(setLoader(true));
        await axios
          .put(
            `${url}api/Profile/UpdateCase?CaseTitle=${newstate?.title}&caseDescription=${newstate?.description}&casetypecode=${newstate?.casetypecode}&CaseID=${data?.id}`
          )
          .then((res) => {
            if (res.status === 200) {
              toast.success("Case updated successfully!", {
                autoClose: 5000,
                hideProgressBar: true,
                newestOnTop: false,
                closeOnClick: true,
                rtl: false,
                pauseOnFocusLoss: true,
                draggable: true,
                pauseOnHover: true,
                className: "toast-message",
              });
              getAllcase();
              setTitle("");
              setDescription("");
              setType("");
              setimage("");
              setShow(false);
            }
          });
        dispatch(setLoader(false));
      } catch {
        dispatch(setLoader(false));
      }
    } else {
      toast.warn("Please fill mandatory fields", {
        autoClose: 5000,
        hideProgressBar: true,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        className: "toast-warn",
      });
      dispatch(setLoader(false));
      return;
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Card className=" border-0 ">
        <div className="card-header mb-2">
          <div className="row">
            <div className="col">
              <h5>Case </h5>
            </div>
            <div className="col text-right">
              ( {UserData?.firstName} {UserData?.lastName} ,{" "}
              {moment(UserData?.birthday).format("DD-MMM-YYYY")} (
              {UserData?.age}) ,{UserData?.gendercodeName} )
            </div>
          </div>
        </div>
        <Card.Body>
          <Card.Text>
            <div className=" col-md-12 ">
              <div className="row justify-content-between">
                <div className="col-md-6"></div>
                <div className="col-md-6  mb-3  text-end">
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      handleShow(setsaveBtn(true), setupdateBtn(false))
                    }
                  >
                    + Add Case
                  </button>
                </div>
                <div className="table-responsive">
                  <table class="table border-0" id="CaseTable">
                    <thead>
                      <tr>
                        <th>Case Title</th>
                        <th>Ticket Number</th>
                        <th>Description</th>
                        <th>Status</th>
                        <th>Case Type</th>
                        <th>Created On</th>
                        <th>Edit/Attachment</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getcase &&
                        getcase.map((listValue, index) => {
                          return (
                            <tr>
                              <td>{listValue.title}</td>
                              <td>{listValue.ticketNumber}</td>
                              <td>{listValue.description}</td>
                              <td>{listValue.statuscodename}</td>
                              <td>{listValue.casetype}</td>
                              <td>  {moment(listValue.createdOn).format("DD-MMM-yyyy") ? moment(listValue.createdOn).format("DD-MMM-yyyy") : moment(listValue.createdOn).format("DD-MMM-yyyy")}
                              </td>
                              <td>
                                {listValue?.statuscodename==="In Progress" &&
                                  <i
                                    className="fa-solid fa-pencil"
                                    disabled={listValue.envelopeid === ""}
                                    onClick={() =>
                                      handleShow(
                                        listValue,
                                        setupdateBtn(true),
                                        setsaveBtn(false)
                                      )
                                    }
                                  ></i>
                                }
                                <i
                                  className="fa-solid fa-comment ms-3"
                                  disabled={listValue.envelopeid === ""}
                                  onClick={() => {
                                    tocomponentchat(listValue);
                                  }}
                                ></i>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>

              <Modal show={show} onHide={handleClose} size="lg">
                <form>
                  <Modal.Header closeButton>
                    {saveBtn ? (
                      <Modal.Title>Add Case</Modal.Title>
                    ) : (
                      <Modal.Title> Edit Case</Modal.Title>
                    )}
                  </Modal.Header>
                  <Modal.Body>
                    <div className="ms-3 row">
                      <label className="col-sm-3 col-form-label ">
                        Case Title
                        <span className="required"></span> :
                      </label>
                      <div className="col-sm-8 mb-3 d-flex">
                        <input
                          className="form-control m-1   border-1"
                          placeholder="Enter case title"
                          name="title"
                          id="title"
                          onChange={(e) =>
                            updateNewState({ title: e.target.value })
                          }
                          value={newstate?.title}
                        />
                      </div>

                      <label className="col-sm-3 col-form-label">
                        Description 
                        <span className="required"></span>
                        :
                      </label>
                      <div className="col-sm-8 mb-3 d-flex ">
                        <textarea
                          rows="5"
                          cols="70"
                          className="m-1"
                          id="description"
                          placeholder="Enter description of case"
                          name="description"
                          onChange={(e) =>
                            updateNewState({ description: e.target.value })
                          }
                          defaultValue={newstate?.description}
                          style={{ border: "1px solid #ced4da" }}
                        />
                      </div>

                      <label className="col-sm-3 col-form-label ">
                        Case Type 
                        <span className="required"></span> :
                      </label>
                      <div className="col-sm-8 mb-3 d-flex">
                        <select
                          className="form-select  m-1  border-1"
                          id="casetypecode"
                          aria-label="Floating label select example"
                          name="casetypecode"
                          onChange={(e) =>
                            updateNewState({ casetypecode: e.target.value })
                          }
                          value={newstate?.casetypecode}
                        >
                          <option value="">Select</option>
                          <option
                            value="1"
                            selected={newstate?.casetypecode === 1}
                          >
                            Question
                          </option>
                          <option
                            value="2"
                            selected={newstate?.casetypecode === 2}
                          >
                            Problem{" "}
                          </option>
                          <option
                            value="3"
                            selected={newstate?.casetypecode === 3}
                          >
                            Request
                          </option>
                        </select>
                      </div>
                      {saveBtn ? (
                        <>
                          <label className="col-sm-3 col-form-label ">
                            Upload file :
                          </label>
                          <div className="col-sm-8 ">
                            <input
                              type="file"
                              id="fileJsUpload"
                              name="file"
                              onChange={(e) =>
                                updateNewState({ image: e?.target?.files[0] })
                              }
                              multiple
                            />
                            <div className="col-sm-12 mt-2">
                              <span className="" id="file_input_help">
                                (jpg, jpeg, png, xlsx, word, pdf, mp4, mp3,
                                docx, doc, txt)
                              </span>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    {saveBtn ? (
                      <Button className="btn btn-primary" onClick={handleSubmit}>
                        Save
                      </Button>
                    ) : null}
                    {updateBtn ? (
                      <Button
                        variant="secondary"
                        className="btn btn-primary"
                        onClick={UpdatetoCase}
                      >
                        Update
                      </Button>
                    ) : null}
                    <Button
                      variant="secondary"
                      className="btn btn-primary"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </form>
              </Modal>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}
