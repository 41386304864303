import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router';
import './KbArticlecss.css';
import Iframe from 'react-iframe'
import { useDispatch } from "react-redux";
import { setLoader } from '../Store/Reducers/UserReducer';
import './KbArticlecss.css'
import moment from "moment";
import { GettheHmToken } from '../services/AllApiCall';
var UserData;
export default function KbArticleDetails(props) {
    UserData = JSON.parse(localStorage.getItem("UData"));
    const dispatch = useDispatch();
    const url = process.env.REACT_APP_OXAR_API_URL;
    const [VideoUrl, setVideoUrl] = useState();
    const [clinicvalue, setclinicvalue] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        let ClinicName = localStorage.getItem("ClinicName");
        setclinicvalue(ClinicName);
        getdata();
    }, []);

    const getdata = async () => {
        let KBId = localStorage.getItem("KbarticeleID");
        let MainUserId = localStorage.getItem("UserID");
        let ClinicName = localStorage.getItem("ClinicName");
        dispatch(setLoader(true));

        axios.get(`${url}api/KBArticle/GetById?id=${KBId}&patientId=${MainUserId}`).then((response) => {
            if (ClinicName !== "BCRM") {
                var newstr = response.data.content['content'].replace("margin-top:25%", "margin-top:5%");
                newstr = newstr.replace("footer", "RemoveFooter");
                let TableData = document.getElementById("IdToBeAppent1")
                TableData.innerHTML = newstr;
            }
            else {
                setVideoUrl(response.data.content.content)
            }
            dispatch(setLoader(false));

        }, (error) => {
            if (error.response.data["errorMessage"] === "Token Expire") {
                GettheHmToken();
            }
            dispatch(setLoader(false));
        });
    };

    const printPage = () => {
        const printContent = document.getElementById("IdToBeAppent1");
        window.document.write(printContent.innerHTML);
        window.document.close();
        window.focus();
        window.print();
        window.close();
        window.location.reload(false);
    };

    return (
        <>
            <div className='row  m-0 mb-3'>
                <div className='card-header mb-2'>
                    <div className="row">
                        <div className="col">
                            <h5>Knowledge Base </h5>
                        </div>
                        <div className="col text-right">

                            ( {UserData?.firstName} {UserData?.lastName} , {moment(UserData?.birthday).format("DD-MMM-YYYY")} ({UserData?.age}) ,{UserData?.gendercodeName} )
                            {/* &nbsp;   <a className="btn btn-primary " onClick={printPage}> Print</a> */}
                            &nbsp; <a href="#/KbArticle" className="text-info fs-5 " onClick={() => navigate("/KbArticle")}>
                                <i className=" fa-solid fa-circle-arrow-left fs-3 text-dark"></i>
                            </a>
                           
                        </div>
                    </div>
                </div>
                <div className='col-md-1'></div>
                <div className='col-md-10  col-lg-10 col-xl-10 col-sm-10  '>
                    <br />
                    {
                        clinicvalue !== 'BCRM' &&
                        <div id="IdToBeAppent1" className="fixed-Div"></div>
                    }
                    {
                        clinicvalue === 'BCRM' &&
                        <div style={{ height: "1000px" }}>
                            {/* <iframe src={VideoUrl} width="100%" /> */}
                            <Iframe url={VideoUrl} width="100%" height="990px" id="" className="" display="block" position="relative" /> </div>
                    }

                </div>
                <div className='col-md-1'></div>

            </div>
        </>
    )


}
