import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Swal from "sweetalert2";
import { useNavigate } from 'react-router';
import { useDispatch } from "react-redux";
import { setLoader } from '../Store/Reducers/UserReducer';
import LoginFun from '../Layout Component/Loginfun';
import './KbArticlecss.css';
import { GettheHmToken } from '../services/AllApiCall';
import moment from "moment";
import $ from 'jquery';
var UserData;

export default function KbArticle(props) {
  UserData = JSON.parse(localStorage.getItem("UData"));
  const { SetSendDatakb } = props;
  const url = process.env.REACT_APP_OXAR_API_URL;
  const [kbarticle, setkbarticle] = useState([]);
  const dispatch = useDispatch();
  const datalogin = LoginFun();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setLoader(true));
    getKbArticle();

    var currentTime = '';
    currentTime = (Math.round(new Date().getTime() / 1000));
    var Expiretime = localStorage.getItem("ExpirretokenTime");
    if (Expiretime < currentTime) {
      
      sessionStorage.getItem('isLoggedIn', false)
      Swal.fire({
        title: 'Session Expired',
        text: "Your session has been expired! Please login again",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Okay',
      }).then(result => {
        datalogin.logout();
      })
    }
  }, []);

  const getKbArticle = async () => {
    let ClinicName = localStorage.getItem("ClinicName");
    dispatch(setLoader(true));
    axios.get(`${url}api/KBArticle/GetAllKbArticles?clinic=${ClinicName}`).then((response) => {
      if (response.data.statusCode === 200) {
        setkbarticle(response.data.content);
        $("#observationsContactdata").DataTable();
      }
      dispatch(setLoader(false));
    }, (error) => {
      if (error.response.data["errorMessage"] === "Token Expire") {
        GettheHmToken();
      }
      dispatch(setLoader(false));
    });
  };

  const Test = async (data) => {
    const sendata = data;
    SetSendDatakb(sendata)
    localStorage.setItem("KbarticeleID", data.id);
    navigate(`/KbArticleDetails/${data.id}`)
  }

  return (
    <>
      <Card className=' border-0 '>
        {/* <Card.Header className=' colorprofile fs-3 contactusfont text-light'>Knowledge Base</Card.Header> */}
        <div className='card-header mb-2'>
          <div className="row">
            <div className="col">
              <h5>Knowledge Base </h5>
            </div>
            <div className="col text-right">
              ( {UserData?.firstName} {UserData?.lastName} , {moment(UserData?.birthday).format("DD-MMM-YYYY")} ({UserData?.age}) ,{UserData?.gendercodeName} )
            </div>
          </div>
        </div>
        <Card.Body>
          <Card.Text>
            <div className="table-responsive mt-2 ">
              <table class="table table-border" id="observationsContactdata">
                <thead class="thead-light">
                  <tr>
                    <th>Article Number</th>
                    <th>Title</th>
                    <th>Created on </th>
                  </tr>
                </thead>
                <tbody>
                  {kbarticle && kbarticle.map((listValue, index) => {
                    return (
                      <tr key={index} onClick={() => Test(listValue)}>
                        <td id="Kbarticlecssmainclick" className='text-info'>{listValue.articlePublicNumber}</td>
                        <td>{listValue.title}</td>
                        <td>{moment(listValue.createdon).format('DD-MMM-yyyy')}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  )
}
