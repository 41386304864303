import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Card } from 'react-bootstrap';
import ReactCodeInput from "react-code-input";
import { Container, Row, Col } from 'react-bootstrap';
import { Button } from "react-bootstrap";
import { getUser } from "../Store/Reducers/UserReducer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setLoader } from '../Store/Reducers/UserReducer';
import { setNestedObjectValues } from 'formik';
import { useNavigate } from 'react-router';
import './PinCode.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $, { data } from "jquery";
var CORRECT_PIN_CODE;

export default function PinCode(props) {
    const url = process.env.REACT_APP_OXAR_API_URL;
    const dispatch = useDispatch();
    const uData = useSelector(getUser);
    const navigate = useNavigate();

    
    const [isPinCodeValid, setIsPinCodeValid] = useState(true);

    const [pinCode, setPinCode] = useState("");

    const [btnIsPressed, setBtnIsPressed] = useState(false);
    const [status, setstatus] = useState(false);
    const [LoginMethod, setLoginMethod] = useState("");

    useEffect(() => {
        
        let LoginMethod2 = localStorage.getItem("LoginMethod");
        setLoginMethod(LoginMethod2);
        SendOTP();
    }, [])


    const SendOTP = async () => {
        
        if (props.NumOrEmail === 1) {
            try {

                let mail = uData.email.replace(/@/g, "%40");
                CORRECT_PIN_CODE = Math.floor(1000 + Math.random() * 9000);
                let encoded = window.btoa(CORRECT_PIN_CODE);
                
                await axios.post(`${url}api/Profile/SendEmailOtp?OTP=${encoded}&email=${mail}`).then((res) => {
                    console.log("************OTP SENT Mail**************")
                    if (res.status = "ok") {

                        setstatus("Success");
                        toast.success('OTP Send successfully!', {
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            newestOnTop: false,
                            autoClose: 3000,
                            hideProgressBar: true,
                           // transition: Slide,
                            className: 'toast-message'
                        });
                        

                    }
                    else {
                        setstatus("Failed")
                    }
                });
                dispatch(setLoader(false));
            }
            catch {
                dispatch(setLoader(false));
            }
        }

        if (props.NumOrEmail === 2) {
           // try {

          //  if(uData.mobileNumber.includes("+") == true){
          
               var mainMobileNumber = uData.mobileNumber

                CORRECT_PIN_CODE = Math.floor(1000 + Math.random() * 9000);

                let encoded = window.btoa(CORRECT_PIN_CODE);
                

                if(uData.mobileNumber.startsWith("+44") == true || uData.mobileNumber.startsWith("44") == true ){
                    mainMobileNumber = uData.mobileNumber;
                }
                else if(uData.mobileNumber.startsWith("0") == true){
                    var numbermain = uData.mobileNumber.toString().substring(1);
                    mainMobileNumber =  "+44"+ numbermain;
                }
                else{
                    mainMobileNumber = "+44"+ numbermain;
                }
                    await axios(`${url}api/Profile/SendOTP?DataForSendSMS=${encoded}&MobileNumber=${mainMobileNumber}`).then((res) => {
                    console.log("************OTP SENT SMS**************")
                    setstatus(res);
                    toast.success('OTP Send successfully!', {
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        newestOnTop: false,
                        autoClose: 3000,
                        hideProgressBar: true,
                       // transition: Slide,
                        className: 'toast-message'
                    });

                });
                dispatch(setLoader(false));
            //}

            // else{

            //     dispatch(setLoader(false));
            //     toast.error('Incorrect Mobile Number, Please contact your clinic', {
            //         closeOnClick: true,
            //         pauseOnHover: true,
            //         draggable: true,
            //         progress: undefined,
            //         autoClose: 3000,
            //         hideProgressBar: true,
            //         theme: "colored"
            //     }
            //     )
            // }
                
            //}
            // catch {
            //     dispatch(setLoader(false));
            // }
        }

    };


    const checkPinCode = () => {
        
        const isPinCodeValid = JSON.parse(pinCode) === CORRECT_PIN_CODE;
        if (JSON.parse(pinCode) === CORRECT_PIN_CODE) {
            var SatustoSend = "Success"

            var DTOTOSEND = {
                name: CORRECT_PIN_CODE,
                method: props.NumOrEmail,
                result: SatustoSend
            };
             axios.post(`${url}api/Profile/UpdateLoginInformation`,DTOTOSEND).then((res) => {
            //await axios.post(`${url}api/Profile/UpdateLoginInformation?name=${CORRECT_PIN_CODE}&method=${props.NumOrEmail}&result=${SatustoSend}`).then((res) => {
                console.log("*********************** Update Login Info *************************")
            });
            props.Setmfalogin('false');
            localStorage.setItem("mfalogin", 'false');
            localStorage.setItem("ShowSideNav", 'false');
          //  navigate('/profileData');
            navigate('/PatientInfo');
        }
        else{
            var SatustoSend1 = "Fail"
            var DTOTOSEND1 = {
                name: CORRECT_PIN_CODE,
                method: props.NumOrEmail,
                result: SatustoSend1
            };
            //await axios.post(`${url}api/Profile/UpdateLoginInformation?name=${CORRECT_PIN_CODE}&method=${props.NumOrEmail}&result=${SatustoSend}`).then((res) => {
            axios.post(`${url}api/Profile/UpdateLoginInformation`,DTOTOSEND1).then((res) => {
                console.log("*********************** Update Login Info WRONG *************************")
            });
            toast.error('OTP Incorrect, Please try again', {
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 3000,
                hideProgressBar: true,
                theme: "colored"
            }
            )
            // $("#pinCode").val('');
            // setPinCode("");
            document.getElementById("pinCode").reset();
        }
    };

    const handlePinChange = (pinCode) => {
        
        setPinCode(pinCode);
        setBtnIsPressed(false);
    };

    return (
        <div className='col-md-12 mt-5'>
            <div className="row m-0 mb-5">
                <div className='col-md-4'></div>
                <div className="col-md-4">
                    <Card className='text-center pincodefilter' >
                        <br />
                        <h6 className="mb-3"><b>Please Enter OTP</b></h6>
                        <ReactCodeInput
                            id="pinCode"
                            type="password"
                            isValid={isPinCodeValid}
                            fields={4}
                            onChange={handlePinChange}
                            value={pinCode}
                            />
                        <div className='row pb-4'>
                            <div className='col'></div>
                            <div className='col m-2 p-2'>
                                <Button className=" btn btn-primary" onClick={() => checkPinCode()}>Verify</Button>&nbsp;
                                <Button className="btn btn-primary" onClick={() => SendOTP()}>Resend</Button>
                            </div>
                            <div className='col'></div>
                        </div>
                    </Card>
                </div>
                <div className='col-md-4'></div>
            </div>
        </div>

    )
}

