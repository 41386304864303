import axios from "axios";
import React, { useEffect } from 'react';
import Swal from "sweetalert2";
import './StepWizard.css';
import { useNavigate } from 'react-router';
import { useDispatch } from "react-redux";
import { setUser,setLoader } from "../Store/Reducers/UserReducer";
import { getProfileData } from "../services/ProfileService";
import { Button } from "react-bootstrap";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';
import LoginFun from "../Layout Component/Loginfun";

export default function StepperFoot(props) {
    const url = process.env.REACT_APP_API_URL;
    const { ProfileData } = props;
    const { next } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const logindata = LoginFun();

    const profileDetail = async () => {
        try {
            
            dispatch(setLoader(true));
            let MainUserId = localStorage.getItem("UserID");
            const res = await getProfileData(MainUserId)
            dispatch(setUser(res.content));
            if (res.content)
                dispatch(setLoader(false));
        } catch (e) {
        }
    }

    useEffect(() => {
        sessionCheck();
    }, []);

    const sessionCheck = async () => {
        var currentTime = ''
        currentTime = (Math.round(new Date().getTime() / 1000));
        var Expiretime = localStorage.getItem("ExpirretokenTime");
        
        if (Expiretime < currentTime) {
            
            sessionStorage.getItem('isLoggedIn', false)
            Swal.fire({
                title: 'Session Expired',
                text: "Your session has been expired! Please login again",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Okay',
            }).then(result => {
                logindata.logout();
            })
        }
    }


    const updateprofile = async (event) => {
        
        sessionCheck();
        try {
            
            dispatch(setLoader(true));
            const res = await axios.put(`${url}/api/Profile/Update`, ProfileData);
            if (res.status === 200) {
                toast.success('Patient updated successfully!', {
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    autoClose: 3000,
                    hideProgressBar: true,
                    transition: Bounce,
                    className: 'toast-message',
                    toastid:'steperfoot'
                }
                )
                profileDetail();
                dispatch(setLoader(false));
                navigate('/MedicalHistory')
            }

        }
        catch (e) {
            toast.error('Error while updating!', {
                autoClose: 5000,
                hideProgressBar: true,
                newestOnTop: false,
                closeOnClick: true,
                rtl: false,
                pauseOnFocusLoss: true,
                draggable: true,
                pauseOnHover: true,
                theme: "colored",
                toastid:'steperfoot'
            }
            )

        }
    }

    return (
        <>

            <div className=' d-flex row justify-content-between'>
                <div className="col-md-4 text-start">
                    {/* <button className="m-2 p-2 ps-4 pe-4" onClick={() => navigate(-1)}> Back</button> */}
                    <Button disabled={props.step < 1} onClick={() => { props.previous(); }} className="   btn btn-primary">Back</Button>
                </div>
                <div className="col-md-4 text-end ">
                    {!(props.step >= props.stepCount - 1) ?
                        <Button onClick={() => next()} className="    btn btn-primary">
                            {ProfileData?.areYousureYouwanttosubmit === true ? "NEXT" : "SAVE / NEXT"}
                        </Button>
                        :
                        <Button className="  btn btn-primary" disabled={ProfileData?.areYousureYouwanttosubmit === true}
                            onClick={() => updateprofile()}>UPDATE</Button>
                    }
                </div>
            </div>
        </>
    )
}
