import React, { useEffect, useState } from 'react'
import { MemoryRouter as Router, HashRouter, Route, Routes } from "react-router-dom";
import Header from './Header';
import Home from '../Home';
import Footer from './Footer';
import Profile from '../Profile Component/Profile';
import Appointment from '../Profile Component/Appointment';
import Invoices from '../Profile Component/Invoices';
import ProfileData from '../Profile Component/ProfileData';
import ObservationResult from '../Profile Component/ObservationResult';
import Checklist from '../Profile Component/Checklist';
import Documents from '../Profile Component/Documents';
import Allergies from '../Profile Component/MedicalHistory Component/Allergies';
import PreviousFertilityTreatment from '../Profile Component/MedicalHistory Component/PreviousFertilityTreatment';
import PreviousProcedure from '../Profile Component/MedicalHistory Component/PreviousProcedure';
import DrugReminder from '../Profile Component/MedicalHistory Component/DrugReminder';
import CheckListDetails from '../Profile Component/CheckListDetails';
import HL7ObservationDetail from '../Profile Component/MedicalHistory Component/HL7ObservationDetail';
import PreviousPregnancy from '../Profile Component/MedicalHistory Component/PreviousPregnancy';
import Prescription from '../Profile Component/MedicalHistory Component/Prescription';
import TreatmentOutcome from '../Profile Component/MedicalHistory Component/TreatmentOutcome'
import TreatmentWithUs from '../Profile Component/MedicalHistory Component/TreatmentWithUs';
import MedicalHistory from '../Profile Component/MedicalHistory Component/MedicalHistory';
import PartnerDetails from '../Profile Component/PartnerDetails';
import Conditions from '../Profile Component/Conditions';
import KbArticle from '../Profile Component/KbArticle';
import KbArticleDetails from '../Profile Component/KbArticleDetails';
import PrescriptionDetail from '../Profile Component/MedicalHistory Component/PrescriptionDetail';
import ShowProfileData from '../Profile Component/ShowProfileData';
import Cookies from './cookies';
import CookiesDetails from './CookiesDetails';
import ProfilePreview from '../Profile Component/MedicalHistory Component/ProfilePreview';
import { getLoader } from '../Store/Reducers/UserReducer';
import { useSelector } from 'react-redux';
import MedicalHistoryNav from '../Profile Component/MedicalHistory Component/MedicalHistoryNav';
import Help from '../Profile Component/Help';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import LoginMFA from '../LoginInfo/LoginMFA';
import PinCode from '../LoginInfo/PinCode';
import ChatCase from '../Profile Component/CustomerCase/ChatCase';
import CaseCustomer from '../Profile Component/CustomerCase/CaseCustomer';

export default function Layout1({ setRout, isLoading }) {
    const Loader = useSelector(getLoader);
    const [mfalogin, Setmfalogin] = useState("true");
    const [SendData, SetSendData] = useState();
    const [NumOrEmail, SetNumOrEmail] = useState();
    const [SendDatakb, SetSendDatakb] = useState();
    useEffect(() => {
        if (localStorage.getItem("mfalogin")) {
            Setmfalogin(localStorage.getItem("mfalogin"));
        }
    })
    return (
        <>
            <ToastContainer />
            {Loader &&
                        <div className="loader-container" >
                            <div className="spinner"></div>
                        </div>
                    }
            <HashRouter>
                <Header setRout={setRout} />
                <div >
                  
                    {
                        window.location.pathname !== "/Home"
                    }
                    <div className={mfalogin === "false" ? "main-wrapper" : null}>
                        <div className={mfalogin === "false" ? "page-wrapper" : null}>
                            <div className={mfalogin === "false" ? "page-content" : null}>
                                <div className={mfalogin === "false" ? "row" : null}>
                                    <div className={mfalogin === "false" ? "col-md-12  stretch-card" : null}>
                                        <div className={mfalogin === "false" ? "card" : null}>
                                            <div className={mfalogin === "false" ? "card-body" : null}>
                                                <Routes>
                                                    <Route path="/">
                                                        <Route path='LoginMFA' element={<LoginMFA SetNumOrEmail={SetNumOrEmail} />} />
                                                        <Route path='PinCode' element={<PinCode NumOrEmail={NumOrEmail} Setmfalogin={Setmfalogin} />} />
                                                        <Route path="Home" element={<Home setRout={setRout} />} />
                                                        {
                                                            mfalogin === "false" ?
                                                                <>
                                                                    <Route index path="profile" element={<Profile />} />
                                                                    <Route path="profileData" element={<ProfileData isLoading={isLoading} />} />
                                                                    <Route path="PartnerDetails" element={<PartnerDetails />} />
                                                                    <Route path="MedicalHistory" element={<MedicalHistory />} />
                                                                    <Route path="MedicalHistoryNav" element={<MedicalHistoryNav />} />
                                                                    <Route path="Appointment" element={<Appointment />} />
                                                                    <Route path="Conditions" element={<Conditions />} />
                                                                    <Route path="CaseCustomer" element={<CaseCustomer />} />
                                                                    <Route path="ChatCase" element={<ChatCase />} />
                                                                    <Route path="Invoices" element={<Invoices />} />
                                                                    <Route path="Checklist" element={<Checklist />} />
                                                                    <Route index path="PatientInfo" element={<ShowProfileData />} />
                                                                    <Route path="ProfilePreview" element={<ProfilePreview setRout={setRout} />} />
                                                                    <Route path="ObservationResult" element={<ObservationResult SetSendData={SetSendData} SendData={SendData} />} />
                                                                    <Route path="CheckListDetails" element={<CheckListDetails />} />
                                                                    <Route path="Documents" element={<Documents />} />
                                                                    <Route path="TreatmentWithUs" element={<TreatmentWithUs />} />
                                                                    <Route path="TreatmentWithUs" element={<TreatmentWithUs />} />
                                                                    <Route path="PreviousProcedure" element={<PreviousProcedure />} />
                                                                    <Route path="TreatmentOutcome" element={<TreatmentOutcome />} />
                                                                    <Route path="Allergies" element={<Allergies />} />
                                                                    <Route path="PreviousFertilityTreatment" element={<PreviousFertilityTreatment />} />
                                                                    <Route path="Prescription" element={<Prescription />} />
                                                                    <Route path="PrescriptionDetail" element={<PrescriptionDetail />} />
                                                                    <Route path="DrugReminder" element={<DrugReminder />} />
                                                                    <Route path="PreviousPregnancy" element={<PreviousPregnancy />} />
                                                                    <Route path="HL7ObservationDetail" element={<HL7ObservationDetail SendData={SendData} />} />
                                                                    <Route path="KbArticle" element={<KbArticle SetSendDatakb={SetSendDatakb} SendDatakb={SendDatakb} />} />
                                                                    <Route path="KbArticleDetails/:id" element={<KbArticleDetails SendDatakb={SendDatakb} />} />
                                                                </>
                                                                :
                                                                null
                                                        }
                                                        <Route path="cookies" element={<Cookies />} />
                                                        <Route path="cookiesdetails" element={<CookiesDetails />} />
                                                        <Route path="Help" element={<Help />} />
                                                    </Route>
                                                </Routes >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </HashRouter >
        </>
    )
}
