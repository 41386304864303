import React, { useEffect } from 'react'
import { Card, Button } from 'react-bootstrap'
import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Modal } from 'react-bootstrap';
import $ from 'jquery'
import moment from "moment";
import './PreviousProcedure.css';
import { useDispatch } from "react-redux";
import { setLoader } from "../../Store/Reducers/UserReducer"
import LoginFun from './../../Layout Component/Loginfun';
import MedicalHistoryNav from "./MedicalHistoryNav";
import 'react-toastify/dist/ReactToastify.css';
import { Slide,  Flip, Bounce } from 'react-toastify';
import {  toast } from 'react-toastify';


export default function PreviousProcedure() {
  const dispatch = useDispatch();
  const url = process.env.REACT_APP_OXAR_API_URL;
  const [prevProcedure, setprevProcedure] = useState([]);
  const [updateallergies, setupdateallergies] = useState([]);
  const [show, setShow] = useState(false);
  const [data, setData] = useState();
  const [saveBtn, setsaveBtn] = useState(false);
  const [updateBtn, setupdateBtn] = useState(false);
  const handleClose = () => setShow(false);
  const logindata = LoginFun();
  const [ChekSelectsnomed, setChekSelectsnomed] = useState(false);
  const handleShow = (dataall) => {
    
    dispatch(setLoader(true));
    setSnomedName("");
    setquerydata([]);
    setChekSelectsnomed(false);
    if (dataall !== undefined) {
      setupdateallergies(dataall);
      var datapush = [];
      var data1 = {
        ID: dataall.code,
        Name: dataall.procedureType,
      }
      datapush.push(data1);
      setquerydata(datapush);
      setSnomedName(dataall.procedureType);
      setData(dataall);
    }
    else {
      setChekSelectsnomed(true);
    }
    // setData('')
    setShow(true)
    dispatch(setLoader(false));
  };

  const checkSpecial = (e) => {
    if (!/^[a-zA-Z0-9\s]*$/.test(e.key)) {
      e.preventDefault();
    }
  }

  useEffect(() => {
    dispatch(setLoader(true));
    setTimeout(() => {
      getAllprevProcedure();
    }, 1000);
    sessionCheck();

  }, []);
  const sessionCheck = async () => {
    var currentTime = ''
    currentTime = (Math.round(new Date().getTime() / 1000));
    var Expiretime = localStorage.getItem("ExpirretokenTime");
    
    if (Expiretime < currentTime) {
      
      sessionStorage.getItem('isLoggedIn', false)
      Swal.fire({
        title: 'Session Expired',
        text: "Your session has been expired! Please login again",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Okay',
      }).then(result => {
        logindata.logout();
      })
    }
  }

  const getAllprevProcedure = async () => {
    dispatch(setLoader(true));
    try {
      $('#PrevTreatmentCycleTable').DataTable().destroy();
      let MainUserId = localStorage.getItem("UserID");
        await axios(`${url}api/PreviousProcedure/GetByContactId?id=${MainUserId}`).then((res) => {
          
          for (var i = 0; i < res.data.content.length; i++) {
            if (res.data.content[i].procedureDate !== null) {
              res.data.content[i].procedureDate = res.data.content[i]['procedureDate'].substring(0, 10)
            }
            if (res.data.content[i].procedureDate === "0001-01-01") {
              res.data.content[i].procedureDate = "";
            }
          }
          setprevProcedure(res.data.content);
          $("#PrevTreatmentCycleTable").DataTable();
          dispatch(setLoader(false));
        });
        dispatch(setLoader(false));
    }
    catch {
      dispatch(setLoader(false));
    }
  };
  const baseUrl = "https://browser.ihtsdotools.org/snowstorm/snomed-ct";
  const edition = "MAIN";
  const version = "2019-07-31";
  const [procedureDate, setprocedureDate] = useState("");
  const [anaestheticType, setanaestheticType] = useState("");
  const [comments, setcomments] = useState("");
  const [querydata, setquerydata] = useState([]);
  const [query, setQuery] = useState("");
  const [SnomedName, setSnomedName] = useState("");
  const [queryName, setQueryName] = useState("");

  const getData = (value) => {
    
    if (value === "Select") {
      setSnomedName("");
    }
    var a = querydata.find(x => x['ID'] === value)
    setSnomedName(a.Name);

  }



  const handleSubmit = async (event) => {
    if (SnomedName !== "") {
      dispatch(setLoader(true));
      event.preventDefault();
debugger
      let postdata = {
        procedureType: SnomedName,
        procedureDate: procedureDate,
        anaestheticType: anaestheticType,
        gyanecological: updateallergies.gyanecological,
        abdominal: updateallergies.abdominal,
        comments: comments,
        query1: queryName,
      }
      
      try {
        dispatch(setLoader(true));
        let MainUserId = localStorage.getItem("UserID");
        const res = await axios.post(`${url}api/PreviousProcedure/Create?userId=${MainUserId}`, postdata);
        
        if (res.status === 200) {
          toast.success('Previous procedure created successfully!', {
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            autoClose: 3000,
            hideProgressBar: true,
            transition: Bounce,
            className: 'toast-message'

          }
          )
          setSnomedName("");
          setprocedureDate("");
          setanaestheticType("");
          updateallergies.abdominal = false;
          updateallergies.gyanecological = false;
          setcomments("");
          setQueryName("");

          setShow(false);
          getAllprevProcedure();

        }
        dispatch(setLoader(false));
      }
      catch (e) {
        if(e.response.data.errorMessage == "Invalid json"){
          toast.error('Please dont use special characters in fields ', {
            autoClose: 5000,
            hideProgressBar: true,
            newestOnTop: false,
            closeOnClick: true,
            rtl: false,
            pauseOnFocusLoss: true,
            draggable: true,
            pauseOnHover: true,
            theme: "colored"
          }
          )
          dispatch(setLoader(false));
        return
        }
        else{
          toast.error('Error while creating', {
            autoClose: 5000,
            hideProgressBar: true,
            newestOnTop: false,
            closeOnClick: true,
            rtl: false,
            pauseOnFocusLoss: true,
            draggable: true,
            pauseOnHover: true,
            theme: "colored"
          }
          )
          dispatch(setLoader(false));
        return
        }
        return
        dispatch(setLoader(false));
      }
    }
    else {
      toast.warn('Please fill mandatory fields', {
        autoClose: 5000,
        hideProgressBar: true,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        className: 'toast-warn'
      }
      )
      dispatch(setLoader(false));
      return
    }
  }

  const updateAllergies = async (updatedvalue) => {
    dispatch(setLoader(true));
    var value = "";
    var name = updatedvalue.target.name;

    if (name === "gyanecological") {
      var value1 = updatedvalue.target.checked;
      value = value1
    }

    else if (name === "abdominal") {
      let value2 = updatedvalue.target.checked;
      value = value2
    }

    else {
      var value = updatedvalue.target.value;
    }
    dispatch(setLoader(false));
    setupdateallergies(values => ({ ...values, [name]: value, 'id': data?.id }));
  }

  //UPdate allergies data API
  const updateAllergiesData = async (e) => {
    dispatch(setLoader(true));
    sessionCheck();
    e.preventDefault();
    let MainUserId = localStorage.getItem("UserID");
    try {
      if (updateallergies) {
        updateallergies.procedureType = SnomedName;
      }

      const res = await axios.put(`${url}api/PreviousProcedure/Update?userId=${MainUserId}`, updateallergies);
      if (res.status === 200) {
        toast.success('Previous procedure updated successfully!', {
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          autoClose: 3000,
          hideProgressBar: true,
          transition: Slide,
          className: 'toast-message'
        }
        )
        setSnomedName("");
        setprocedureDate("");
        setanaestheticType("");
        setcomments("");
        updateallergies.abdominal = false;
        updateallergies.gyanecological = false;
        setQueryName("");
        setShow(false);
        getAllprevProcedure();
        dispatch(setLoader(false));

      }
    }
    catch (e) {
      if(e.response.data.errorMessage == "Invalid json"){
        toast.error('Please dont use special characters in fields ', {
          autoClose: 5000,
          hideProgressBar: true,
          newestOnTop: false,
          closeOnClick: true,
          rtl: false,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true,
          theme: "colored"
        }
        )
        dispatch(setLoader(false));
      return
      }
      else{
        toast.error('Error while updating', {
          autoClose: 5000,
          hideProgressBar: true,
          newestOnTop: false,
          closeOnClick: true,
          rtl: false,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true,
          theme: "colored"
        }
        )
        dispatch(setLoader(false));
      return
      }
      dispatch(setLoader(false));
      return
    }
  }

  // for finding query and getting condition to create condition
  const findByQuery = async () => {
    dispatch(setLoader(true));
    if (query === "") {
      return
    }
    let value = query;
    setQueryName(value)

    
    try {
      const response = await fetch(
        `${baseUrl}/${edition}/${version}/concepts?term=${encodeURIComponent(value)}&activeFilter=true&offset=0&limit=20`
      ).then((response) => response.json());

      var dataforsnomed = response["items"];

      var MainPath = [];

      dataforsnomed.forEach(element => {
        var data = {
          ID: element.conceptId,
          Name: element.pt.term
        }
        MainPath.push(data);
      });
      setquerydata(MainPath);

      dispatch(setLoader(false));

    }
    catch (ex) {
      dispatch(setLoader(false));

    }
  }


  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <MedicalHistoryNav />
        <Card  className='border-0'>
          <Card.Body>
              <div className="col text-left bg-white-200  ">
                <div className='d-flex'>
                  <div className='col-md-6'>

                  </div>
                  <div className='col-md-6 d-grid gap-2 d-md-flex justify-content-md-end'>
                    <Button className=' btn btn-primary' onClick={() => handleShow(setsaveBtn(true), setupdateBtn(false))}>Add Procedure +</Button>
                  </div>
                </div>
                <div className="table-responsive">
                                    <table class="table border-0" id="PrevTreatmentCycleTable">
                                        <thead class="">
                                            <tr>
                                                <th> Procedure Type</th>
                                                <th>Procedure Date </th>
                                                <th>Anaesthetic Type</th>
                                                <th> Gynaecological?</th>
                                                <th>Abdominal? </th>
                                                <th>Comments</th>
                                                <th>Created On</th>
                                                <th>Edit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {prevProcedure && prevProcedure.map((listValue, index) => {
                                                return (
                                                    <tr>
                                                        <td>{listValue.procedureType}</td>
                                                        <td>{moment(listValue.procedureDate).format("DD-MMM-YYYY")} </td>
                                                        <td>{listValue.anaestheticType}</td>
                                                        <td>{listValue.gyanecological === false ? "No" : "Yes"}</td>
                                                        <td>{listValue.abdominal === false ? "No" : "Yes"}</td>
                                                        <td>{listValue.comments}</td>
                                                        <td>{moment(listValue.createdOn).format("DD-MMM-YYYY")}</td>
                                                        <td>
                                                            <i class="fa-solid fa-pencil" role="button" disabled={listValue.envelopeid === ""} onClick={() => handleShow(listValue, setupdateBtn(true), setsaveBtn(false))}></i>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
              
              </div>
              <Modal size="lg" show={show} onHide={handleClose} >
                <form>
                  <Modal.Header closeButton>
                    {saveBtn ?
                      <h6> Add Previous Procedure</h6>
                      :
                      <h6> Edit Previous Procedure</h6>
                    }
                  </Modal.Header>
                  <Modal.Body >
                    <div className='ms-3 row'>
                      <label className='col-sm-3 col-form-label ' >Search Procedure : </label>
                      <div className="col-sm-8 d-flex">
                        <input className='form-control m-1  border-1' placeholder='Search Procedure' name="input1" onKeyDown={(e) => checkSpecial(e)} onChange={e => setQuery(e.target.value)} />
                        <i className="fa-solid fa-magnifying-glass m-2 fs-4" onClick={findByQuery}></i>
                      </div>

                      <label className='col-sm-3 col-form-label ' >Procedure Type <span className="required"></span> : </label>

                      <div className="col-sm-8">
                        <select className="form-select border-1 m-1" id="floatingSelect" name="title" onChange={e => getData(e.target.value)}  >
                          {ChekSelectsnomed && <option value={null}>Select</option>}
                          {querydata.map((data, i) => {
                            return (
                              <>
                                <option value={data.ID}> {data?.Name}</option>
                              </>
                            )
                          })
                          }
                        </select>
                      </div>
                      <label className='col-sm-3 col-form-label ' > Procedure Date : </label>
                      <div className="col-sm-8">
                        <input type="date" className='form-control m-1 border-1' id='procedureDate' name="procedureDate" defaultValue={data?.procedureDate} onChange={(e) => updateAllergies(e, setprocedureDate(e.target.value))} />
                      </div>
                      <label className='col-sm-3 col-form-label ' > Anaesthetic Type : </label>
                       <div className="col-sm-8">
                      <input className='form-control m-1 border-1' name="anaestheticType" placeholder="Anaesthetic Type" defaultValue={data?.anaestheticType}
                        onKeyDown={(e) => checkSpecial(e)} onChange={(e) => updateAllergies(e, setanaestheticType(e.target.value))} />
                    </div>

                      <label className='col-sm-3 col-form-label ' >Gynaecological? : </label>
                      <div className="col-sm-8 ">
                        <div className=" form-check form-switch ps-0 ">
                          <label class="switch">
                            <input type="checkbox" name='gyanecological'
                              defaultChecked={data?.gyanecological === true}
                              onChange={(e) => updateAllergies(e)} />
                            <div class="slider round">
                              <span className="on">Yes</span>
                              <span className="off">No</span>
                            </div>
                          </label>
                        </div>

                      </div>
                      <label className='col-sm-3 col-form-label ' >Abdominal? : </label>
                      <div className="col-sm-8 ">

                        {/* <div className="form-check form-switch ">
                                      <input className="form-check-input fs-3" type="checkbox" id="flexSwitchCheckChecked" name='abdominal' value={data?.abdominal} onChange={(e) => updateAllergies(e, setabdominal(e.target.value))} />
                                    </div> */}

                        <div className=" form-check form-switch ps-0">
                          <label class="switch">
                            <input type="checkbox" name='abdominal'
                              defaultChecked={data?.abdominal === true}
                              onChange={(e) => updateAllergies(e)} />
                            <div class="slider round">
                              <span className="on">Yes</span>
                              <span className="off">No</span>
                            </div>
                          </label>
                        </div>


                      </div>

                      <label className='col-sm-3 col-form-label ' >Comments : </label>
                      <div className="col-sm-8">
                       <textarea className='form-control m-1 border-1' placeholder='Comments' name="comments" defaultValue={data?.comments}
                        onKeyDown={(e) => checkSpecial(e)} onChange={(e) => updateAllergies(e, setcomments(e.target.value))} />
                    </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    {
                      updateBtn ?
                        <Button type="button" className='btn btn-primary me-2' onClick={updateAllergiesData} >Update</Button>
                        : null
                    }
                    {saveBtn ?
                      <Button type="button" onClick={handleSubmit} className=' btn btn-primary me-2'  >Save</Button>
                      : null
                    }
                    <Button variant="secondary" className='btn btn-primary' onClick={handleClose}>Close</Button>
                  </Modal.Footer>
                </form>
              </Modal>
          </Card.Body>
        </Card>
      </div>
      {/* <ToastContainer /> */}

    </>
  )
}
