import React, { useEffect } from 'react'
import {  useNavigate } from "react-router-dom";
import { Card, Button } from 'react-bootstrap'
import "./TreatmentOutcome.css";
import { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setLoader } from "../../Store/Reducers/UserReducer"
import CanvasJSReact from '../../Assests/canvasjs.react';
import { Modal } from 'react-bootstrap';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import Swal from "sweetalert2";
import moment from "moment";
import Select from 'react-select';
import $ from "jquery";
import LoginFun from './../../Layout Component/Loginfun';
import 'react-toastify/dist/ReactToastify.css';
import { Flip } from 'react-toastify';
import { toast } from 'react-toastify';
var UserData;
export default function TreatmentOutcome(props) {
    const regex = /^[a-zA-Z0-9\s]*$/;
    UserData = JSON.parse(localStorage.getItem("UData"));
    let UID = localStorage.getItem("UserID");
    let treatment_cycle = localStorage.getItem("Tretmentid");
    const dispatch = useDispatch();
    var CanvasJS = CanvasJSReact.CanvasJS;
    const logindata = LoginFun();
    const url = process.env.REACT_APP_OXAR_API_URL;
    const [TreatmentDataDetails, setTreatmentDataDetails] = useState();
    const navigate = useNavigate();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const [livebirth, setlivebirth] = useState([]);
    const [subdiv, setSubDiv] = useState(false);
    const [show, setShow] = useState(false);
    // update data treatment oucome
    const [DateofPreg, setDateofPreg] = useState("");
    const [resultofPreg, setresultofPreg] = useState("");
    const [data, setData] = useState();
    const [updateallergies, setupdateallergies] = useState([]);
    const [countryBirth, setcountryBirth] = useState([]);
    // on submit model

    const data2 = { ...data }
    const handleCountry = (event) => {
        const countryBirth = event.target.value;
        data2.countryOfBirth = countryBirth
        updateModalData(event)
    }

    const getCountryOfBirth = async () => {
        dispatch(setLoader(true));
        try {
            await axios(`${url}/api/Profile/GetCountries`).then((res) => {
                // setProfileData(res.data.content);
                setcountryBirth(res.data.content);
                dispatch(setLoader(false));
            });
        }
        catch {
            dispatch(setLoader(false));
        }
    }

    const deleteData = async (id) => {
        try {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Do you want to delete ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch(setLoader(true));
                    axios.delete(`${url}api/Treatment/Deletepregnancyoutcomedetails?id=${id}`).then((res => {
                        if (res.status === 200) {
                            toast.success('Your record has been deleted', {
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                autoClose: 3000,
                                hideProgressBar: true,
                                transition: Flip,
                                className: 'toast-message'
                            }
                            )
                            getTreatmentOutcome();
                        }
                        else {
                            toast.error('Error while deleting previous pregnancy', {
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                autoClose: 3000,
                                hideProgressBar: true,
                                transition: Flip,
                                theme: "colored"
                            }
                            )
                        }
                        dispatch(setLoader(false));
                    }))

                }
            })
        }
        catch (e) {
        }
    }

    useEffect(() => {
        dispatch(setLoader(true));
        getTreatmentDataForDetails();
        getTreatmentOutcome();
        getCountryOfBirth();
        var currentTime = ''
        currentTime = (Math.round(new Date().getTime() / 1000));
        var Expiretime = localStorage.getItem("ExpirretokenTime");
        if (Expiretime < currentTime) {
            sessionStorage.getItem('isLoggedIn', false)
            Swal.fire({
                title: 'Session Expired',
                text: "Your session has been expired! Please login again",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Okay',
            }).then(result => {
                logindata.logout();
            })
        }
    }, []);

    const showtreatmentCard = (value) => {
        setresultofPreg(value);
    }

    const getTreatmentDataForDetails = async () => {
        try {
            dispatch(setLoader(true));
            let MainUserId = localStorage.getItem("Tretmentid");
            await axios(`${url}api/Treatment/GetTreatmentCycleById?id=${MainUserId}`).then((res) => {
                setTreatmentDataDetails(res.data.content);
                if (res.data.content !== null || res.data.content !== undefined) {
                    var pregnancytest = res.data.content.bcrm_result_of_pregnancy_test
                    setresultofPreg(pregnancytest);
                    res.data.content.bcrm_date_time_of_early_outcome = res.data.content['bcrm_date_time_of_early_outcome'].substring(0, 10);
                    if (res.data.content.bcrm_date_time_of_early_outcome === "0001-01-01") { res.data.content.bcrm_date_time_of_early_outcome = ""; }
                    res.data.content.firstInjectionDate = res.data.content['firstInjectionDate'].substring(0, 10)
                    if (res.data.content.folliculagramJSON) {
                        DrawFolliculogramTable(JSON.parse(res.data.content.folliculagramJSON));
                        DrawBubbleChart(JSON.parse(res.data.content.folliculagramJSON));
                    }
                }
            });
            dispatch(setLoader(false));
        }
        catch {
            dispatch(setLoader(false));
        }
    };
    // ******************************** API for  getting treatment details outcome *********************************************//
    const getTreatmentOutcome = async () => {
        try {
            let MainUserId = localStorage.getItem("Tretmentid");
            dispatch(setLoader(true));
            await axios(`${url}api/Treatment/GetByIdpregnancyoutcomedetails?id=${MainUserId}`).then((res) => {
                if (res.data.content) {
                    setlivebirth(res.data.content);
                    $("#PrevTreatmentCycleTable").DataTable();
                } else {
                    setlivebirth('');
                }
                dispatch(setLoader(false));
            });
            dispatch(setLoader(false));
        }
        catch {
            dispatch(setLoader(true));
        }
    };

    const updateTreatmentOutcome = async (event) => {
        let MainUserId = localStorage.getItem("Tretmentid");
        const DataToPost = {
            id: MainUserId,
            bcrm_date_time_of_early_outcome: DateofPreg,
            bcrm_result_of_pregnancy_test: resultofPreg,
        }
        try {
            dispatch(setLoader(true));
            const res = await axios.put(`${url}/api/Treatment/UpdateTreatmentOutcome`, DataToPost)
            if (res.status === 200) {
                toast.success('Treatment outcome updated successfully!', {
                    autoClose: 5000,
                    hideProgressBar: true,
                    newestOnTop: false,
                    closeOnClick: true,
                    rtl: false,
                    pauseOnFocusLoss: true,
                    draggable: true,
                    pauseOnHover: true,
                    className: 'toast-message'
                }
                )
            }
            else {
                toast.warn('Error while Updating Treatment Outcome', {
                    autoClose: 5000,
                    hideProgressBar: true,
                    newestOnTop: false,
                    closeOnClick: true,
                    rtl: false,
                    pauseOnFocusLoss: true,
                    draggable: true,
                    pauseOnHover: true,
                    className: 'toast-warn'
                }
                )
            }
            dispatch(setLoader(false));
        }
        catch (e) {
            if(e.response.data.errorMessage == "Invalid json"){
                toast.error('Please dont use special characters in fields ', {
                  autoClose: 5000,
                  hideProgressBar: true,
                  newestOnTop: false,
                  closeOnClick: true,
                  rtl: false,
                  pauseOnFocusLoss: true,
                  draggable: true,
                  pauseOnHover: true,
                  theme: "colored"
                }
                )
                dispatch(setLoader(false));
              return
              }
              else{
                toast.error('Error while updating', {
                  autoClose: 5000,
                  hideProgressBar: true,
                  newestOnTop: false,
                  closeOnClick: true,
                  rtl: false,
                  pauseOnFocusLoss: true,
                  draggable: true,
                  pauseOnHover: true,
                  theme: "colored"
                }
                )
                dispatch(setLoader(false));
              return
              }
              dispatch(setLoader(false));
              return
        }
    }
    const checkSpecial = (e) => {
        if (!/^[a-zA-Z0-9\s]*$/.test(e.key)) {
            e.preventDefault();
        }
    }
    const updateModalData = async (updatedvalue) => {
        var name = updatedvalue.target.name;
        var value = updatedvalue.target.value;
        if (data) {
            data[name] = value;
        }
        setupdateallergies(values => ({ ...values, [name]: value, 'patient': UID, 'treatment_cycle': treatment_cycle }));
        if (updateallergies.forenames && updateallergies.surname) {
            setupdateallergies(values => ({ ...values, "Name": updateallergies.forenames + " " + updateallergies.surname, 'patient': UID, 'treatment_cycle': treatment_cycle }));
        }
    }

    const AddPregnancyOutcomeDetails = async () => {
        if (updateallergies.forenames !== undefined && updateallergies.surname !== undefined && updateallergies.gender !== undefined && updateallergies.weight_in_grams !== undefined && updateallergies.delivery_date !== undefined && updateallergies.gestation !== undefined &&
            updateallergies.nhs_number !== undefined && updateallergies.country_of_birth !== undefined && updateallergies.town_or_district_of_birth !== undefined && updateallergies.town_or_district !== undefined &&
            updateallergies.forenames !== "" && updateallergies.surname !== "" && updateallergies.gender !== "" && updateallergies.weight_in_grams !== "" && updateallergies.delivery_date !== "" && updateallergies.gestation !== "" &&
            updateallergies.nhs_number !== "" && updateallergies.country_of_birth !== "" && updateallergies.town_or_district_of_birth !== "" && updateallergies.town_or_district !== "") {
            dispatch(setLoader(true));
            await axios.post(`${url}/api/Treatment/Createpregnancyoutcomedetails`, updateallergies).then((response) => {
                if (response.status === 200) {
                    toast.success('Pregnancy outcome created successfully!', {
                        autoClose: 5000,
                        hideProgressBar: true,
                        newestOnTop: false,
                        closeOnClick: true,
                        rtl: false,
                        pauseOnFocusLoss: true,
                        draggable: true,
                        pauseOnHover: true,
                        className: 'toast-message'
                    }
                    )
                    //navigate("/MedicalHistory")
                    setShow(false)
                    getTreatmentOutcome();
                }
                else {

                    toast.error('Error while creating Pregnancy  Outcome', {
                        autoClose: 5000,
                        hideProgressBar: true,
                        newestOnTop: false,
                        closeOnClick: true,
                        rtl: false,
                        pauseOnFocusLoss: true,
                        draggable: true,
                        pauseOnHover: true,
                        theme: "colored"
                    }
                    )
                }
                dispatch(setLoader(false));
            }, (error) => {


                if(error.response.data.errorMessage == "Invalid json"){
                    toast.error('Please dont use special characters in fields ', {
                      autoClose: 5000,
                      hideProgressBar: true,
                      newestOnTop: false,
                      closeOnClick: true,
                      rtl: false,
                      pauseOnFocusLoss: true,
                      draggable: true,
                      pauseOnHover: true,
                      theme: "colored"
                    }
                    )
                    dispatch(setLoader(false));
                  return
                  }
                    else{
                        toast.error('Error while creating ', {
                            autoClose: 5000,
                            hideProgressBar: true,
                            newestOnTop: false,
                            closeOnClick: true,
                            rtl: false,
                            pauseOnFocusLoss: true,
                            draggable: true,
                            pauseOnHover: true,
                            theme: "colored"
                        }
                        )
                        dispatch(setLoader(false));
                        return
                    }
                    dispatch(setLoader(false));
                    return
            });
        } else {
            toast.warn('Please fill mandatory fields', {
                autoClose: 5000,
                hideProgressBar: true,
                newestOnTop: false,
                closeOnClick: true,
                rtl: false,
                pauseOnFocusLoss: true,
                draggable: true,
                pauseOnHover: true,
                className: 'toast-warn'
            }
            )
        }
    }

    // Update Pregnancy outcome
    const Updatepregnancyoutcomedetails = async () => {
        if (data.forenames !== "" && data.surname !== "" && data.gender !== "" && data.weight_in_grams !== "" && data.delivery_date !== "" && data.gestation !== "" &&
            data.nhs_number !== "" && data.country_of_birth !== "" && data.town_or_district_of_birth !== "" && data.town_or_district !== "") {
            dispatch(setLoader(true));
            await axios.put(`${url}/api/Treatment/Updatepregnancyoutcomedetails`, data).then((response) => {
                if (response.status === 200) {
                    toast.success('Pregnancy outcome updated successfully!', {
                        autoClose: 5000,
                        hideProgressBar: true,
                        newestOnTop: false,
                        closeOnClick: true,
                        rtl: false,
                        pauseOnFocusLoss: true,
                        draggable: true,
                        pauseOnHover: true,
                        className: 'toast-message'
                    }
                    )
                    setShow(false)
                    setData([]);
                    getTreatmentOutcome();
                }
                else {
                    toast.error('Error while Updating Pregnancy Outcome', {
                        autoClose: 5000,
                        hideProgressBar: true,
                        newestOnTop: false,
                        closeOnClick: true,
                        rtl: false,
                        pauseOnFocusLoss: true,
                        draggable: true,
                        pauseOnHover: true,
                        theme: "colored"
                    }
                    )
                }
                dispatch(setLoader(false));
            }, (error) => {

                if(error.response.data.errorMessage == "Invalid json"){
                    toast.error('Please dont use special characters in fields ', {
                      autoClose: 5000,
                      hideProgressBar: true,
                      newestOnTop: false,
                      closeOnClick: true,
                      rtl: false,
                      pauseOnFocusLoss: true,
                      draggable: true,
                      pauseOnHover: true,
                      theme: "colored"
                    }
                    )
                    dispatch(setLoader(false));
                  return
                  }

                else{
                    toast.error('Error while Updating Pregnancy Outcome', {
                        autoClose: 5000,
                        hideProgressBar: true,
                        newestOnTop: false,
                        closeOnClick: true,
                        rtl: false,
                        pauseOnFocusLoss: true,
                        draggable: true,
                        pauseOnHover: true,
                        theme: "colored"
                    }
                    )
                    dispatch(setLoader(false));
                    return
                }
                dispatch(setLoader(false));
                    return
            });
        } else {
            toast.warn('Please fill mandatory fields', {
                autoClose: 5000,
                hideProgressBar: true,
                newestOnTop: false,
                closeOnClick: true,
                rtl: false,
                pauseOnFocusLoss: true,
                draggable: true,
                pauseOnHover: true,
                className: 'toast-warn'
            }
            )
        }
    }

    const DrawFolliculogramTable = async (JsonObject) => {
        var leftID = 1;
        var RightID = 1;
        var weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        var _start = new Date(JsonObject[0].date)
        var _endDate = new Date(JsonObject[JsonObject.length - 1].date + " 23:59:59");
        let days = (_endDate - _start) / (1000 * 60 * 60 * 24);
        var dateForLoop = new Date(_start);
        var for_day_loop = new Date(_endDate);
        var TableObject = '<table id="table" class="table-bordered" style="width: 100%;"><thead style="position: sticky; top: 0; z-index: 3;background-color: #f0f3f8;"><tr>';
        TableObject += '</tr><tr style="height: 15px; overflow:hidden;font-size: 12px;">'
        //--------------------------------------------------------------Second Row For WeekDays---------------------------------
        for (var a = 0; a <= Math.round(days); a++) {
            if (a === 0) {
                TableObject += '<th class="headcol" style="position: -webkit-sticky; position: sticky; left: 0; top: 0; z-index: 2; background-color: #f0f3f8;">Day</th>';
            }
            else {
                var day = weekday[for_day_loop.getDay()];
                TableObject += '<th class="long" colspan="2" style="position: -webkit-sticky; position: sticky; top: 0; z-index: 1; background-color: #f0f3f8;">' + day + '</th>';
                var newDate = for_day_loop.setDate(for_day_loop.getDate() + 1);
                for_day_loop = new Date(newDate);
            }
        }

        TableObject += '</tr><tr style="height: 15px; overflow:hidden;font-size: 12px;">';
        //------------------------------------------------------------ Fourth Row Data --------------------------------------
        for ( a = 0; a <= Math.round(days); a++) {
            if (a === 0) {
                TableObject += '<th class="headcol" style="position: -webkit-sticky; position: sticky; left: 0; top: 0; z-index: 2;background-color: #f0f3f8;">Date</th>';
            }
            else {
                TableObject += '<th class="long" colspan="2" id="date_pdf' + a + '" style="position: -webkit-sticky; position: sticky; top: 0; z-index: 1; background-color: #f0f3f8;">' + formatDate(dateForLoop) + '</th>';
                 newDate = dateForLoop.setDate(dateForLoop.getDate() + 1);
                dateForLoop = new Date(newDate);
            }
        }

        TableObject += '</tr><tr style="height: 5px; overflow:hidden;font-size: 12px;">';
        //------------------------------------------------------------- Fifth Row Sn -----------------------------------------
        for ( a = 0; a <= Math.round(days); a++) {
            if (a === 0) {
                TableObject += '<th class="headcol" style="position: -webkit-sticky; position: sticky; left: 0; top: 0; z-index: 2;background-color: #f0f3f8;">Day Of Tx</th>';
            }
            else {
                TableObject += '<th class="long" colspan="2" style="position: -webkit-sticky; position: sticky; top: 0; z-index: 1; background-color: #f0f3f8;">' + a + '</th>';
            }
        }
        TableObject += '</tr><tr style="height: 15px; overflow:hidden;font-size: 12px;">';
        //------------------------------------------------------------- Sixth Row MM------------------------------------------
        for ( a = 0; a <= Math.round(days); a++) {
            if (a === 0) {
                TableObject += '<th class="headcol" style="position: -webkit-sticky; position: sticky; left: 0; top: 0; z-index: 2;background-color: #f0f3f8;">In mm</th>';
            }
            else {
                TableObject += '<th style="color:red; position: -webkit-sticky; position: sticky; top: 0; z-index: 1; background-color: #f0f3f8;" >R</th><th style="color:blue; position: -webkit-sticky; position: sticky; top: 0; z-index: 1; background-color: #f0f3f8;">L</th>';
            }
        }
        TableObject += "</tr></thead>";
        var Mm_value = 26;
        for (var Second = 0; Second < 20; Second++) {
            TableObject += '<tr style="height: 15px; overflow:hidden;font-size: 12px;">'
            // To Set MM Values In Table
            for (var third = 0; third <= Math.round(days); third++) {
                if (third === 0) {
                    if (Mm_value > 25) {
                        TableObject += '<th className="headcol"  scope="row" style="position: -webkit-sticky; position: sticky; left: 0; z-index: 1; background-color: #f0f3f8;">>25</th>';
                        Mm_value--;
                    }
                    else {
                        if (Mm_value === 9) {
                            TableObject += '<th className="headcol" style="position: -webkit-sticky; position: sticky; left: 0; z-index: 1; background-color: #f0f3f8;">6-9</th>';
                            Mm_value = 5;
                        }
                        else if (Mm_value === 5) {
                            TableObject += '<th className="headcol" style="position: -webkit-sticky; position: sticky; left: 0; z-index: 1; background-color: #f0f3f8;">2-5</th>';
                            Mm_value = 1;
                        }
                        else if (Mm_value === 1) {
                            TableObject += '<th className="headcol" style="position: -webkit-sticky; position: sticky; left: 0; z-index: 1; background-color: #f0f3f8;">Total</th>';
                            Mm_value = 0;
                        }

                        else {
                            TableObject += '<th className="headcol" style="position: -webkit-sticky; position: sticky; left: 0; z-index: 1; background-color: #f0f3f8;">' + Mm_value + '</th>';
                            Mm_value--;
                        }
                    }
                }
                else {
                    var TempCondiotin = false;
                    try {
                        if (JsonObject !== null && JsonObject[third - 1].Values[Second].Col_R !== undefined && JsonObject[third - 1].Values[Second].Col_l !== undefined) {
                            TempCondiotin = true;
                        }
                    }
                    catch (error) {
                        TempCondiotin = false;
                    }
                    //To Show Data On LOad
                    if (TempCondiotin) {
                        TableObject += '<td className="long" style="color:red;" id="R_pdf' + RightID + '">' + JsonObject[third - 1].Values[Second].Col_R + '</td><td style="color:blue; id="L_pdf' + leftID + '" class="long">' + JsonObject[third - 1].Values[Second].Col_l + '</td>';
                        leftID++;
                        RightID++;
                    }
                    // To Continue Without Date
                    else {
                        TableObject += '<td className="long" style="color:red;" id="R_pdf' + RightID + '"></td><td style="color:blue; class="long" id="L_pdf' + leftID + '"></td>';
                        leftID++;
                        RightID++;
                    }
                }
            }
            TableObject += "</tr>"

            if (Mm_value === 12) {
                TableObject += '<tr style="height: 15px; background-color:#f0f3f8;>';
            }
            if (Mm_value === 18) {
                TableObject += '<tr style="height: 15px;background-color:#f0f3f8;>';
            }
        }
        //---------------------------------------------------------------Row Seperator Two Before Hormones ------------------------------------------------
        if ((Math.round(days)) > 0) {
            TableObject += '<tr style="height: 15px; overflow:hidden;font-size: 12px;background-color:antiquewhite !important;text-align: center !important;">';
            /**Td Start Collapes */
            TableObject += '<th style="position: -webkit-sticky; position: sticky; left: 0; z-index: 1;">Hormones</th><td colspan="' + (Math.round(days)) + '"></td><td class="seperator" colspan="' + (Math.round(days)) + '"></td>';
            /**Td end Collapes */
            TableObject += "</tr>";
        }

        //---------------------------------------------------------------Endometrium Row------------------------------------------------


        TableObject += '<tr style="height: 15px; overflow:hidden;font-size: 12px;">';
        for ( a = 0; a <= Math.round(days); a++) {
            if (a === 0) {

                TableObject += '<th className="headcol" style="position: -webkit-sticky; position: sticky; left: 0; z-index: 1; background-color: #f0f3f8;">Endometrium</th>';
            }
            else {
                 TempCondiotin = false;
                //Put Try Becouse it Create Some Exception Where It HAs No Data
                try {
                    if (JsonObject !== null && JsonObject[a - 1].Endome !== undefined) {
                        TempCondiotin = true;
                    }
                }
                catch (error) {
                    TempCondiotin = false;
                }
                //To Show Data On LOad
                if (TempCondiotin) {

                    TableObject += '<td className="long" id="Endome_pdf' + a + '" colspan="2">' + JsonObject[a - 1].Endome + '</td>';
                }
                // To Continue Without Date
                else {

                    TableObject += '<td classname="long" id="Endome_pdf' + a + '" colspan="2"></td>';
                }
            }
        }
        //---------------------------------------------------------------E2 Row------------------------------------------------

        TableObject += '</tr><tr style="height: 15px; overflow:hidden;font-size: 12px;">';
        for (var a = 0; a <= Math.round(days); a++) {
            if (a === 0) {

                TableObject += '<th class="headcol" style="position: -webkit-sticky; position: sticky; left: 0; z-index: 1; background-color: #f0f3f8;">E2</th>';
            }
            else {
                 TempCondiotin = false;
                //Put Try Becouse it Create Some Exception Where It HAs No Data
                try {
                    if (JsonObject !== null && JsonObject[a - 1].E2 !== undefined) {
                        TempCondiotin = true;
                    }
                }
                catch (error) {
                    TempCondiotin = false;
                }
                //To Show Data On LOad
                if (TempCondiotin) {

                    TableObject += '<td class="long" id="E2_pdf' + a + '" colspan="2">' + JsonObject[a - 1].E2 + '</td>';
                }
                // To Continue Without Date
                else {

                    TableObject += '<td class="long" id="E2_pdf' + a + '" colspan="2"></td>';
                }
            }
        }
        //---------------------------------------------------------------LH Row------------------------------------------------

        TableObject += '</tr><tr style="height: 15px; overflow:hidden;font-size: 12px;">';
        for (var a = 0; a <= Math.round(days); a++) {
            if (a === 0) {

                TableObject += '<th class="headcol" style="position: -webkit-sticky; position: sticky; left: 0; z-index: 1; background-color: #f0f3f8;">LH</th>';
            }
            else {
                var TempCondiotin = false;
                //Put Try Becouse it Create Some Exception Where It HAs No Data
                try {
                    if (JsonObject !== null && JsonObject[a - 1].LH !== undefined) {
                        TempCondiotin = true;
                    }
                }
                catch (error) {
                    TempCondiotin = false;
                }
                //To Show Data On LOad
                if (TempCondiotin) {

                    TableObject += '<td class="long" id="LH_pdf' + a + '" colspan="2">' + JsonObject[a - 1].LH + '</td>';
                }
                // To Continue Without Data
                else {

                    TableObject += '<td class="long" id="LH_pdf' + a + '" colspan="2"></td>';
                }
            }
        }
        //---------------------------------------------------------------P4 Row------------------------------------------------

        TableObject += '</tr><tr style="height: 15px; overflow:hidden;font-size: 12px;">';
        for (var a = 0; a <= Math.round(days); a++) {
            if (a === 0) {
                TableObject += '<th class="headcol" style="position: -webkit-sticky; position: sticky; left: 0; z-index: 1; background-color: #f0f3f8;">P4</th>';
            }
            else {
                var TempCondiotin = false;
                //Put Try Becouse it Create Some Exception Where It HAs No Data
                try {
                    if (JsonObject !== null && JsonObject[a - 1].p4 !== undefined) {
                        TempCondiotin = true;
                    }
                }
                catch (error) {
                    TempCondiotin = false;
                }
                //To Show Data On LOad
                if (TempCondiotin) {
                    TableObject += '<td class="long" id="P4_pdf' + a + '" colspan="2">' + JsonObject[a - 1].p4 + '</td>';
                }
                // To Continue Without Date
                else {
                    TableObject += '<td class="long" id="P4_pdf' + a + '" colspan="2"></td>';
                }
            }
        }
        //---------------------------------------------------------------FSH Row------------------------------------------------

        TableObject += '</tr><tr style="height: 15px; overflow:hidden;font-size: 12px;">';
        for (var a = 0; a <= Math.round(days); a++) {
            if (a === 0) {
                TableObject += '<th class="headcol" style="position: -webkit-sticky; position: sticky; left: 0; z-index: 1; background-color: #f0f3f8;">FSH</th>';
            }
            else {
                var TempCondiotin = false;
                //Put Try Becouse it Create Some Exception Where It HAs No Data
                try {
                    if (JsonObject !== null && JsonObject[a - 1].FSH !== undefined) {
                        TempCondiotin = true;
                    }
                }
                catch (error) {
                    TempCondiotin = false;
                }
                //To Show Data On LOad
                if (TempCondiotin) {
                    TableObject += '<td class="long" id="FSH_pdf' + a + '" colspan="2">' + JsonObject[a - 1].FSH + '</td>';
                }
                // To Continue Without Date
                else {
                    TableObject += '<td class="long" id="FSH_pdf' + a + '" colspan="2"></td>';
                }
            }
        }
        //---------------------------------------------------------------PROL Row------------------------------------------------

        TableObject += '</tr><tr style="height: 15px; overflow:hidden;font-size: 12px;">';
        for ( a = 0; a <= Math.round(days); a++) {
            if (a === 0) {
                TableObject += '<th class="headcol" style="position: -webkit-sticky; position: sticky; left: 0; z-index: 1; background-color: #f0f3f8;">Prol</th>';
            }
            else {
                 TempCondiotin = false;
                //Put Try Becouse it Create Some Exception Where It HAs No Data
                try {
                    if (JsonObject !== null && JsonObject[a - 1].PROL !== undefined) {
                        TempCondiotin = true;
                    }
                }
                catch (error) {
                    TempCondiotin = false;
                }
                //To Show Data On LOad
                if (TempCondiotin) {
                    TableObject += '<td class="long" id="PROL_pdf' + a + '" colspan="2">' + JsonObject[a - 1].PROL + '</td>';
                }
                // To Continue Without Date
                else {
                    TableObject += '<td class="long" id="PROL_pdf' + a + '" colspan="2"></td>';
                }
            }
        }
        //---------------------------------------------------------------Row Seperator One Before Drugs ------------------------------------------------
        if (Math.round(days) > 0) {
            TableObject += '</tr><tr style="height: 15px; overflow:hidden;font-size: 12px;background-color:antiquewhite !important;text-align: center !important;">';
            /**Td Start Collapes */
            TableObject += '<th class="headcol seperator" style="position: -webkit-sticky; position: sticky; left: 0; z-index: 1;">Drugs</th><td class="seperator" colspan="' + (Math.round(days)) + '"></td><td class="seperator" colspan="' + (Math.round(days)) + '"></td>';
        }
        /**Td end Collapes */
        TableObject += "</tr>";

        //----------------------------------------------------------------*Drugs Row Started*-----------------------------------
        try {
            for (var Second = 0; Second < JsonObject[0].DrugsValue.length; Second++) {
                TableObject += '<tr style="height: 15px; overflow:hidden;font-size: 12px;">';
                var for_drug_loop = new Date(_start);
                //convert local date to utc date..
                var yyyy = for_drug_loop.getFullYear().toString()
                var mm = for_drug_loop.getMonth() + 1;
                // if (mm < 10) { mm = "0" + mm; }
                var dd = for_drug_loop.getDate();
                // if (dd < 10) { dd = "0" + dd; }
                for_drug_loop = new Date(yyyy + "-" + mm + "-" + dd + "T00:00:00Z");
                // To Set MM Values In Table
                for (var third = 0; third <= Math.round(days); third++) {
                    if (third === 0) {
                        TableObject += '<th class="headcol" style="position: -webkit-sticky; position: sticky; left: 0; z-index: 1; background-color: #f0f3f8;">' + JsonObject[0].DrugsValue[Second].DrugName + '</th>';
                    }
                    else {

                        var TempCondiotin = false;
                        try {
                            if (JsonObject !== null && JsonObject[third - 1].DrugsValue[Second] !== undefined && JsonObject[third - 1].DrugsValue[Second].DrugsVAlues !== "") {
                                TempCondiotin = true;
                            }
                        }
                        catch (error) {
                            TempCondiotin = false;
                        }
                        var NameOfDrugForID = ((JsonObject[0].DrugsValue[Second].DrugName).replace(/[{()}]/g, '')).split(' ').join('');
                        //To Show Data On LOad
                        if (TempCondiotin) {
                            TableObject += '<td class="long" colspan="2" id=' + NameOfDrugForID + '_pdf' + Second + third + '>' + JsonObject[third - 1].DrugsValue[Second].DrugsVAlues + '</td>';
                        }
                        // To Continue Without Date
                        else {
                            TableObject += '<td class="long" colspan="2" id=' + NameOfDrugForID + '_pdf' + Second + third + '></td>';
                        }
                    }
                }
                TableObject += "</tr>";
            }
        }
        catch (err) {
            //Continue
        }
        TableObject += "</tr>";
        TableObject += "</table>"
        //----------------------------------------------------------------*End Of Table*-----------------------------------------
        //$("#IdToBeAppent").html(TableObject);
        if (TableObject) {
            $("#IdToBeAppent").html(TableObject);
        }
        // let TableData = document.getElementById("IdToBeAppent")
        // TableData.HTML = TableObject;
        //TableData.innerHTML = TableObject
        //TableData = TableObject
        //return TableObject;
    };

    const formatDate = (date) => {
        return String(date.getDate()).slice(-2) + monthNames[date.getMonth()] + String(date.getFullYear()).slice(-2);
    }

    const DrawBubbleChart = async (DataSet) => {
        var max_values = 0;
        var BubbleChartDataArray = [];
        var LH_forLineChart = [];
        var E2_forLineChart = [];
        var P4_forLineChart = [];
        var FSH_forLineChart = [];
        var PROL_forLineChart = [];

        DataSet.map((Out_Value, key) => {
            max_values++
            var LH_singleData = {
                x: max_values,
                y: Out_Value.LH !== "" ? parseFloat(parseFloat(Out_Value.LH).toFixed(2)) : null
            };
            LH_forLineChart.push(LH_singleData);

            var E2_singleData = {
                x: max_values,
                y: Out_Value.E2 !== "" ? parseFloat(parseFloat(Out_Value.E2).toFixed(2)) / 1000 : null
            };
            E2_forLineChart.push(E2_singleData);

            var P4_singleData = {
                x: max_values,
                y: Out_Value.p4 !== "" ? parseFloat(parseFloat(Out_Value.p4).toFixed(2)) : null
            };
            P4_forLineChart.push(P4_singleData);

            var FSH_singleData = {
                x: max_values,
                y: Out_Value.FSH !== "" ? parseFloat(parseFloat(Out_Value.FSH).toFixed(2)) : null
            };
            FSH_forLineChart.push(FSH_singleData);

            var PROL_singleData = {
                x: max_values,
                y: Out_Value.PROL !== "" ? parseFloat(parseFloat(Out_Value.PROL).toFixed(2)) : null
            };
            PROL_forLineChart.push(PROL_singleData);
            // 16-06-2020
            var Mm_Size = 26;

            Out_Value.Values.map((value_Cols, key1) => {

                if (Mm_Size === 9) {
                    Mm_Size = 6;
                }
                if (key1 === 19) {
                    //Skip 
                }
                else {
                    if (value_Cols.Col_R !== "") {
                        var dtaatopush = {
                            x: max_values,
                            y: Mm_Size,
                            z: parseFloat(parseFloat(value_Cols.Col_R).toFixed(2)),
                            name: Out_Value.date + ' R',
                            color: "#f75959"
                        }
                        BubbleChartDataArray.push(dtaatopush);
                    }
                    if (value_Cols.Col_l !== "") {
                        var dtaatopush2 = {
                            x: max_values,
                            y: Mm_Size,
                            z: parseFloat(parseFloat(value_Cols.Col_l).toFixed(2)),
                            name: Out_Value.date + ' L',
                            color: "#85c9e9"
                        }
                        BubbleChartDataArray.push(dtaatopush2);
                    }
                    Mm_Size--;
                }
            })
        })

        var chart = new CanvasJS.Chart("chartContainer", {
            animationEnabled: true,
            zoomEnabled: true,
            width: 1366,
            height: 370,
            title: {
                text: "LH, P4, E2",
                fontSize: 20
            },
            axisX: {
                title: "X axis is days",
                //valueFormatString: "DD",
                labelFontSize: 12,
                titleFontSize: 20,
            },
            axisY: {
                title: "Y axis size of follicles in MM.",
                labelFontSize: 12,
                titleFontSize: 20,
                includeZero: false,
            },
            toolTip: {
                shared: true
            },
            data: [{
                name: "LH",
                connectNullData: true,
                type: "spline",
                yValueFormatString: "#0.##",
                //  showInLegend: true,                
                dataPoints: LH_forLineChart
            },
            {
                name: "P4",
                connectNullData: true,
                type: "spline",
                yValueFormatString: "#0.##",
                //  showInLegend: true,
                dataPoints: P4_forLineChart
            },
            {
                name: "E2",
                connectNullData: true,
                type: "spline",
                yValueFormatString: "#0.##",
                // showInLegend: true,
                dataPoints: E2_forLineChart
            },
            {
                name: "FSH",
                connectNullData: true,
                type: "spline",
                yValueFormatString: "#0.##",
                //  showInLegend: true,                
                dataPoints: FSH_forLineChart
            },
            {
                name: "PROL",
                connectNullData: true,
                type: "spline",
                yValueFormatString: "#0.##",
                //  showInLegend: true,                
                dataPoints: PROL_forLineChart
            }]
        });
        chart.render();

        var Cahrt1 = new CanvasJS.Chart("chartContainer12", {
            animationEnabled: true,
            zoomEnabled: true,
            width: 1366,
            height: 670,
            title: {
                text: "Follicle Size Number",
                fontSize: 20
            },
            axisX: {
                title: "X axis is days",
                titleFontSize: 20,
                labelFontSize: 12,
                maximum: max_values,
            },
            axisY: {
                title: "Y axis size of follicles in MM.",
                labelFontSize: 12,
                titleFontSize: 20
            },
            legend: {
                horizontalAlign: "left",
                fontSize: 15
            },
            data: [{
                type: "bubble",
                showInLegend: true,
                legendText: "The Bubble show the number of follicles",
                legendMarkerType: "circle",
                legendMarkerColor: "grey",
                fillOpacity: .3,
                toolTipContent: "<b>{name}</b><br/>Day of Tx: {x}<br/> MM : {y}<br/> # of Follicles: {z}",
                dataPoints: BubbleChartDataArray
            }]
        });
        Cahrt1.render();
    }

    const handleClose = () => setShow(false);
    const handleShow = (data) => {
        setData('')
        setShow(true)
        setData(data)
    };
    return (
        <>
            <Card className=" border-0 ">
                <Card.Header className="  contactusfont text-dark ">
                    <div className="row">
                        <div className="col-6">
                            <h5>Treatment With Us </h5>
                        </div>
                        <div className="col-6 text-right">
                            ( {UserData?.firstName} {UserData?.lastName} , {moment(UserData?.birthday).format("DD-MMM-YYYY")} ({UserData?.age}) ,{UserData?.gendercodeName} )
                            &nbsp;    <a type="submit" onClick={() => navigate(-1)}>
                                <i style={{ fontSize: "18px" }} class="fa-solid fa-circle-arrow-left"></i>
                            </a>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Card.Text>
                        <div>
                            <div className='row'>
                                <div className="col-md-1"></div>
                                <div className='col-md-10'>
                                    <div className="card">
                                        <div className='card-header pb-2'><h5 className=' d-flex justify-content-center mt-1 mb-1'>Treatment Details</h5></div>
                                        <div className='card-body'>
                                            <div className='row mb-2 p-2'>
                                                <h5 className='mt-1 mb-1'>Treatment Outcome</h5>
                                                <div className='col-md-4'>
                                                    <div className='row'>
                                                        <div className='col-md-5'>
                                                            <label >Result of pregnancy test</label>
                                                        </div>
                                                        <div className='col-md-7'>
                                                            <select class="form-select " id="floatingSelect" aria-label="Floating label select example" name="patientGroups"
                                                                onChange={(e) => showtreatmentCard(e.target.value)}
                                                                defaultValue={TreatmentDataDetails?.bcrm_result_of_pregnancy_test}>
                                                                <option value="null">--Select--</option>
                                                                <option value="2" selected={TreatmentDataDetails?.bcrm_result_of_pregnancy_test === 2}>Negative</option>
                                                                <option value="1" selected={TreatmentDataDetails?.bcrm_result_of_pregnancy_test === 1}>Positive</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-4 '>
                                                    <div className='row'>
                                                        <div className='row'>
                                                            <div className='col-md-5'>
                                                                <label>Date of pregnancy test</label>
                                                            </div>
                                                            <div className='col-md-7'>
                                                                <input type="date" className="form-control" name="bcrm_date_time_of_early_outcome" defaultValue={TreatmentDataDetails?.bcrm_date_time_of_early_outcome} onChange={(e) => setDateofPreg(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-4 '>
                                                    <div className='row'>
                                                        <div className='col-md-5'>
                                                            <Button className='btn btn-primary updatetop ' onClick={() => updateTreatmentOutcome()}>UPDATE</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <>
                                                <div className="row p-2 mb-1">
                                                    {
                                                        resultofPreg === 1 &&
                                                        <>
                                                            <h5 className='pb-2'>Pregnancy Outcome Details</h5>
                                                            <div className='col-md-2'>
                                                                <label className="form-check-label" for="inlineRadio1">Live Birth :</label>
                                                                <input class="form-check-input ml-3" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" onClick={() => setSubDiv(!subdiv)} />

                                                            </div>
                                                            <div className='col-md-3'>
                                                            </div>
                                                            <div className='col-md-2'>
                                                                <label class="form-check-label" for="inlineRadio2">Neonatal Death :
                                                                <input className="form-check-input ml-3" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" onClick={() => setSubDiv(false)} />

                                                                </label>

                                                            </div>
                                                            <div className='col-md-3'>

                                                            </div>
                                                        </>
                                                    }
                                                    {
                                                        subdiv ? <>
                                                            <div className='col-md-12 mt-1'>
                                                                <div>
                                                                    <button className='btn btn-primary pb-1' type='button' onClick={handleShow}>Add</button>
                                                                </div>
                                                                <div className="table-responsive pt-1">
                                                                    <table class="table border-0" id="PrevTreatmentCycleTable">
                                                                        <thead class="">
                                                                            <tr>
                                                                                <th> Dilevery Date</th>
                                                                                <th>First Name </th>
                                                                                <th>Last Name</th>
                                                                                <th>Gender</th>
                                                                                <th> NHS Number</th>
                                                                                <th>Gestation </th>
                                                                                <th>Edit/Delete</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {livebirth && livebirth.map((listValue, index) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{moment(listValue.delivery_date).format("DD-MMM-YYYY")}</td>
                                                                                        <td>{listValue.forenames}</td>
                                                                                        <td>{listValue.surname}</td>
                                                                                        <td>{listValue.gender}</td>
                                                                                        <td>{listValue.nhs_number}</td>
                                                                                        <td>{listValue.gestation}</td>
                                                                                        <td>
                                                                                            <i className="fa-solid fa-trash" onClick={() => deleteData(listValue.id)}></i><span>{" "}{" "}</span><span>{" "}{" "}</span>
                                                                                            <i className="fa-solid fa-pencil" disabled={listValue.envelopeid === ""} onClick={() => handleShow(listValue)}></i>
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </>
                                                            : null
                                                    }
                                                </div>
                                                <div className='row p-2 bg-light p-2 m-2 border rounded border-border-dark'>
                                                    <div className='row'>
                                                        <div className='col-md-4 '>
                                                            <label>Referring Centre : </label><label> {TreatmentDataDetails?.referringCentre}</label>
                                                            {/* {TreatmentDataDetails?.referringCentre} */}
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <label>First Injection Date : </label>
                                                            <label> {moment(TreatmentDataDetails?.firstInjectionDate).format('DD-MMM-yyyy')}</label>

                                                        </div>
                                                        <div className='col-md-4 '>
                                                            <label>TypeofCycle :  </label><label> {TreatmentDataDetails?.typeofCycle}</label>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            <label>Treatment Details Sec Diagnosis : </label><label> {TreatmentDataDetails?.secDiagnosis}</label>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <label>Treatment Details Diagnosis : </label><label> {TreatmentDataDetails?.treatmentDetailDiagnosis}</label>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <label>Dose:</label><label>{TreatmentDataDetails?.dose}</label>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            <label>TimeOfTrigger : </label><label>{TreatmentDataDetails?.timeOfTrigger}</label>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <label>treatmentType : </label><label>{TreatmentDataDetails?.treatmentType}</label>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <label>Treatment Feature : </label><label>{TreatmentDataDetails?.treatmentFeature}</label>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            <label>Treatment Details Other Diagnosis  : </label><label>{TreatmentDataDetails?.treatmentDetailsOtherDiagnosis}</label>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <label>Left Ovary : </label><label>{TreatmentDataDetails?.leftOvary}</label>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <label>Right Ovary : </label><label>{TreatmentDataDetails?.rightOvary}</label>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            <label>Stimulation Location  : </label><label>{TreatmentDataDetails?.stimulationLocation}</label>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <label>LH : </label><label>{TreatmentDataDetails?.lh}</label>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <label>Egg Collection : </label><label>{TreatmentDataDetails?.eggCollection}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-1"></div>
                            </div>
                            {/* Edit Pregnancy Outcome Details data on edit data       */}
                            <Modal size="lg" show={show} onHide={handleClose} >
                                <Modal.Header closeButton>
                                    <Modal.Title>Pregnancy Outcome Details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body >
                                    <div className='row mb-2'>
                                        <div className='col-md-6'>
                                            <div className='row'>
                                                <div className='col-md-5'>
                                                    <label className=' col-form-label'>First Name <span className='text-danger'>*</span> </label>
                                                </div>
                                                <div className='col-md-7'>
                                                    <input type='text' className="form-control" value={data?.forenames}
                                                        placeholder="Enter First Name " name="forenames" onChange={(e) => updateModalData(e)} onKeyDown={(e) => checkSpecial(e)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='row'>
                                                <div className='col-md-5'>
                                                    <label className=' col-form-label ' >Last Name <span className='text-danger'>*</span> </label>

                                                </div>
                                                <div className='col-md-7'>
                                                    <input className='form-control m-1 border-1' value={data?.surname} placeholder='Enter Last Name'
                                                        name="surname" onChange={(e) => updateModalData(e)} onKeyDown={(e) => checkSpecial(e)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-md-6'>
                                            <div className='row'>
                                                <div className='col-md-5'>
                                                    <label className=' col-form-label'>Gender <span className='text-danger'>*</span> </label>
                                                </div>
                                                <div className='col-md-7'>
                                                    <select class="form-select " id="floatingSelect" aria-label="Floating label select example" name='gender' value={data?.gender}
                                                        onChange={(e) => updateModalData(e)} >
                                                        <option value="">Select </option>
                                                        <option value="1">Male</option>
                                                        <option value="2">Female</option>
                                                        <option value="3">Other</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='row'>
                                                <div className='col-md-5'>
                                                    <label className=' col-form-label '>Weight in grams<span className='text-danger'>*</span> </label>

                                                </div>
                                                <div className='col-md-7'>
                                                    <input className='form-control m-1  border-1' value={data?.weight_in_grams} onKeyDown={(e) => checkSpecial(e)}
                                                        placeholder='Enter Weight in grams' name="weight_in_grams" onChange={(e) => updateModalData(e)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-md-6'>
                                            <div className='row'>
                                                <div className='col-md-5'>
                                                    <label className=' col-form-label'>Delivery Date <span className='text-danger'>*</span> </label>
                                                </div>
                                                <div className='col-md-7'>
                                                    <input type="date" className='form-control m-1  border-1' value={data?.delivery_date}
                                                        placeholder='Enter Delivery Date' name="delivery_date" onChange={(e) => updateModalData(e)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='row'>
                                                <div className='col-md-5'>
                                                    <label className=' col-form-label '>Gestation in weeks<span className='text-danger'>*</span> </label>
                                                </div>
                                                <div className='col-md-7'>
                                                    <input type="number" className='form-control m-1  border-1' value={data?.gestation} placeholder='Enter Gestation in weeks'
                                                        name="gestation" onChange={(e) => updateModalData(e)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-md-6'>
                                            <div className='row'>
                                                <div className='col-md-5'>
                                                    <label className=' col-form-label'>NHS number <span className='text-danger'>*</span> </label>
                                                </div>
                                                <div className='col-md-7'>
                                                    <input type="number" className='form-control m-1  border-1' value={data?.nhs_number}
                                                        placeholder='Enter NHS number' name="nhs_number" onChange={(e) => updateModalData(e)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='row'>
                                                <div className='col-md-5'>
                                                    <label className=' col-form-label '>Country of Birth<span className='text-danger'>*</span> </label>
                                                </div>
                                                <div className='col-md-7'>
                                                    <select className='form-control form-select' name='country_of_birth'
                                                        onChange={(e) => handleCountry(e)} value={data?.country_of_birth} >
                                                        <option value="">--Select--</option>
                                                        <Select onChange={(e) => handleCountry(e)} />
                                                        {countryBirth ? (countryBirth?.map((country, index) => {
                                                            return (
                                                                <option value={country?.id}>{country?.friendlyName}</option>
                                                            );
                                                        })
                                                        ) : null
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-md-6'>
                                            <div className='row'>
                                                <div className='col-md-5'>
                                                    <label className=' col-form-label'>Town or District of Birth <span className='text-danger'>*</span> </label>
                                                </div>
                                                <div className='col-md-7'>
                                                    <input className='form-control m-1  border-1' value={data?.town_or_district_of_birth} placeholder='Enter Town or District of Birth'
                                                        name="town_or_district_of_birth" onChange={(e) => updateModalData(e)} onKeyDown={(e) => checkSpecial(e)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='row'>
                                                <div className='col-md-5'>
                                                    <label className=' col-form-label'> Town or District<span className='text-danger'>*</span> </label>
                                                </div>
                                                <div className='col-md-7'>
                                                    <input className='form-control m-1  border-1' value={data?.town_or_district} placeholder='Enter Town or District'
                                                        name="town_or_district"
                                                        onKeyDown={(e) => checkSpecial(e)}
                                                        onChange={(e) => updateModalData(e)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    {data?.id ? <Button type="button" className=' btn btn-primary me-2' onClick={() => Updatepregnancyoutcomedetails()} >Update</Button>
                                        : <Button type="button" className=' btn btn-primary me-2' onClick={() => AddPregnancyOutcomeDetails()} >Add</Button>}
                                    <Button variant="secondary" className='btn btn-primary' onClick={handleClose}>Close</Button>
                                </Modal.Footer>
                            </Modal>
                            <br></br>
                            <div className="col-md-11 px-md-0 table-responsive text-center" id="IdToBeAppent"></div>
                            <br></br>
                            <div className="col-md-12  table-responsive" id="chartContainer"></div>
                            <br></br>
                            <div className="col-md-12 table-responsive" id="chartContainer12" ></div>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>
        </>
    )
}
