import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router";
import moment from "moment";
import LoginFun from './../../Layout Component/Loginfun';
import Swal from "sweetalert2";
import './TreatmentWithUs.css';
import { useDispatch } from "react-redux";
import { setLoader } from "../../Store/Reducers/UserReducer"
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import MedicalHistoryNav from "./MedicalHistoryNav";
import $ from "jquery";

export default function TreatmentWithUs({ props }) {
  const dispatch = useDispatch();
  const url = process.env.REACT_APP_OXAR_API_URL;
  const logindata = LoginFun();
  const [TreatmentCycle, setTreatmentCycle] = useState([]);
  const navigate = useNavigate();
  
  const tocomponentTreatmentOutcome = (id) => {
    var Tid = id
    localStorage.setItem("Tretmentid", Tid);
    navigate("/TreatmentOutcome", { state: { Tid } });
  };
 

  useEffect(() => {
    dispatch(setLoader(true));
    getTreatmentCycle();
    var currentTime = ''
    currentTime = (Math.round(new Date().getTime() / 1000));
    var Expiretime = localStorage.getItem("ExpirretokenTime");
    if (Expiretime < currentTime) {
      sessionStorage.getItem('isLoggedIn', false)
      Swal.fire({
        title: 'Session Expired',
        text: "Your session has been expired! Please login again",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Okay',
      }).then(result => {
        logindata.logout();
      })
    }
  }, []);

  const getTreatmentCycle = async () => {
    try {
      dispatch(setLoader(true));
      let MainUserId = localStorage.getItem("UserID");
      await axios(
        `${url}api/Treatment/GetTreatmentCyclesByContactId?id=${MainUserId}`
      ).then((res) => {
        if (res.data.content.treatmentcycleDTO) {
          var ActiveTreatment = res['data']['content']['bcrm_activetreatmentcycle'];
          if (res.data.content.treatmentcycleDTO != null) {
            for (var i = 0; i < res['data']['content']['treatmentcycleDTO'].length; i++) {
              if (res.data.content.treatmentcycleDTO[i].name === ActiveTreatment) {
                res.data.content.treatmentcycleDTO[i].isCompair = true;
              }
            }
          }
          setTreatmentCycle(res.data.content.treatmentcycleDTO);
          $("#treatmentCycleTable").DataTable();
        } else {
          setTreatmentCycle([])
        }
      });
      dispatch(setLoader(false));
    } catch {

    }
  };
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <MedicalHistoryNav />
      <>
        <div className="col-md-12">
          <Card className="border-0">
            <Card.Body>
              <div className="col-md-12 text-left ">
                {/* <h3>Semen Analysis </h3> */}
                <div className="table-responsive">
                  <table class="table border-0" id="treatmentCycleTable">
                    <thead >
                      <tr>
                        <th>Name</th>
                        <th>Created On</th>
                        <th>Details </th>
                      </tr>
                    </thead>
                    <tbody>
                      {TreatmentCycle && TreatmentCycle.map((listValue, index) => {
                        return (
                          <tr>
                            <td>{listValue.name}</td>
                            <td> 
                            {moment(listValue.createdOn).format("DD-MMM-yyyy") ? moment(listValue.createdOn).format("DD-MMM-yyyy") : moment(listValue.createdOn).format("DD-MMM-yyyy")}</td>
                            <td>  <a role="button"
                              className="pointer text-primary" type="button"
                              // disabled={row.envelopeid == ""}
                              onClick={() => {
                                tocomponentTreatmentOutcome(listValue.id);
                              }}
                            >
                              Details
                            </a></td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </>
    </div>
  );
}
