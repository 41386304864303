import React, { useEffect } from 'react'
import { Card, Button } from 'react-bootstrap'
import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Modal } from 'react-bootstrap';
import $ from 'jquery';
import moment from "moment";
import LoginFun from './../../Layout Component/Loginfun';
import './MedicalHistory.css';
import './PreviousPregnancy.css';
import { useDispatch } from "react-redux";
import { setLoader } from "../../Store/Reducers/UserReducer"
import MedicalHistoryNav from './MedicalHistoryNav';
import 'react-toastify/dist/ReactToastify.css';
import { Slide,  Flip } from 'react-toastify';
import {  toast } from 'react-toastify';

export default function PreviousPregnancy() {
    const dispatch = useDispatch();
    const url = process.env.REACT_APP_OXAR_API_URL;
    const [prevPregnancy, setprevPregnancy] = useState([]);
    const [updateallergies, setupdateallergies] = useState([]);
    const [show, setShow] = useState(false);
    const [data, setData] = useState();
    const [saveBtn, setsaveBtn] = useState(false);
    const [updateBtn, setupdateBtn] = useState(false);
    const [SendData, setSendData] = useState();
    const handleClose = () => setShow(false);
    const handleShow = (Dataall) => {
        setconceptionName('');
        setData([]);
        setSendData([]);
        setShow(true);
        if (Dataall !== undefined) {
            setupdateallergies(Dataall)
            setSendData(Dataall)
            setData(Dataall)
        }
    };
    const logindata = LoginFun();

    useEffect(() => {
        dispatch(setLoader(true));
        setTimeout(() => {
            getAllprevPregnancy();
        }, 1000);
        sessionCheck();

    }, []);

    const sessionCheck = async () => {
        var currentTime = ''
        currentTime = (Math.round(new Date().getTime() / 1000));
        var Expiretime = localStorage.getItem("ExpirretokenTime");
        if (Expiretime < currentTime) {
            sessionStorage.getItem('isLoggedIn', false)
            Swal.fire({
                title: 'Session Expired',
                text: "Your session has been expired! Please login again",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Okay',
            }).then(result => {
                logindata.logout();
            })
        }
    }

    const getAllprevPregnancy = async () => {
        dispatch(setLoader(true)); 
        try {
            $('#PrevTreatmentCycleTable').DataTable().destroy();
            let MainUserId = localStorage.getItem("UserID");
                await axios(`${url}api/PreviousPregnancy/GetByContactId?id=${MainUserId}`).then((res) => {
                    setprevPregnancy(res.data.content);
                    $("#PrevTreatmentCycleTable").DataTable();
                    dispatch(setLoader(false));
                });
        }
        catch(e) {
            dispatch(setLoader(false));
        }
    };
    const [yearOfPregnancy, setyearOfPregnancy] = useState("");
    const [outcome, setoutcome] = useState("");
    const [wasItWithPartner, setwasItWithPartner] = useState("");
    const [conceptionName, setconceptionName] = useState("");
    const [numberOfWeeks, setnumberOfWeeks] = useState("");
    const [comments, setcomments] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (conceptionName !== "") {

            let postdata = {
                yearOfPregnancy: yearOfPregnancy,
                wasItWithPartner: wasItWithPartner,
                conceptionMethod: conceptionName,
                numberOfWeeks: numberOfWeeks,
                comments: comments,
                outcome: outcome
            }
            dispatch(setLoader(true));
            try {
                debugger
                let MainUserId = localStorage.getItem("UserID");
                const res = await axios.post(`${url}api/PreviousPregnancy/Create?userId=${MainUserId}`, postdata);
                if (res.status === 200) {
                    toast.success('Previous pregnancy created successfully!', {
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        autoClose: 3000,
                        hideProgressBar: true,
                        transition: Slide,
                        className: 'toast-message'
                    }
                    )
                    setyearOfPregnancy("");
                    setwasItWithPartner("");
                    setconceptionName("");
                    setnumberOfWeeks("");
                    setcomments("");
                    setoutcome("");
                    setShow(false);
                    getAllprevPregnancy();
                    dispatch(setLoader(false));

                }
            }
            catch (e) {
                debugger
                if(e.response.data.errorMessage == "Invalid json"){
                    toast.error('Please dont use special characters in fields ', {
                      autoClose: 5000,
                      hideProgressBar: true,
                      newestOnTop: false,
                      closeOnClick: true,
                      rtl: false,
                      pauseOnFocusLoss: true,
                      draggable: true,
                      pauseOnHover: true,
                      theme: "colored"
                    }
                    )
                    dispatch(setLoader(false));
                  return
                  }
                  else{
                    toast.error('Error while updating', {
                      autoClose: 5000,
                      hideProgressBar: true,
                      newestOnTop: false,
                      closeOnClick: true,
                      rtl: false,
                      pauseOnFocusLoss: true,
                      draggable: true,
                      pauseOnHover: true,
                      theme: "colored"
                    }
                    )
                    dispatch(setLoader(false));
                  return
                  }
                  dispatch(setLoader(false));
                  return
            }
        }
        else {
            toast.warn('Please fill mandatory fields', {
                autoClose: 5000,
                hideProgressBar: true,
                newestOnTop: false,
                closeOnClick: true,
                rtl: false,
                pauseOnFocusLoss: true,
                draggable: true,
                pauseOnHover: true,
                className: 'toast-warn'
            }
            )
            dispatch(setLoader(false));
            return
        }
    }

    const updateAllergies = async (updatedvalue) => {
        var name = updatedvalue.target.name;
        var value = updatedvalue.target.value;
        setupdateallergies(values => ({ ...values, [name]: value, 'id': data?.id }));
    }
    const checkSpecial = (e) => {
        if (!/^[a-zA-Z0-9\s]*$/.test(e.key)) {
            e.preventDefault();
        }
    }
    // Update procedure Data API
    const updateAllergiesData = async (e) => {
        debugger
        sessionCheck();
        e.preventDefault();
        dispatch(setLoader(true));
        let MainUserId = localStorage.getItem("UserID");
        try {
            const res = await axios.put(`${url}api/PreviousPregnancy/Update?userId=${MainUserId}`, updateallergies);
            if (res.status === 200) {
                toast.success('Previous pregnancy updated successfully!', {
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    autoClose: 3000,
                    hideProgressBar: true,
                    transition: Slide,
                    className: 'toast-message'
                }
                )
                setyearOfPregnancy("");
                setwasItWithPartner("");
                setconceptionName("");
                setnumberOfWeeks("");
                setcomments("");
                setoutcome("");
                setShow(false);
                getAllprevPregnancy();
                dispatch(setLoader(false));
            }
        }
        catch (e) {
            if(e.response.data.errorMessage == "Invalid json"){
                toast.error('Please dont use special characters in fields ', {
                  autoClose: 5000,
                  hideProgressBar: true,
                  newestOnTop: false,
                  closeOnClick: true,
                  rtl: false,
                  pauseOnFocusLoss: true,
                  draggable: true,
                  pauseOnHover: true,
                  theme: "colored"
                }
                )
                dispatch(setLoader(false));
              return
              }
              else{
                toast.error('Error while updating', {
                  autoClose: 5000,
                  hideProgressBar: true,
                  newestOnTop: false,
                  closeOnClick: true,
                  rtl: false,
                  pauseOnFocusLoss: true,
                  draggable: true,
                  pauseOnHover: true,
                  theme: "colored"
                }
                )
                dispatch(setLoader(false));
              return
              }
              dispatch(setLoader(false));
               return
        }
    }
    const wasItWithPartners = (datas) => {
        setData(values => ({ ...values, "wasItWithPartner": datas }));
        setupdateallergies(values => ({ ...values, "wasItWithPartner": datas, 'id': data?.id }));
    }
    // Delete procedure Data API
    const deleteData = async (item) => {
        try {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Do you want to delete ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch(setLoader(true));
                    axios.delete(`${url}api/PreviousPregnancy/Delete?id=${item}`).then((res => {
                        if (res.status === 200) {
                            getAllprevPregnancy();
                            toast.success('Your record has been deleted', {
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                autoClose: 3000,
                                hideProgressBar: true,
                                transition: Flip,
                                className: 'toast-message'
                            }
                            )
                            dispatch(setLoader(true));
                        }
                        else {
                            toast.error('Error while deleting previous pregnancy', {
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                autoClose: 3000,
                                hideProgressBar: true,
                                transition: Flip,
                                theme: "colored"
                            }
                            )
                        }
                    }))
                }
            })
        }
        catch (e) {
            dispatch(setLoader(false));
        }
    }

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <MedicalHistoryNav />
                {/* step wizard for Medical History */}
                <div className='col-md-12'>
                    <Card className="border-0">
                        <Card.Body>
                            <form>
                                <div className="col text-left bg-white-200  ">
                                    <div className='d-flex mb-2 '>
                                        <div className='col-md-6'>
                                            {/* <label>Search : </label>
                                                                <input class=" ms-2" type="search" placeholder="Search" aria-label="Search" /> */}
                                        </div>
                                        <div className='col-md-6 d-grid gap-2 d-md-flex justify-content-md-end' >
                                            <Button className='btn btn-primary ' onClick={() => handleShow(setsaveBtn(true), setupdateBtn(false))}>Add Previous Pregnancy +</Button>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table class="table border-0" id="PrevTreatmentCycleTable">
                                            <thead class="">
                                                <tr>
                                                    <th>Years Of Pregnancy</th>
                                                    <th>Was it with partner you are seeking treatment? </th>
                                                    <th>Conception Method </th>
                                                    <th>Number of Gestation</th>
                                                    <th>Created On</th>
                                                    <th>Comments </th>
                                                    <th>Delete/Edit</th>
                                                </tr>
                                            </thead>
                                            <tbody>


                                                {prevPregnancy && prevPregnancy.map((listValue, index) => {
                                                    return (
                                                        <tr>
                                                            <td>{listValue.yearOfPregnancy}</td>
                                                            <td>{listValue.wasItWithPartner}
                                                                {listValue.wasItWithPartner === false ? "No" : "Yes"}
                                                            </td>
                                                            <td>{listValue.conceptionName}</td>
                                                            <td>{listValue.numberOfWeeks}</td>
                                                            <td>
                                                                {moment(listValue.createdon).format("DD-MMM-yyyy") ? moment(listValue.createdon).format("DD-MMM-yyyy") : moment(listValue.createdon).format("DD-MMM-yyyy")}</td>
                                                            <td>{listValue.comments}</td>
                                                            <td>
                                                                <i className="fa-solid fa-trash" onClick={() => deleteData(listValue.id)}></i><span>{" "}{" "}</span><span>{" "}{" "}</span>
                                                                <i className="fa-solid fa-pencil" disabled={listValue.envelopeid === ""} onClick={() => handleShow(listValue, setupdateBtn(true), setsaveBtn(false))}></i>
                                                            </td>

                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* <BootstrapTable
                                            bootstrap4
                                            wrapperClasses="table-responsive"
                                            keyField='id'
                                            pagination={pagination}
                                            rowEvents={tableRowEvents}
                                            filter={filterFactory()}
                                            data={prevPregnancy}
                                            columns={columns}
                                            hover
                                        /> */}
                                </div>
                                <Modal size="lg" show={show} onHide={handleClose} >
                                    <form>
                                        <Modal.Header closeButton>
                                            {saveBtn ?
                                                <h6> Add Previous Pregnancy</h6>
                                                :
                                                <h6> Edit Previous Pregnancy</h6>
                                            }
                                        </Modal.Header>
                                        <Modal.Body >
                                            <div className='ms-3 row'>
                                                <label className='col-sm-4 col-form-label ' >Conception Method  <span className="required"></span> : </label>
                                                <div className="col-sm-7 ">
                                                    <select className="form-select  m-1  border-1" id="floatingSelect" aria-label="Floating label select example"
                                                        name="conceptionMethod" defaultValue={SendData?.conceptionMethod} onChange={(e) => updateAllergies(e, setconceptionName(e.target.value))} >
                                                        <option value="0">--Select--</option>
                                                        <option value="1" selected={SendData?.conceptionMethod === 1}>Natural</option>
                                                        <option value="2" selected={SendData?.conceptionMethod === 2}>Assisted Conception</option>
                                                    </select>
                                                </div>
                                                <label className='col-sm-4 col-form-label ' >Year of Pregnancy : </label>
                                                <div className="col-sm-7">
                                                    <input type="number" className='form-control m-1   border-1'
                                                        name="yearOfPregnancy" defaultValue={data?.yearOfPregnancy}
                                                        onChange={(e) => updateAllergies(e, setyearOfPregnancy(e.target.value))} />
                                                </div>

                                                <label className='col-sm-4 col-form-label'>Number of weeks gestation : </label>
                                                <div className="col-sm-7">
                                                    <input className='form-control m-1   border-1' defaultValue={data?.numberOfWeeks} placeholder='Number of weeks gestation'
                                                        name="numberOfWeeks" onKeyDown={(e) => checkSpecial(e)}
                                                        onChange={(e) => updateAllergies(e, setnumberOfWeeks(e.target.value))} />
                                                </div>
                                                <label className='col-sm-4 col-form-label ' >Comments: </label>
                                                <div className="col-sm-7">
                                                    <textarea className='form-control m-1  border-1' name="comments" defaultValue={data?.comments}
                                                        onKeyDown={(e) => checkSpecial(e)}
                                                        onChange={(e) => updateAllergies(e, setcomments(e.target.value))} />
                                                </div>
                                                <label className='col-sm-4 col-form-label ' >Outcome : </label>
                                                <div className="col-sm-7 ">
                                                    <select className="form-select  m-1  border-1" id="floatingSelect" aria-label="Floating label select example" name="outcome"
                                                        defaultValue={SendData?.outcome} onChange={(e) => updateAllergies(e, setoutcome(e.target.value))} >
                                                        <option value="0">--Select--</option>
                                                        <option value="1" selected={SendData?.outcome === 1}>Live Birth</option>
                                                        <option value="2" selected={SendData?.outcome === 2}>Ectopic</option>
                                                        <option value="3" selected={SendData?.outcome === 3}>Miscarraige</option>
                                                        <option value="4" selected={SendData?.outcome === 4}>Still Birth</option>
                                                        <option value="5" selected={SendData?.outcome === 5}>Neonatal Death</option>
                                                    </select>
                                                </div>

                                                <label className='col-sm-4 col-form-label ' >Was it with partner you are seeking treatment? : </label>
                                                <div className="col-sm-7">
                                                    <div className=" form-check form-switch p-0 mt-1">
                                                        <label class="switch">
                                                            <input type="checkbox" name='wasItWithPartner'
                                                                defaultChecked={data?.wasItWithPartner === true}
                                                                onChange={(e) => {
                                                                    wasItWithPartners(e.target.checked,
                                                                        setwasItWithPartner(e.target.checked))
                                                                }} />
                                                            <div class="slider round">
                                                                <span className="on">Yes</span>
                                                                <span className="off">No</span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            {
                                                updateBtn ?
                                                    <Button type="button" className='btn btn-primary me-2' onClick={updateAllergiesData} >Update</Button>
                                                    : null
                                            }
                                            {saveBtn ?
                                                <Button variant="primary" type="button" onClick={handleSubmit} className='btn btn-primary me-2'  >Save</Button>
                                                : null
                                            }
                                            <Button variant="secondary" className='btn btn-primary' onClick={handleClose}>Close</Button>
                                        </Modal.Footer>
                                    </form>
                                </Modal>
                            </form>

                        </Card.Body>
                    </Card>
                </div>

            </div>
            {/* <ToastContainer /> */}
        </>
    )
}
