import React from "react";
import "./LoadingSpinner.css";

export default function LoadingSpinner() {
  return (
    // <div className="spinner-container">
    //   <div className="loading">
    //   </div>
    // </div>
    
    <div className="spinner">
    <div class="ball ball-1"></div>
    <div class="ball ball-2"></div>
    <div class="ball ball-3"></div>
    <div class="ball ball-4"></div>
    
  </div>
  );
}