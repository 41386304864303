import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Button } from "react-bootstrap";
import { getProfileData } from "../services/ProfileService";
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from "react-redux";
import { getUser, setLoader, setUser } from "../Store/Reducers/UserReducer";
import './PinCode.css'
import $ from "jquery";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function LoginMFA(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [shownumber, setshownumber] = useState(false);
    const [showmail, setshowmail] = useState(false);
    const [ProfileData, setProfileData] = useState();
    const [number, setnumber] = useState();
    const [EmailPatient, setEmailPatient] = useState();
    const [Mobilepatient, setMobilepatient] = useState();

    const uData = useSelector(getUser);
    const toggleinput = (value) => {
        
        if (value == '2') {
            setshownumber(true);
            setshowmail(false);
        }
        else {
            setshownumber(false);
            setshowmail(true);
        }
    }
    useEffect(() => {
        
       // dispatch(setLoader(true));
        localStorage.setItem("mfalogin", true);
        let Mobilepatient = localStorage.getItem("PatientMobileformfa");
        if(Mobilepatient != ""){
            setshownumber(true);
            setshowmail(false);
        }
        else{
            setshownumber(false);
            setshowmail(true);
        }
        // For Mobile
        if(Mobilepatient != null){
            var Mobilepatient2 = Mobilepatient.slice(0,2) + Mobilepatient.slice(2).replace(/.(?=...)/g, '*');
            setMobilepatient(Mobilepatient2);
        }
        
        // For email
        let EmailPatient = localStorage.getItem("PatientEmailformfa");
        if(EmailPatient != null){
            var EmailPatient2  = hideEmail(EmailPatient)
            setEmailPatient(EmailPatient2);
        }
     

        profileDetail();
        setnumber(ProfileData?.mobileNumber);
        
    }, [uData]);
    useLayoutEffect(() => {
       
    })
    const navigatePin = () => {
        
        
        var val = $("#ChooseDilveryMethod").val();
        localStorage.setItem("LoginMethod", val);
        if(val == "2" && Mobilepatient == ""){
           dispatch(setLoader(false));
          toast.error('Mobile Number not exist please choose another method', {
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    autoClose: 3000,
                    hideProgressBar: true,
                    theme: "colored"
                }
                )
          return
        }

        else if(val == "1" && EmailPatient == ""){
            dispatch(setLoader(false));
           toast.error('Email not exist please choose another method', {
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     autoClose: 3000,
                     hideProgressBar: true,
                     theme: "colored"
                 }
                 )
           return
         }
        props.SetNumOrEmail(JSON.parse(val));
        Math.floor(1000 + Math.random() * 9000);
        navigate("/PinCode");
    }

    const profileDetail = async () => {
        
        try {
            let MainUserId = localStorage.getItem("UserID");
            const res = await getProfileData(MainUserId);
            res.content.email = hideEmail(res.content.email);
            res.content.mobileNumber = res.content.mobileNumber.slice(0, 2) + res.content.mobileNumber.slice(2).replace(/.(?=...)/g, '*');
            setProfileData(res.content);
        } catch (e) {
        }
        setTimeout(() => {
            dispatch(setLoader(false));
        }, 1000);

    }
    let hideEmail = function (email) {
        
        return email.replace(/(.{2})(.*)(?=@)/,
            function (gp1, gp2, gp3) {
                for (let i = 0; i < gp3.length; i++) {
                    gp2 += "*";
                } return gp2;
            });
    };

    return (
       <div className='container  mt-5'>
            <div className='row m-0'>
                <div className='col-md-1'></div>
                <div className='col-md-10'>
                    <Card style={{ marginTop: 50, marginBottom: 50 }}>
                        <Card.Header>
                            <h4><b>Help Us Verify Your Identity</b></h4>
                        </Card.Header>
                        <p className='px-2 mt-3 '>For the protection of your account, we want to send you a One-Time Identification Code and make sure it's really you.
                        </p>
                        <div className='col-md-5 px-3'>
                            <label> Choose a delivery method <span className="required"></span> </label>
                        </div>
                        <div className='col-md-7 px-3'>
                            <select className="form-select " id="ChooseDilveryMethod" name="ChooseDilveryMethod"
                                onChange={(e) => toggleinput(e.target.value)} >
                                <option value="2" selected={shownumber}>SMS</option>
                                <option value="1" selected={showmail}>Email</option>
                                {/* <option value="2" selected={ProfileData?.mobileNumber} >SMS</option>
                                <option value="1" selected={!number} >Email</option> */}
                                
                            </select>
                        </div>

                        {/* {(showmail || !ProfileData?.mobileNumber) && !shownumber ? */}
                        {(showmail) ?
                            <>
                                <div className='col-md-5 px-3'>
                                    
                                </div>
                                <div className='col-md-7 px-3  mt-2 '>
                                    <input type="email"
                                        className='form-control m-1  border-1'
                                        name="email"
                                       // value={ProfileData?.email}
                                        value={EmailPatient}
                                        disabled />
                                </div>
                            </>
                            :
                            null
                        }

                        {/* {(shownumber || ProfileData?.mobileNumber) && !showmail ? */}
                        {(shownumber) ?
                            <>
                                <div className='col-md-5 px-3 mt-2'>
                                </div>
                                <div className='col-md-7 px-3 '>
                                    <input type=""
                                        className='form-control m-1  border-1'
                                        name="mobileNumber"
                                        value={Mobilepatient}
                                        disabled />
                                </div>
                            </>
                            :
                            null
                        }
                        
                        <Card.Body>
                        </Card.Body>
                        <Card.Footer>
                            <b>Important :</b> By clicking on continue below I agree to receive an OTP, <a id='Colortext'>Please make sure your Mobile number is correct including country code with “+” extension, if not please use email to receive One Time Password.</a>
                            <div className='text-center mt-3'>
                                <Button className=" btn btn-primary" onClick={() => navigatePin()}>
                                    Continue
                                </Button>
                            </div>
                        </Card.Footer>
                    </Card >
                </div>
                <div className='col-md-1'></div>
            </div>
            </div>
    )

}