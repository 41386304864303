import React, { useEffect } from 'react';
import "./Profile.css";
import { useNavigate, useLocation } from 'react-router';
import { useWindowWidthAndHeight } from "../Layout Component/CustomHooks";

export default function Profile() {
  useEffect(() => {
    navigate('/ProfileData')
  }, []);
  const navigate = useNavigate();
  const [width] = useWindowWidthAndHeight();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");


  return (
    <>
      <div >
        {width > 1400 ? (
          <>
            <section className='editProfileHeader' >
              <div className='TopSmallCss  '>
                <nav className='bigNavCSS '>
                  <label onClick={() => navigate("/ProfileData")} className={splitLocation[1] === "ProfileData" ? "fa fa-user   bigCSSlabel" : " fa fa-user  "} id="bigCSSlabelactive"  >
                    <a href='#/' className='fontProfile'><br></br>Profile</a>
                  </label>

                  <label onClick={() => navigate("/PartnerDetails")} id='bigCSSlabelactive' className={splitLocation[1] === "PartnerDetails" ? "fa fa-users  bigCSSlabel" : "fa fa-users  "} >
                    <a href='#/' className=' fontProfile'><br></br>Partner Details</a>
                  </label>

                  <label onClick={() => navigate("/MedicalHistory")} id='bigCSSlabelactive' className={(splitLocation[1] === "MedicalHistory" || splitLocation[1] === "PreviousFertilityTreatment" ||splitLocation[1] === "TreatMentWithUs" ||splitLocation[1] === "PreviousPregnancy" ||splitLocation[1] === "Allergies"
                   ||splitLocation[1] === "PreviousProcedure" ||splitLocation[1] === "Prescription" ||splitLocation[1] === "DrugReminder" ) ? "fa fa-file-medical  bigCSSlabel" : "fa fa-file-medical "}  >
                    <a href='#/' className='fontProfile'><br></br>Medical history</a>
                  </label>

                  <label onClick={() => navigate("/Appointment")} id='bigCSSlabelactive' className={splitLocation[1] === "Appointment" ? "fa fa-calendar-check  bigCSSlabel" : "fa fa-calendar-check "}  >
                    <a href='#/' className='fontProfile'><br></br>Appointment</a>
                  </label >

                  <label onClick={() => navigate("/Conditions")} id='bigCSSlabelactive' className={splitLocation[1] === "Conditions" ? "fa fa-hand-holding-medical  bigCSSlabel" : "fa fa-hand-holding-medical "} >
                    <a href='#/' className='fontProfile'><br></br>Conditions</a>
                  </label >
                  <label onClick={() => navigate("/Invoices")} id='bigCSSlabelactive' className={splitLocation[1] === "Invoices" ? "fa fa-file-invoice-dollar  bigCSSlabel" : "fa fa-file-invoice-dollar "} >
                    <a href='#/' className='fontProfile'><br></br>Invoices</a>
                  </label >
                  <label onClick={() => navigate("/CheckList")} id='bigCSSlabelactive' className={splitLocation[1] === "CheckList" ? "fa fa-file-invoice-dollar  bigCSSlabel" : "fa fa-file-invoice-dollar "} >
                    <a href='#/' className='fontProfile'><br></br>Checklist</a>
                  </label >
                  <label onClick={() => navigate("/ObservationResult")} id='bigCSSlabelactive' className={splitLocation[1] === "ObservationResult" ? "fa fa-magnifying-glass-plus  bigCSSlabel" : "fa fa-magnifying-glass-plus "} >
                    <a href='#/' className='fontProfile'><br></br>Observation Result</a>
                  </label >
                  <label onClick={() => navigate("/Documents")} id='bigCSSlabelactive' className={splitLocation[1] === "Documents" ? "fa fa-file-waveform  bigCSSlabel" : "fa fa-file-waveform "} >
                    <a href='#/' className='fontProfile'><br></br>Documents</a>
                  </label >
                  <label onClick={() => navigate("/ProfilePreview")} id='bigCSSlabelactive' className={splitLocation[1] === "ProfilePreview" ? "fa fa-file-waveform  bigCSSlabel" : "fa fa-file-waveform "} >
                    <a href='#/' className='fontProfile'><br></br>Profile Preview</a>
                  </label>
                </nav>
              </div>
            </section>
          </>
        ) : (
          <>
            <section className='editProfileHeader'>
              <div className='TopCss'>
                <nav className='navCSS d-flex '>
                  <label onClick={() => navigate("/ProfileData")} className={splitLocation[1] === "ProfileData" ? "fa fa-user smallCsslabel" : " fa fa-user  "} id="liststyle"  ><br></br>
                    <a href='#/' className='fontProfile'><br></br>Profile</a>
                  </label>

                  <label onClick={() => navigate("/PartnerDetails")} className={splitLocation[1] === "PartnerDetails" ? "fa fa-users smallCsslabel" : " fa fa-users  "} id="liststyle"  ><br></br>
                    <a href='#/' className='fontProfile'><br></br>Partner Details</a>
                  </label>

                  <label  onClick={() => navigate("/MedicalHistory")}  className={(splitLocation[1] === "MedicalHistory" || splitLocation[1] === "PreviousFertilityTreatment" || splitLocation[1] === "TreatMentWithUs" || splitLocation[1] === "PreviousPregnancy" || splitLocation[1] === "Allergies"
                    || splitLocation[1] === "PreviousProcedure" || splitLocation[1] === "Prescription" || splitLocation[1] === "DrugReminder") ? "fa fa-file-medical smallCsslabel" : " fa fa-file-medical "} id="liststyle"  ><br></br>
                    <a href='#/' className='fontProfile'><br></br>Medical history</a>
                  </label>

                  <label  onClick={() => navigate("/Conditions")} className={splitLocation[1] === "Conditions" ? "fa fa-hand-holding-medical smallCsslabel" : " fa fa-hand-holding-medical  "} id="liststyle"  ><br></br>
                    <a href='#/' className='fontProfile'><br></br>Conditions</a>
                  </label>

                  <label  onClick={() => navigate("/Appointment")} className={splitLocation[1] === "Appointment" ? "fa fa-calendar-check smallCsslabel" : " fa fa-calendar-check  "} id="liststyle"  ><br></br>
                    <a href='#/' className='fontProfile'><br></br>Apppointment</a>
                  </label>

                  <label  onClick={() => navigate("/Invoices")} className={splitLocation[1] === "Invoices" ? "fa fa-file-invoice-dollar smallCsslabel" : " fa fa-file-invoice-dollar  "} id="liststyle"  ><br></br>
                    <a href='#/' className='fontProfile'><br></br>Invoices</a>
                  </label>
                  <label  onClick={() => navigate("/CheckList")} className={splitLocation[1] === "CheckList" ? "fa fa-file-invoice-dollar smallCsslabel" : " fa fa-file-invoice-dollar  "} id="liststyle"  ><br></br>
                    <a href='#/' className='fontProfile'><br></br>Checklist</a>
                  </label>

                  <label  onClick={() => navigate("/ObservationResult")} className={splitLocation[1] === "ObservationResult" ? "fa fa-magnifying-glass-plus smallCsslabel" : " fa fa-magnifying-glass-plus  "} id="liststyle"  ><br></br>
                    <a href='#/' className='fontProfile'><br></br>Observation Result</a>
                  </label>

                  <label  onClick={() => navigate("/Documents")} className={splitLocation[1] === "Documents" ? "fa fa-file-waveform smallCsslabel" : " fa fa-file-waveform  "} id="liststyle"  ><br></br>
                    <a href='#/' className='fontProfile'><br></br>Documents</a>
                  </label>

                  <label  onClick={() => navigate("/ProfilePreview")} className={splitLocation[1] === "ProfilePreview" ? "fa fa-address-card smallCsslabel" : " fa fa-address-card "} id="liststyle"  ><br></br>
                    <a href='#/' className='fontProfile'><br></br>Profile Preview</a>
                  </label>
                </nav>
              </div>
            </section>
          </>
        )
        }
      </div>


    </>
  )
}


















