import React from 'react';
import { useLocation } from "react-router-dom";
import {  Link } from 'react-router-dom'
import './MedicalHistoryNav.css';
import moment from 'moment';
var UserData;
export default function MedicalHistoryNav() {
  UserData = JSON.parse(localStorage.getItem("UData"));
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  return (
        <>
          <div className='card-header mb-2'>
            <div className="row">
              <div className="col">
                <h5>Medical History</h5>
              </div>
              <div className="col text-right">
                ( {UserData?.firstName} {UserData?.lastName} , {moment(UserData?.birthday).format("DD-MMM-YYYY")} ({UserData?.age}) ,{UserData?.gendercodeName} )
              </div>
            </div>
          </div>
          <section class="ftco-section ">
            <ul class="nav nav-tabs ">

              <li className={splitLocation[1] === "MedicalHistory" ? "active nav-item" : ""}>
                <Link to='/MedicalHistory' className='nav-link nav-link-Medical'>Patient Medical History</Link>
              </li>

              <li className={splitLocation[1] === "TreatMentWithUs" ? "active nav-item" : ""}>
                <Link to='/TreatMentWithUs' className='nav-link nav-link-Medical' >Treatment with Us</Link>
              </li>

              <li className={splitLocation[1] === "PreviousFertilityTreatment" ? "active nav-item" : ""}>
                <Link to='/PreviousFertilityTreatment' className='nav-link nav-link-Medical' >Previous Fertility Treatment</Link>
              </li>
              <li className={splitLocation[1] === "PreviousPregnancy" ? "active nav-item" : ""}>
                <Link to='/PreviousPregnancy' className='nav-link nav-link-Medical' > Previous Pregnancy</Link>
              </li>
              <li className={splitLocation[1] === "Allergies" ? "active nav-item" : ""}>
                <Link to='/Allergies' className='nav-link nav-link-Medical' >Allergies</Link>
              </li>
              <li className={splitLocation[1] === "PreviousProcedure" ? "active nav-item" : ""}>
                <Link to='/PreviousProcedure' className='nav-link nav-link-Medical' >Previous Procedure</Link>
              </li>
              <li className={splitLocation[1] === "Prescription" ? "active nav-item" : ""}>
                <Link to='/Prescription' className='nav-link nav-link-Medical' >Prescription</Link>
              </li>
              <li className={splitLocation[1] === "DrugReminder" ? "active nav-item" : ""}>
                <Link to='/DrugReminder' className='nav-link nav-link-Medical' >Drug Reminder</Link>
              </li>


            </ul>
          </section>
          {/* <div className="row m-0 ">
              <div className="col-md-12">
                {showTreatment ?
                  <TreatmentWithUS />
                  : null
                }
                {showPreviousProcedure ?
                  <PreviousProcedure />
                  : null
                }
                {showPreviousPregnancy ?
                  <PreviousPregnancy />
                  : null
                }
                {showAllergies ?
                  <Allergies />
                  : null
                }
                {showPrevFertTreat ?
                  <PreviousFertilityTreatment />
                  : null
                }
                {showPrescription ?
                  <Prescription />
                  : null
                }
                {showDrugReminder ?
                  <DrugReminder />
                  : null
                }
                {
                  showPatientMedical ?
                    <div className="row justify-content-center">
                      <div className="col-md-11 mb-3 ">

                        <Card>
                          <Card.Body>
                            <div>
                              <Card.Body>
                                <div className="step-progress">
                                  <div className="justify-content-center">
                                    <MedicalStepHead
                                      uData={uData}
                                      navigateTo={navigateTo.bind(this)}
                                      stepCount={steps.length}
                                      step={index}
                                    />
                                  </div>

                                  <div className="stepsPlaceHolder justfiy-content-center  ">
                                    <stat.comp
                                      next={next.bind(this)}
                                      setIsLoading={setIsLoading}
                                      isLoading={isLoading}
                                      MedicalHistory={MedicalHistory}
                                      setMedicalHistory={setMedicalHistory}

                                    />
                                  </div>
                                  <hr />
                                  <MedicalStepFoot
                                    uData={uData}
                                    step={index}
                                    stepCount={steps.length}
                                    MedicalHistory={MedicalHistory}
                                    next={next.bind(this)}
                                    previous={previous.bind(this)}
                                  />
                                  <hr />
                                </div>
                              </Card.Body>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </div>
                    : null
                }
              </div>
            </div> */}
        </>
  )
}