import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button } from "react-bootstrap";
import { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setLoader } from "../../Store/Reducers/UserReducer";
import { Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import LoginFun from "../../Layout Component/Loginfun";
import $, { data } from "jquery";
import "./ChatCase.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

export default function ChatCase({}) {
  const logindata = LoginFun();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [show, setShow] = useState(false);
  const url = process.env.REACT_APP_OXAR_API_URL;
  const handleClose = () => setShow(false);
  const [SelectedFile, SetSelectedFile] = useState([]);
  const [Files, SetFiles] = useState([]);
  const [Data, setData] = useState({});
 

  const updateNewState = (value) => {
    debugger;
    setData({ ...Data, ...value });
  };

  const sessionCheck = async () => {
    var currentTime = "";
    currentTime = Math.round(new Date().getTime() / 1000);
    var Expiretime = localStorage.getItem("ExpirretokenTime");
    if (Expiretime < currentTime) {
      sessionStorage.getItem("isLoggedIn", false);
      Swal.fire({
        title: "Session Expired",
        text: "Your session has been expired! Please login again",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Okay",
      }).then((result) => {
        logindata.logout();
      });
    }
  };

  const getAllCaseByID = async () => {
    debugger;
    try {
      dispatch(setLoader(true));
      await axios(
        `${url}api/Profile/GetNotesbehalfoFCASE?CaseId=${state?.Casedata?.id}`
      ).then((res) => {
        SetSelectedFile(res.data.content);
        //   for (let index = 0; index < res.data.content.length; index++) {
        //     debugger;
        //     if (res.data.content[index]?.createdby == "# OxAR App") {
        //       setfileUploadebypatient("By Patient");
        //     } else {
        //       setfileUploadebyclinician("By Clinician");
        //     }
        //   }
        SetFiles(res.data.content?.fileName);
      });
      dispatch(setLoader(false));
    } catch {
      dispatch(setLoader(false));
    }
  };
  const getDocument = async (Msg) => {
    debugger;
    var a = document.createElement("a"); //Create <a>
    a.href = "data:application/octet-stream;base64," + Msg.documentbody; //Image Base64 Goes here
    a.download = Msg.fileName; //File name Here
    a.click();
    toast.success("file downloaded successfully!", {
      autoClose: 5000,
      hideProgressBar: true,
      newestOnTop: false,
      closeOnClick: true,
      rtl: false,
      pauseOnFocusLoss: true,
      draggable: true,
      pauseOnHover: true,
      className: "toast-message",
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    createComments();
    handleClose();
  };

  const createComments = async () => {
    debugger;
    dispatch(setLoader(true));

    if (Data?.title !== undefined) {
      var payload = new FormData();
      payload.append("file", Data?.image);
      try {
        dispatch(setLoader(true));
        await axios
          .post(
            `${url}api/Profile/CreateNotesForCase?Title=${Data?.title}&Description=${Data?.description}&CaseID=${state?.Casedata?.id}`,
            payload
          )
          .then((res) => {
            if (res.status === 200) {
              toast.success("Comment created successfully!", {
                autoClose: 5000,
                hideProgressBar: true,
                newestOnTop: false,
                closeOnClick: true,
                rtl: false,
                pauseOnFocusLoss: true,
                draggable: true,
                pauseOnHover: true,
                className: "toast-message",
              });
              getAllCaseByID();
              setShow(false);
             setData({});
            }
          });
        dispatch(setLoader(false));
      } catch {
        dispatch(setLoader(false));
      }
    } else {
      toast.warn("Please fill mandatory fields", {
        autoClose: 5000,
        hideProgressBar: true,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        className: "toast-warn",
      });
      dispatch(setLoader(false));
      return;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getAllCaseByID();
    }, 1000);
    sessionCheck();
  }, []);
  return (
    <Card className=" border-0 ">
      <Card.Body>
        <Card.Text>
          <div className=" row ">
            <div className="col-md-1"></div>
            <div className="col-md-10">
              <div className="row">
                <div className="col-md-3 fs-3 ">
                  <a type="submit" onClick={() => navigate(-1)}>
                    <i class="fa-solid fa-circle-arrow-left"></i>
                  </a>
                </div>
                <div className="col-md-5 fw-bold fs-6 ">
                  <div className="col">
                    {" "}
                    {state?.Casedata?.title} ({state?.Casedata?.casetype})
                  </div>
                  <div className="col">
                    <span>
                      {moment(state?.Casedata?.createdOn).format("DD-MMM-YYYY")}
                    </span>
                  </div>
                  <div className="col">
                    <span>{state?.Casedata?.statuscodename}</span>
                  </div>
                </div>
                {state?.Casedata?.statuscodename === "In Progress" && (
                  <div className="col-md-3 mt-2">
                    <Button
                      className="btn btn-primary  "
                      onClick={() => setShow(true)}
                    >
                      + Add Comments
                    </Button>
                    <i className="fa-solid fa-rotate ms-4 fs-5"
                     onClick={() => getAllCaseByID()}
                    ></i>
                  </div>
                )}
              </div>

              {SelectedFile && (
                <div className="row mt-5">
                  <div className="col-md-12">
                    <div class="comments-list">
                      <div className="card ">
                        <div className="card-body custome_msg-scroll p-4">
                          {SelectedFile.map((data, index) => {
                            console.log("data--", data);
                            return data?.createdby === "# OxAR App" ? (
                              <div className="media">
                                <div className="media-body">
                                  <div className="row">
                                    <div className="columnmsg mb-3">
                                      <div className="incoming_msg">
                                        <div className="row">
                                          {/* <div className="col-md-1">
                                            <img
                                              className="file-image"
                                              src={ patientimg }
                                              alt=""
                                            />
                                          </div> */}
                                          <div className="col-md-3 ">
                                            <div className="col-md-8">
                                              <p className="font-weight-bold ">
                                                <span>
                                                  {data?.createdby ===
                                                  "# OxAR App"
                                                    ? "By Patient"
                                                    : "By Clinician"}
                                                </span>
                                                <span className="ms-2">
                                                  {moment(
                                                    data?.createdOn
                                                  ).format("DD-MMM-YYYY")}
                                                </span>
                                              </p>
                                            </div>

                                            <div className="col-md-11">
                                              <h6 className="mt-3 ">
                                                <i class="fa fa-user"></i>
                                                <span className="ms-3   fw-light">
                                                  {data?.subject}
                                                </span>
                                              </h6>
                                              <h6 className="mt-1 text-secondary fw-bold">
                                                {" "}
                                                {data?.description}
                                              </h6>
                                              <p
                                                className=" fssmall "
                                                onClick={() =>
                                                  getDocument(data)
                                                }
                                              >
                                                {data?.fileName}
                                              </p>
                                            </div>
                                          </div>
                                          {/* <div className="col-md-1 mt-5">
                                            <img
                                              className="file-image"
                                              src={patientimg}
                                              alt=""  
                                            />
                                          </div> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="media">
                                <div className="media-body">
                                  <div className="row">
                                    <div className="columnmsg mb-3">
                                      <div className="incoming_msg">
                                        <div className="row  float-right">
                                          <div className="col-md-9 float-right ">
                                            <div className="col-md-12 float-right">
                                              <p className="fw-5 text font-weight-bold">
                                                <span>
                                                  {data?.createdby ===
                                                  "# OxAR App"
                                                    ? "By Patient"
                                                    : "By Clinician"}
                                                </span>
                                                <span className="ms-2">
                                                  {moment(
                                                    data?.createdOn
                                                  ).format("DD-MMM-YYYY")}
                                                </span>
                                              </p>
                                            </div>

                                            <div className="col-md-12 float-right">
                                              <h6 className="mt-3">
                                                <i class="fa fa-user"></i>
                                                <span className="ms-3">
                                                  {data?.subject}
                                                </span>
                                              </h6>
                                              <p
                                                className=" fssmall "
                                                onClick={() =>
                                                  getDocument(data)
                                                }
                                              >
                                                {data?.fileName}
                                              </p>
                                            </div>
                                          </div>
                                          {/* <div className="col-md-1  mt-5 float-right">
                                            <img
                                              className="file-image"
                                              src={doctorimg}
                                              alt=""
                                            />
                                          </div> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-1"></div>
          </div>

          <Modal show={show} onHide={handleClose} size="lg">
            <form>
              <Modal.Header closeButton>
                <Modal.Title>Add Comments</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="ms-3 row">
                  <label className="col-sm-3 col-form-label ">
                    Title
                    <span className="required"></span> :
                  </label>
                  <div className="col-sm-8 mb-3 d-flex">
                    <input
                      className="form-control m-1   border-1"
                      placeholder="Enter case title"
                      name="title"
                      id="title"
                      onChange={(e) =>
                        updateNewState({ title: e.target.value })
                      }
                      value={Data?.title}
                    />
                  </div>

                  <label className="col-sm-3 col-form-label">Comment:</label>
                  <div className="col-sm-8 mb-3 d-flex">
                    <textarea
                      className="m-1  border-1"
                      id="description"
                      placeholder="Enter description of case"
                      name="description"
                      onChange={(e) =>
                        updateNewState({ description: e.target.value })
                      }
                      defaultValue={Data?.description}
                      rows="5"
                      cols="70"
                      style={{ border: "1px solid #ced4da" }}
                    />
                  </div>
                  <label className="col-sm-3 col-form-label ">
                    Upload file :
                  </label>
                  <div className="col-sm-8 ">
                    <input
                      type="file"
                      id="fileJsUpload"
                      name="file"
                      onChange={(e) =>
                        updateNewState({ image: e?.target?.files[0] })
                      }
                      multiple
                    />
                    <div className="col-sm-12 mt-2">
                      <span className="" id="file_input_help">
                        (jpg, jpeg, png, xlsx, word, pdf, mp4, mp3, docx, doc,
                        txt)
                      </span>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button className="btn btn-primary" onClick={handleSubmit}>
                  Save
                </Button>
                <Button
                  variant="secondary"
                  className="btn btn-primary"
                  onClick={handleClose}
                >
                  Close
                </Button>
              </Modal.Footer>
            </form>
          </Modal>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
