import React, { useState } from 'react'
import { Card } from 'react-bootstrap'
import './Help.css'
import { useNavigate } from 'react-router';
export default function Help() {
  const [accordian, setAccordian] = useState("");
  const [subaccordian, setsubaccordian] = useState("");
  const [subaccordian1, setsubaccordian1] = useState("");
  const [subaccordian2, setsubaccordian2] = useState("");
  const [subaccordian3, setsubaccordian3] = useState("");
  const navigate = useNavigate();
  return (
    <>
      <div className="HLP container">
        <Card className='HLP borderprofile '>
          <Card.Header className='  fs-5 contactusfont text-dark'>
          <div className="row">
                        <div className="col-6">
                            <h5>Help Content</h5>
                        </div>
                        <div className="col-6 text-right">
                            &nbsp;    <a type="submit" onClick={() => navigate("/Home")}>
                                <i style={{ fontSize: "24px" }} class="fa-solid fa-circle-arrow-left"></i>
                            </a>
                        </div>
                    </div>
          </Card.Header>
          <Card.Body className='bg-white'>
            <Card.Text className='row'>
              {/* step wizard for Medical History */}
              <div className="col-md-1"></div>
              <div className='col-xl-10 col-lg-10 col-md-10 col-sm-6   mt-0'>
                <Card.Body>
                  <div id="IdToBeAppent1">
                    <div className=" mb-2 mt-1 ps-3 pe-3   bgHeader">
                      <div className='card-header mt-2 d-flex row' onClick={() => {
                        if (accordian === "1") {
                          setAccordian("")
                        } else {
                          setAccordian("1")
                        }
                      }}>
                        <div className='col-md-6'> <h4 className='fs-6 text-start'>Cookie Policy</h4></div>
                        <div className='col-md-6 text-end '>
                          {
                            (accordian === "1") ?
                              <i className={`fa fa-minus  pointer me-2  fs-4 `} onClick={() => setAccordian("")} ></i>
                              :
                              <i className={`fa fa-plus pointer me-2  fs-4 `} onClick={() => setAccordian("1")}></i>
                          }
                        </div>
                      </div>
                      {
                        (accordian === "1") &&
                        <div className="card-header bg-white row">

                          {/* <div className="col-md-12 mt-1">
                            <h5>1) Before login WITHOUT enabling third party Cookie, in portal at bottom it shows a message.</h5>
                            <img src="assets\img\cookienew.png" alt="" className="responsive" />

                          </div>
                          <div className="col-md-12 mt-3">
                            <h5>2) After login to portal WITHOUT Enable third party cookie, portal gives an error. Click
                              Okay or Check for enable third party cookie by clicking on  Browser settings. </h5>
                            <img src="assets\img\CookieIssuenew.png" alt="" className="responsive" />
                          </div> */}


                          <div className="col-md-12 mt-3">
                            <div className=" mb-2 mt-1 ps-3 pe-3   bgHeader" onClick={() => setsubaccordian(!subaccordian)}>
                              <div className='card-header mt-2 d-flex row'>
                                <div className='col-md-6'> <h4 className='fs-6 text-start'>Enable Third Party Policy</h4></div>
                                <div className='col-md-6 text-end'>{
                                  <i className={`fa fa-${subaccordian ? "minus" : 'plus'}  pointer me-2  fs-4 `} ></i>
                                }

                                </div>
                              </div>
                              {
                                subaccordian &&
                                <div className="card-header bg-white row">

                                  <div className="col-md-12 mt-1">
                                    <h5>1) Before login WITHOUT enabling third party Cookie, in portal at bottom it shows a message.</h5>
                                    <img src="assets\img\ThirdParty1.png" alt="" className="responsive" />
                                  </div>
                                  <div className="col-md-12 mt-1">
                                    <h5>2) Go to &gt; Privacy and Security and click &gt; Cookies and other site data </h5>
                                    <img src="assets\img\ThirdParty2.png" alt="" className="responsive" />
                                  </div>
                                  <div className="col-md-12 mt-1">
                                    <h5>3) Click &gt; Allow all cookies and close the tab then Refresh the portal site
                                      https://oxar.health and login again. </h5>
                                    <img src="assets\img\ThirdParty3.png" alt="" className="responsive" />
                                  </div>
                                </div>
                              }
                            </div>
                          </div>

                          <div className="col-md-12 mt-3">
                            <div className=" mb-2 mt-1 ps-3 pe-3   bgHeader" onClick={() => setsubaccordian1(!subaccordian1)}>
                              <div className='card-header mt-2 d-flex row'>
                                <div className='col-md-6'> <h4 className='fs-6 text-start'>Enable Third Party Cookie in Incongnito Browser</h4></div>
                                <div className='col-md-6 text-end'>{
                                  <i className={`fa fa-${subaccordian1 ? "minus" : 'plus'}  pointer me-2  fs-4 `} ></i>
                                }

                                </div>
                              </div>
                              {
                                subaccordian1 &&
                                <div className="card-header bg-white row">

                                  <div className="col-md-12 mt-1">
                                    <h5>1) Uncheck the block third party cookies check box if checked </h5>
                                    <img src="assets\img\CaptureIncognito.png" alt="" className="responsive" />
                                  </div>
                                </div>
                              }
                            </div>

                          </div>
                          <div className="col-md-12 mt-3">
                            <div className=" mb-2 mt-1 ps-3 pe-3   bgHeader" onClick={() => setsubaccordian2(!subaccordian2)}>
                              <div className='card-header mt-2 d-flex row'>
                                <div className='col-md-6'> <h4 className='fs-6 text-start'>Enable  Cookie in Safari(iPhone/iPad iOS 11)</h4></div>
                                <div className='col-md-6 text-end'>{
                                  <i className={`fa fa-${subaccordian2 ? "minus" : 'plus'}  pointer me-2  fs-4 `} ></i>
                                }

                                </div>
                              </div>
                              {
                                subaccordian2 &&
                                <div className="card-header bg-white row">

                                  <div className="col-md-12 mt-1">
                                    <h5>1) Open your Settings</h5>
                                    <h5>2) Scroll down and select Safari.</h5>
                                    <h5>3) Under Privacy & Security, select “Block Cookies”.</h5>
                                    <h5>4) Select “Always Allow” or “Allow from Websites I Visit”.</h5>
                                    <img src="assets\img\iphone11.png" alt="" className="responsive" />
                                  </div>
                                </div>
                              }
                            </div>

                          </div>
                          <div className="col-md-12 mt-3">
                            <div className=" mb-2 mt-1 ps-3 pe-3   bgHeader" onClick={() => setsubaccordian2(!subaccordian2)}>
                              <div className='card-header mt-2 d-flex row'>
                                <div className='col-md-6'> <h4 className='fs-6 text-start'>To
                                  enable cookies in Safari (iPhone/iPad iOS 10)</h4></div>
                                <div className='col-md-6 text-end'>{
                                  <i className={`fa fa-${subaccordian2 ? "minus" : 'plus'}  pointer me-2  fs-4 `} ></i>
                                }

                                </div>
                              </div>
                              {
                                subaccordian2 &&
                                <div className="card-header bg-white row">

                                  <div className="col-md-12 mt-1">
                                    <h5>1) Open your Settings</h5>
                                    <h5>2) Scroll down and select Safari.</h5>
                                    <h5>3) Under Privacy & Security, select “Block Cookies”.</h5>
                                    <h5>4) Select “Always Allow” or “Allow from Websites I Visit”.</h5>
                                    <img src="assets\img\iphone10.png" alt="" className="responsive" />
                                  </div>
                                </div>
                              }
                            </div>

                          </div>
                          <div className="col-md-12 mt-3">
                            <div className=" mb-2 mt-1 ps-3 pe-3   bgHeader" onClick={() => setsubaccordian3(!subaccordian3)}>
                              <div className='card-header mt-2 d-flex row'>
                                <div className='col-md-6'> <h4 className='fs-6 text-start'>To
                                  enable cookies in Safari browser</h4></div>
                                <div className='col-md-6 text-end'>{
                                  <i className={`fa fa-${subaccordian3 ? "minus" : 'plus'}  pointer me-2  fs-4 `} ></i>
                                }

                                </div>
                              </div>
                              {
                                subaccordian3 &&
                                <div className="card-header bg-white row">
                                  <div className="col-md-12 mt-1">
                                    <h5>1) Go to the Safari</h5>
                                    <h5>2) Select Preferences.</h5>
                                    <img src="assets\img\safariFirst.png" alt="" className="responsive" />
                                  </div>

                                  <div className="col-md-12 mt-1">
                                    <h5>3) Go to the Privacy.</h5>
                                    <h5>4) Uncheck Block all cookies, if check.</h5>
                                    <h5>5) Uncheck Prevent cross-site tracking, if check.</h5>
                                    <img src="assets\img\safaritwo.png" alt="" className="responsive" />
                                  </div>
                                </div>
                              }
                            </div>

                          </div>
                        </div>
                      }
                    </div>
                    <div className=" mb-2 ps-3 pe-3   bgHeader"  >
                      <div className='card-header mt-2 d-flex row' onClick={() => {
                        if (accordian === "2") {
                          setAccordian("")
                        } else {
                          setAccordian("2")
                        }
                      }} >
                        <div className='col-md-6'> <h4 className='fs-6  text-start'>New Patient Login</h4></div>
                        <div className='col-md-6 text-end'>{
                          (accordian === "2") ?
                            <i className={`fa fa-minus  pointer me-2  fs-4 `} onClick={() => setAccordian("")} ></i>
                            :
                            <i className={`fa fa-plus pointer me-2  fs-4 `} onClick={() => setAccordian("2")}></i>
                        }

                        </div>
                      </div>
                      {
                        (accordian === "2") &&
                        <div className="card-header bg-white row">

                          <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 text-start mt-2'>
                            <div className="panel" >
                              <div className="row">
                                <div className="col-md-12 " >
                                  <h5>1) Go on the Portal link in your Browser : - https://oxar.health</h5>
                                  <h5>2) Click &gt; LOGIN button</h5>
                                  <img src="assets\img\loginpage.png" alt="" className="responsive mt-2" />
                                </div>
                                <div className="col-md-12 mt-3">
                                  <h5>3) Enter the Credential and Click &gt; SIGN IN button <br />
                                    Example:-<br />
                                    - User Name: patientnew***************.com <br />
                                    - Password:  ***********
                                  </h5>
                                  <img src="assets\img\PatientLogin2.png" alt="" className="responsive" />
                                </div>
                                {/* <div className="col-md-12 mt-3">
                                    <h5>4) After Clicking the SIGN-IN button Create new password by enter password in below fields and then Click &gt; Continue button
                                    </h5>
                                    <img src="assets\img\LoginImageChangePassword.png " alt="" className="responsive" />
                                  </div>
                                  <div className="col-md-12 mt-3">
                                    <h5>5) Select  the Country Code and Enter the Phone Number   &gt; Click Send Code button / Call Me button
                                    </h5>
                                    <img src="assets\img\STEP4NewLogin.png" alt="" className="responsive" />
                                  </div>
                                  <div className="col-md-12 mt-3">
                                    <h5>6) Enter the Verification Code  &gt; Click Verify Code button
                                    </h5>
                                    <img src="assets\img\Step5NewLogin.png" alt="" className="responsive" />
                                  </div> */}
                                <div className="col-md-12 mt-3">
                                  <h5>4) Select the Logged in Method and click on Send verify code button</h5>
                                  <img src="assets\img\MFA1.png" alt="" className="responsive" />
                                </div>
                                <div className="col-md-12 mt-3">
                                  <h5>5) Enter the OTP and click on Verify button</h5>
                                  <img src="assets\img\MFA2.png" alt="" className="responsive" />
                                </div>

                                <div className="col-md-12 mt-3">
                                  <h5>6) click on continue button</h5>
                                  <img src="assets\img\MFA3.png" alt="" className="responsive" />
                                </div>

                               <div className="col-md-12 mt-3">
                                <h5>7) Successfully Logged in</h5>
                                <img src="assets\img\Confirm Page.png" alt="" className="responsive" />
                              </div>

                              <div className="col-md-12 mt-3">
                                <h5>8) For Edit Profile &gt;&gt; Click Edit button</h5>
                                <img src="assets\img\Edit Section Profile.png" alt="" className="responsive" />
                              </div>

                              <div className="col-md-12 mt-3">
                                <h5>9) Edit Your Profile </h5>
                                <img src="assets\img\EditProfilePage.png" alt="" className="responsive" />
                              </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                    <div className=" mb-2 ps-3 pe-3   bgHeader" >
                      <div className='card-header mt-2 d-flex row' onClick={() => {
                        if (accordian === "3") {
                          setAccordian("")
                        } else {
                          setAccordian("3")
                        }
                      }}>
                        <div className='col-md-6'> <h4 className='fs-6  text-start'>Forgot Password / Reset Password</h4></div>
                        <div className='col-md-6 text-end'>{
                          (accordian === "3") ?
                            <i className={`fa fa-minus  pointer me-2  fs-4 `} onClick={() => setAccordian("")} ></i>
                            :
                            <i className={`fa fa-plus pointer me-2  fs-4`} onClick={() => setAccordian("3")}></i>
                        }

                        </div>
                      </div>
                      {
                        (accordian === "3") &&
                        <div className="card-header bg-white row">
                          <div className='col-xl-2 col-lg-2 col-md-4 col-sm-6 text-start'>
                            {/* <img className='w-50 h-100' src={metadata?.imageLink} width="50" height={10} alt="test" /> */}
                          </div>
                          <div className="row">
                            <div className="col-md-12 mt-1">
                              <h5>1) Click on &gt; Forgot Password Button </h5>
                              <img src="assets\img\ForgotPwd1.png"
                              alt="" className="responsive" />

                            </div>
                            <div className="col-md-12 mt-1">
                              <h5>2) Enter the Email address and click &gt; Send verification code </h5>
                              <img src="assets\img\ForgotPwd2.png" alt="" className="responsive" />
                            </div>
                            <div className="col-md-12 mt-1">
                              <h5>3) You will receive the code on the Email </h5>

                              <img src="assets\img\ForgotPwd3.png" alt="" className="responsive" />
                            </div>

                            <div className="col-md-12 mt-1">
                              <h5>4) Enter the Code and Click &gt; Verify Code </h5>

                              <img src="assets\img\ForgotPwd4.png" alt="" className="responsive" />
                            </div>

                            <div className="col-md-12 mt-1">
                              <h5>5) Now, click &gt; Continue </h5>
                              <img src="assets\img\ForgotPwd5.png" alt="" className="responsive" />
                            </div>

                            <div className="col-md-12 mt-1">
                              <h5>6) Enter your New Password then enter Confirm New Password and
                                click &gt; Continue
                              </h5>
                              <img src="assets\img\ForgotPwd6.png" alt="" className="responsive" />
                            </div>

                            <div className="col-md-12 mt-1">
                              <h5>7) When the Password is Successful Reset click &gt; Ok </h5>
                              <img src="assets\img\ForgotPwd7.png" alt="" className="responsive" />
                            </div>

                            <div className="col-md-12 mt-1">
                              <h5>8) Click &gt; Login and login with New Password </h5>
                              <img src="assets\img\loginpage.png" alt="" className="responsive" />
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                    {/* <div className=" mb-2 ps-3 pe-3   bgHeader" >
                      <div className='card-header mt-2 d-flex row' onClick={() => {
                        if (accordian === "4") {
                          setAccordian("")
                        } else {
                          setAccordian("4")
                        }
                      }}  >
                        <div className='col-md-6'> <h4 className='fs-6 text-start'>Forgot Password/Reset Password button on mobile /IPad</h4></div>
                        <div className='col-md-6 text-end'>{
                          (accordian === "4") ?
                            <i className={`fa fa-minus  pointer me-2  fs-4 `} onClick={() => setAccordian("")} ></i>
                            :
                            <i className={`fa fa-plus pointer me-2  fs-4 `} onClick={() => setAccordian("4")}></i>
                        }
                        </div>
                      </div>
                      {
                        (accordian === "4") &&
                        <div className="card-header bg-white row">
                          <div className='col-xl-2 col-lg-2 col-md-4 col-sm-6 text-start'>
                          </div>
                          <div className="row">
                            <div className="col-md-12 mt-1">
                              <h5>1) Click on &gt; Three line Icon </h5>
                              <img src="assets\img\Mobileview.png" alt="" className="mobileResponsive" width="100" height="100" />
                            </div>
                            <div className="col-md-12 mt-1">
                              <h5>2) Forgot Password Button </h5>
                              <img src="assets\img\ForgotPassmobile.png" alt="" className="mobileResponsive" width="100" height="100" />
                            </div>

                          </div>
                        </div>
                      }
                    </div> */}
                    <div className=" mb-2 ps-3 pe-3   bgHeader">
                      <div className='card-header mt-2 d-flex row' onClick={() => {
                        if (accordian === "5") {
                          setAccordian("")
                        } else {
                          setAccordian("5")
                        }
                      }}>
                        <div className='col-md-6'> <h4 className='fs-6  text-start'>Session Time Out</h4></div>
                        <div className='col-md-6 text-end'>{
                          (accordian === "5") ?
                            <i className={`fa fa-minus  pointer me-2  fs-4 `} onClick={() => setAccordian("")} ></i>
                            :
                            <i className={`fa fa-plus pointer me-2  fs-4 `} onClick={() => setAccordian("5")}></i>
                        }
                        </div>
                      </div>
                      {
                        (accordian === "5") &&
                        <div className="card-header bg-white row">
                          <div className="row">
                            <div className="col-md-12 mt-1">
                              <h5>1) When Session is expired, an error popup on portal screen, Click  &gt; Okay </h5>

                              <img src="assets\img\sessionexpired.png" alt="" className="responsive" />
                            </div>
                            <div className="col-md-12 mt-1">
                              <h5>2) Now, come to home screen of the portal and click &gt; Login then enter your User Name &
                                Password and login again.
                                After that, you’ll successfully login to the portal.
                              </h5>
                              <img src="assets\img\loginpage.png" alt="" className="responsive" />
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </Card.Body>
              </div>
              <div className="col-md-1"></div>
            </Card.Text>
          </Card.Body>
        </Card >
        <div className='row'>
          <h4 className='text-center'>Still have a question?</h4>
          <div>
            <h4 className='text-center font '>
              If you cannot find answer to your question in our help, You can always
              Contact us. We will answer to you shortly!
            </h4>
          </div>
        </div>
      </div>
    </>
  )
}
