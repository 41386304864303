import { useState } from "react";
import {  useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import { b2cPolicies } from "../authconfig";
import { useDispatch } from "react-redux";
import { setLoader, setUser } from "../Store/Reducers/UserReducer";
import Swal from "sweetalert2";
import axios from "axios";


function LoginFun() {
    
    const url = process.env.REACT_APP_OXAR_API_URL
    const dispatch = useDispatch();
    const [istoken, setistoken] = useState(Boolean);
    const [loggedIn, setloggedIn] = useState(Boolean);
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [authToken, setAuthToken] = useState([]);
    const [accessTokenExpStamp, setaccessTokenExpStamp] = useState();
    const navigate = useNavigate();
    // LoginFunction

    const getToken = async () => {
        
        if (accounts.length > 0) {
            if (accounts[0].idTokenClaims.jobTitle !== "Consultant") {
                try {
                    dispatch(setLoader(true));
                    var currentAccount = instance.getAccountByUsername(accounts[0].username);
                    if(currentAccount == null){
                        dispatch(setLoader(false));
                    }
                    var silentRequest = {
                        scopes: ["openid", "profile", "https://oxar.onmicrosoft.com/c7e3a74b-6465-4ef2-9700-3ab424414914/Write", "https://oxar.onmicrosoft.com/c7e3a74b-6465-4ef2-9700-3ab424414914/Read"],
                        account: currentAccount,
                        forceRefresh: false
                    };
                    const tokenResponse = await instance.acquireTokenSilent(silentRequest).catch(error => {
                        debugger
                        if (error.name === "InteractionRequiredAuthError") {
                            Swal.fire({
                                title: 'Server Error',
                                text: "Please try again later.",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonText: 'Okay',
                                //cancelButtonText: 'Browser settings',
                            }).then(result => {
                                if (result.isConfirmed) {
                                    logout();
                                }
                            })
                        }
                        else {
                            debugger
                            var loggedIn = sessionStorage.getItem("isLoggedIn");
                            if (loggedIn) {
                                Swal.fire({
                                    title: 'Session Expired',
                                    text: "Your session has been expired! Please login again ",
                                    icon: 'warning',
                                    showCancelButton: false,
                                    confirmButtonText: 'Okay',
                                }).then(result => {
                                    logout();
                                });
                            }
                        }   
                    });
                    debugger
                    sessionStorage.setItem("AuthToken", tokenResponse.accessToken);
                    setAuthToken(tokenResponse);
                    if (tokenResponse.accessToken !== "") {
                        dispatch(setLoader(true));
                        sessionStorage.setItem('TOKEN', true);
                         localStorage.setItem("ISTOKEN", true) ;
                         debugger
                        localStorage.setItem("ExpirretokenTime", tokenResponse.account.idTokenClaims.exp);
                        dispatch(setLoader(true));
                        localStorage.setItem("isLogin", true);
                        sessionStorage.setItem('isLoggedIn', true);
                        getProfileDetails();
                        localStorage.setItem("mfalogin",false);
                        //navigate('/LoginMFA');
                        //navigate('/PatientInfo');
                        dispatch(setLoader(true));
                    }
                    checkAccount();
                    dispatch(setLoader(false));
                } catch (e) {
                    console.log("Error on token get --> ", e)
                }
            }
        }
    }
    const getProfileDetails = async () => {
        
        try {
            dispatch(setLoader(true));
            const res = await axios.get(`${url}/api/CrmUser/GetByB2CId`);
            if (res) {
                debugger
                dispatch(setUser(res.data.content));
                localStorage.setItem("testpatientvalue", res.data.content.testpatient) 
                 localStorage.setItem("UserName", res.data.content.fullname) 
                 localStorage.setItem("UserID", res.data.content.id) 
                 localStorage.setItem("ClinicName", res.data.content.clinicName) 
                localStorage.setItem("ClinicLogoURL", res.data.content.clinicLogoURL)
                localStorage.setItem('PatientGender', res.data.content.gendercode);
                localStorage.setItem('PatientEmailformfa', res.data.content.emailaddress1);
                localStorage.setItem('PatientMobileformfa', res.data.content.mobilephone);
                navigate('/PatientInfo');
            }
            dispatch(setLoader(false));
        }
        catch (e) {
            debugger
            console.log(e);
            if (e.response.data.content == null) {
                Swal.fire({
                    title: 'Login Issue',
                    text: "Patient not exist, Please contact your clinic",
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Okay',
                }).then(result => {
                    logout();
                });
            }
            dispatch(setLoader(false));
        }
    }
    const logout = async () => {
        
        try {
            console.log("is Authenticated --> ", isAuthenticated);
            sessionStorage.setItem('isLoggedIn', '') // alt
            localStorage.setItem("isLogin", false);
            // setRout(false);
            debugger
           localStorage.clear();
            sessionStorage.clear()
            dispatch(setUser({}));
            const loginResponse = await instance.logout();
            console.log("loginREsponse--->", loginResponse)
            setistoken(false);
        }
        catch (e) {
            console.log(e.message);
            dispatch(setLoader(false));
        }
    };

    //   Session logout function
    function checkAccount() {
        debugger
        var loggedIn = sessionStorage.getItem("isLoggedIn");
         loggedIn = !!instance.getAccount();
        if (loggedIn) {
            setaccessTokenExpStamp(accounts[0].idTokenClaims.exp);
            localStorage.setItem("setaccessTokenExpStamp", accounts[0].idTokenClaims.exp)
            var Expiretime = sessionStorage.getItem("ExpirretokenTime");
            let currentTimeStamp = Math.round(new Date().getTime() / 1000);
            if (Expiretime < currentTimeStamp) {
                logout();
            }
        }
    }
    const ResetPassword = async () => {
        
        try {
            instance.loginRedirect(b2cPolicies.authorities.resetPassword).then(response => {
            }).catch((e) => {
                console.log("Reset password error ---> ", e);
            })
        } catch (err) {
            console.log("Reset password error --> ", err);
        }
    };

    return {
        checkAccount, logout, getToken, istoken, setistoken, loggedIn, instance, isAuthenticated, authToken,
        setAuthToken, ResetPassword, accessTokenExpStamp
    }
}
export default LoginFun;



