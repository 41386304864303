import axios from "axios";
import { useState } from "react";

const url = process.env.REACT_APP_OXAR_API_URL;
const TokenKey = process.env.REACT_APP_TOKENKEY;

const GettheHmToken = async () => {
    
        axios.get(`${url}/api/ExtendedToken/GetExtendedAuth?Key=${TokenKey}`).then((response) => {
            if (response.data['statusCode'] == 200) {
                var tokenimportant = "hmacauth" + " " + response.data['content']
                localStorage.setItem('HMTokenForApis', tokenimportant);
                return 
            }
        }, (error) => {
            
  });   
};


export {GettheHmToken};
