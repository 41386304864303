import React, {  useEffect } from 'react'
import '../../Profile Component/StepWizard.css';
import Swal from "sweetalert2";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setLoader } from "../../Store/Reducers/UserReducer"
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginFun from '../../Layout Component/Loginfun';

export default function MedicalStepFoot(props) {
    const dispatch = useDispatch();
    // for loading spinner
    const url = process.env.REACT_APP_API_URL;
    const { MedicalHistory } = props;
    const { next } = props;
    const logindata = LoginFun();
    useEffect(() => {
        sessionCheck();
    }, []);

    const sessionCheck = async () => {
        var currentTime = ''
        currentTime = (Math.round(new Date().getTime() / 1000));
        var Expiretime = localStorage.getItem("ExpirretokenTime");
        
        if (Expiretime < currentTime) {
            sessionStorage.getItem('isLoggedIn', false)
            Swal.fire({
                title: 'Session Expired',
                text: "Your session has been expired! Please login again",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Okay',
            }).then(result => {
                logindata.logout();
            })
        }
    }

    const updateprofile = async (event) => {
        debugger
        sessionCheck();
        try {
            dispatch(setLoader(true));
            let MainUserId = localStorage.getItem("UserID");
            const res = await axios.put(`${url}/api/Profile/UpdateQuestionaire?userId=${MainUserId}`, MedicalHistory);
            if (res.status === 200) {
                toast.success('Successfully updated!', {
                    autoClose: 5000,
                    hideProgressBar: true,
                    newestOnTop: false,
                    closeOnClick: true,
                    rtl: false,
                    pauseOnFocusLoss: true,
                    draggable: true,
                    pauseOnHover: true,
                    className: 'toast-message',
                    toastId:'medicalsuccess'
                }
                )
                dispatch(setLoader(false));
            }
        }
        catch (e) {
                if(e.response.data.errorMessage == "Invalid json"){
                    toast.error('Please dont use special characters in fields ', {
                    autoClose: 5000,
                    hideProgressBar: true,
                    newestOnTop: false,
                    closeOnClick: true,
                    rtl: false,
                    pauseOnFocusLoss: true,
                    draggable: true,
                    pauseOnHover: true,
                    theme: "colored"
                    }
                    )
                    dispatch(setLoader(false));
                 return
                }

            else{
                toast.error('Error while updating!', {
                    autoClose: 5000,
                    hideProgressBar: true,
                    newestOnTop: false,
                    closeOnClick: true,
                    rtl: false,
                    pauseOnFocusLoss: true,
                    draggable: true,
                    pauseOnHover: true,
                    className: 'toast-warn',
                    toastId:'medicalerror'
                }
                )
                dispatch(setLoader(false));
                return
            }
        }
    }

    return (
        <>
            <div className=' d-flex row justify-content-between'>
                <div className="col-md-4 text-start">
                    <button disabled={props.step < 1} onClick={() => { props.previous(); }}
                        className=" btn btn-primary ">PREVIOUS</button>
                </div>
                <div className="col-md-4 text-end me-4">
                    {!(props.step >= props.stepCount - 1) ?
                        <button onClick={() => next()} className=" btn btn-primary ">SAVE / NEXT</button>
                        :
                        <button className="  btn btn-primary "
                            onClick={() => updateprofile()} >UPDATE</button>
                    }
                </div>
            </div>
            {/* <ToastContainer /> */}
        </>

    )
}
