import React, { useLayoutEffect } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { useMsal, useAccount } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import { getUser, resetUser, setUser } from "../Store/Reducers/UserReducer";
import { b2cPolicies, loginRequest } from "../authconfig";
import Swal from "sweetalert2";
import axios from "axios";
import LoadingSpinner from '../Layout Component/LoadingSpinner';
import "./header.css";
import { useWindowWidthAndHeight } from "./CustomHooks";
import SmallScreensNavbar from "./SmallScreensNavbar";
import PartnerDetails from '../Profile Component/PartnerDetails';
import LoginFun from './Loginfun';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import $ from "jquery";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

let check = 10;
let mfalogin;
let theToken = false;
let sidenav;
let PatientTestValue = false;
let ClinicName = "";
export default function Header({
  setloggedIn, setRout
}) {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { useState, useEffect } = React;
  const [istoken, setistoken] = useState(Boolean);
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const uData = useSelector(getUser);
  const [isLoading, setIsLoading] = useState(false);
  const url = process.env.REACT_APP_OXAR_API_URL
  const [showDropdown, setShowDropdown] = useState(false);
  const data = LoginFun();
  var username = '';
  debugger
  username = localStorage.getItem("UserName");
  theToken = localStorage.getItem("ISTOKEN");
  sidenav = localStorage.getItem("ShowSideNav");
  PatientTestValue =  localStorage.getItem("testpatientvalue");
  ClinicName = localStorage.getItem("ClinicName");

  const ClinicLogoURLMain = localStorage.getItem("ClinicLogoURL");
  useEffect(() => {
    mfalogin = localStorage.getItem("mfalogin");
    sidenav = localStorage.getItem("ShowSideNav");
    if(sidenav == "true"){
      
      localStorage.setItem('ShowSideNav', "true");
    }
    if (mfalogin === 'false') {
      $(".testD").removeClass('d-none');
    }
    var currentTime = ''
    currentTime = (Math.round(new Date().getTime() / 1000));
    var Expiretime = localStorage.getItem("ExpirretokenTime");
    if (Expiretime != null) {
      
      if (Expiretime < currentTime) {
        
        sessionStorage.getItem('isLoggedIn', false)
        Swal.fire({
          title: 'Session Expired',
          text: "Your session has been expired! Please login again",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'Okay',
        }).then(result => {
          data.logout();
        })
      }
    }

    
    if (data?.isAuthenticated && theToken == 'false' || theToken == null) {
      data.getToken();
    }

  }, [data.isAuthenticated]);

  useEffect(() => {
    
    mfalogin = localStorage.getItem("mfalogin");
    sidenav = localStorage.getItem("ShowSideNav");
    if (theToken) {
      setRout(true)
    }
    const splitLocation = pathname.split("/");
  }, [splitLocation])

  // Refersh button
  const refreshPage = () => {
    setIsLoading(true);
    window.location.reload();
  }

  const toggleClick = (e) => {


    if (window.matchMedia('(min-width: 992px)').matches) {
      e.preventDefault();

      if ($(".bodys").hasClass("sidebar-folded")) {
        $(".bodys").removeClass("sidebar-folded")
      } else {
        $(".bodys").addClass('sidebar-folded')
      }
    } else if (window.matchMedia('(max-width: 991px)').matches) {
      e.preventDefault();
      if ($(".bodys").hasClass("sidebar-open")) {
        $('.bodys').removeClass('sidebar-open');

      } else {
        $('.bodys').addClass('sidebar-open');

      }

    }
  }
  const selectLink = (data, i) => {
    $("." + check).removeClass("active");
    $("." + data).addClass("active");
    check = i;
    document.body.classList.remove('sidebar-open');
  }

  return (
    <>
      <div className="page-wrapper">
        {
          !theToken ? <>  <nav className="navbar1  justify-content-end">
            <ul className="navbar-nav m-2">

              <a>
                <img src="assets\img\MicrosoftTeams-imageOXAR.png" className='' style={{ width: "185px" }} id="CLinicLogo" alt="" onClick={() => navigate('/Home')} />

                <a onClick={() => data?.ResetPassword()} className='btn  text-white text-light border-light  float-right' >
                  <i className="fa fa-key m-0 text-light" aria-hidden="true" />
                  &nbsp;   &nbsp;Forgot Password</a>
              </a>
            </ul>
          </nav></>
            : null
        }
        {
          theToken ?
            <nav className="navbar1" >
              <div className="navbar-content p-2 pt-1">
                {
                  theToken && mfalogin == 'false' && <>
                    <a class="sidebar-toggler "><i onClick={(e) => toggleClick(e)} class="toggleDisplay text-white fa fa-bars"></i></a>
                  </>
                }
                {
                  theToken && mfalogin == 'false' ?
                    <img src={ClinicLogoURLMain} id="CLinicLogo1" className='pt-2' alt="" />
                    : null
                }
                {
                  mfalogin == 'true' && <>
                    <img src="assets\img\MicrosoftTeams-imageOXAR.png" className='' style={{ width: "185px" }} id="CLinicLogo" alt=""  onClick={(e) => {
                      debugger
                        data?.logout();
                        localStorage.clear();
                        dispatch(setUser({}));
                      }}/>
                    <div className='float-right p-3' onClick={(e) => {
                      debugger
                      data?.logout();
                      localStorage.clear();
                      dispatch(setUser({}));
                     
                    }}>
                      <i className="fa fa-home text-light " aria-hidden="true" ></i>&nbsp;
                      <a type="submit" className='fontheader text-light ms-1 '> Home</a>
                    </div>
                  </>
                }
                {
                  theToken && mfalogin == 'false' ? <>
                    <div className='float-right check dropdown'>
                      <DropdownButton className=' fontheader pt-2 check' style={{ border: 'none', width: 'auto' }} menuVariant="dark"
                        title={username}
                        onMouseLeave={() => setShowDropdown(false)}
                        onMouseOver={() => setShowDropdown(true)}
                        align={{ lg: 'start' }}
                        id="dropdown-menu-align-end"
                        show={showDropdown}
                      >
                        <Dropdown.Item onClick={refreshPage}> <li><a id="refreshname"><i className="fa-solid fa-rotate me-2"></i>Refresh</a></li></Dropdown.Item>
                        <Dropdown.Item onClick={(e) => {
                          data?.logout();
                          debugger
                          localStorage.clear();
                          dispatch(setUser({}));
                        }}><li id="refreshname"><a id="refreshname">{" "}<i className="fa-solid fa-right-from-bracket me-2">{" "}</i>{" "}
                          Logout</a></li> </Dropdown.Item>
                      </DropdownButton>
                    </div>
                  </>
                    :
                    null
                }
                
              </div>
            </nav>
            : null}
      </div>

      {(theToken) && <div className='testD'>
        <nav className="sidebar  bg-white">
          <div className="sidebar-header">
            <a className="sidebar-brand">
              <img src="assets\img\MicrosoftTeams-imageOXAR.png" className='sidebar-brand' id="" style={{ width: "100%" }} alt="" />
            </a>
            <div className="sidebar-toggler not-active" >
              <i className="fa fa-bars text-white m-2" aria-hidden="true" onClick={(e) => toggleClick(e)}></i>
            </div>
          </div>
          <div className="sidebar-body">
            <ul className="nav">
              <li className="nav-item  PDComponent 21">
                <a className={splitLocation[1] === "PatientInfo" || splitLocation[1] === "ProfileData" ? "nav-link 1 prof active" : "nav-link 1 prof"} title='Profile' onClick={() => { navigate('/PatientInfo'); selectLink('prof', 1); }}>
                  <i className="fa fa-user  " data-feather="box"></i>
                  <span className="link-title">Profile</span>
                </a>
              </li>
              <li className="nav-item ">
                <a className={splitLocation[1] === "PartnerDetails" ? "nav-link allergy 2 active" : "nav-link allergy 2"} title='Partner Details' onClick={() => { navigate('/PartnerDetails'); selectLink('allergy', 2); }}>
                  <i className="fa fa-users  " data-feather="box"></i>
                  <span className="link-title">Partner Details</span>
                </a>
              </li>
              <li className="nav-item ">
                <a className={splitLocation[1] === "MedicalHistory" || splitLocation[1] === "TreatmentOutcome" || splitLocation[1] === "PrescriptionDetail" || splitLocation[1] === "TreatMentWithUs" || splitLocation[1] === "PreviousFertilityTreatment" || splitLocation[1] === "PreviousPregnancy" || splitLocation[1] === "Allergies" || splitLocation[1] === "PreviousProcedure"
                  || splitLocation[1] === "Prescription" || splitLocation[1] === "DrugReminder"
                  ? "nav-link Condition 3 active" : "nav-link Condition 3"} title='Medical History' onClick={() => { navigate('/MedicalHistory'); selectLink('Condition', 3); }}>
                  <i className="fa fa-file-medical " data-feather="box"></i>
                  <span className="link-title">Medical History</span>
                </a>
              </li>
              <li className="nav-item ">
                <a className={splitLocation[1] === "Appointment" ? "nav-link invoices 4 active" : "nav-link invoices 4"} title='Appointment' onClick={() => { navigate('/Appointment'); selectLink('invoices', 4); }}>
                  <i className="fa fa-hand-holding-medical" data-feather="box"></i>
                  <span className="link-title">Appointment</span>
                </a>
              </li>
              <li className="nav-item ">
                <a title='Conditions' className={splitLocation[1] === "Conditions" ? "nav-link testResult 5 active" : "nav-link testResult 5"} onClick={() => { navigate('/Conditions'); selectLink('testResult', 5); }}>
                  <i className="fa fa-calendar-check" data-feather="box"></i>
                  <span className="link-title">Conditions</span>
                </a>
              </li>
              {
                  PatientTestValue == true ||  PatientTestValue == "true" ?
              <li className="nav-item ">
                <a title='Conditions' className={splitLocation[1] === "CaseCustomer"|| splitLocation[1] === "ChatCase" ? "nav-link case 12 active" : "nav-link case 12"} onClick={() => { navigate('/CaseCustomer'); selectLink('case', 12); }}>
                  <i className="fa fa-calendar-check" data-feather="box"></i>
                  <span className="link-title">Case</span>
                </a>
              </li>:null
              }


              <li className="nav-item ">
                <a className={splitLocation[1] === "Invoices" ? "nav-link observations 6 active" : "nav-link observations 6"} title='Invoices' onClick={() => { navigate('/Invoices'); selectLink('observations', 6); }}>
                  <i className="fa fa-file-invoice-dollar " data-feather="box"></i>
                  <span className="link-title">Invoices</span>
                </a>
              </li>
              <li className="nav-item">
                <a className={splitLocation[1] === "CheckList" || splitLocation[1] === "CheckListDetails" ? "nav-link  prescript 7 active" : "nav-link  prescript 7"} title='Check List' onClick={() => { navigate('/CheckList'); selectLink('prescript', 7); }}>
                  <i className="fa fa-file-invoice-dollar   " data-feather="box"></i>
                  <span className="link-title">Checklist</span>
                </a>
              </li>
              <li className="nav-item">
                <a className={splitLocation[1] === "ObservationResult" || splitLocation[1] === "HL7ObservationDetail" ? "nav-link digiImage 8 active" : "nav-link digiImage 8"} title='Observation Result' onClick={() => { navigate('/ObservationResult'); selectLink('digiImage', 8); }}>
                  <i className="fa fa-magnifying-glass-plus" data-feather="box"></i>
                  <span className="link-title">Observation Result</span>
                </a>
              </li>
              {
              ClinicName != "Hull" ?
              <li className="nav-item">
                <a className={splitLocation[1] === "Documents" ? "nav-link docs 9 active" : "nav-link docs 9"} title='Documents' onClick={() => { navigate('/Documents'); selectLink('docs', 9); }}>
                  <i className=" fa fa-file-waveform" data-feather="box"></i>
                  <span className="link-title">Documents</span>
                </a>
              </li>:null
              }

              <li className="nav-item">
                <a className={splitLocation[1] === "ProfilePreview" ? "nav-link consents 10 active" : "nav-link consents 10"} title='Profile Preview' onClick={() => { navigate('/ProfilePreview'); selectLink('consents', 10); }}>
                  <i className="fa fa-address-card " data-feather="box"></i>
                  <span className="link-title">Profile Preview</span>
                </a>
              </li>
              <li className="nav-item">
                <a className={splitLocation[1] === "KbArticle" || splitLocation[1].includes("KbArticleDetails") ? "nav-link KBase 11 active" : "nav-link KBase 11"} title='Profile Preview' onClick={() => { navigate('/KbArticle'); selectLink('KBase', 11); }}>
                  <i className="fa fa-graduation-cap  " aria-hidden="true" ></i>
                  <span className="link-title">Knowledge Base</span>
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>}
      
    </>
  )
}