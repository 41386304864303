import React, { useEffect, useLayoutEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setLoader } from "../../Store/Reducers/UserReducer"
import './HL7ObservationResult.css';
import axios from "axios";
import Swal from "sweetalert2";
import LoginFun from './../../Layout Component/Loginfun';
import moment from "moment";
var DataOfObservation = {}
var UserData;
export default function HL7ObservationDetail(props) {
    UserData = JSON.parse(localStorage.getItem("UData"));
    const dispatch = useDispatch();
    const url = process.env.REACT_APP_OXAR_API_URL;
    const [observartinalldatarequest, setobservartinalldatarequest] = useState();
    const navigate = useNavigate();
    const [ocr1, setocr1] = useState();
    const [ocr2, setocr2] = useState();
    const data = LoginFun();
    // const [DataOfObservation, setDataOfObservation] = useState();

    useLayoutEffect(() => {
        if (props.SendData !== undefined) {
            localStorage.setItem("ObservationData", JSON.stringify(props.SendData));
            DataOfObservation = props.SendData;
        }
        else {
            var daata = JSON.parse(localStorage.getItem("ObservationData"))
            DataOfObservation = daata;
        }
    }, []);

    useEffect(() => {
        dispatch(setLoader(true));
        getdata();
        getdataallrequest();
        var currentTime = ''
        currentTime = (Math.round(new Date().getTime() / 1000));
        var Expiretime = localStorage.getItem("ExpirretokenTime");
      
        if (Expiretime < currentTime) {
            
            sessionStorage.getItem('isLoggedIn', false)
            Swal.fire({
                title: 'Session Expired',
                text: "Your session has been expired! Please login again",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Okay',
            }).then(result => {
                data.logout();
            })
        }
    }, []);

    const getdata = async () => {
      
        try {
            dispatch(setLoader(true));
            let ID = localStorage.getItem("ObservationId");
            await axios(`${url}api/TDLObservation/GetObservationRequestByObservationId?id=${ID}`).then((res) => {
              
                var ocr1234 = "A" + " " + "RE - Observations/Performed Service to follow" + " " + res.data.content[0].placeOrderNumber + " " + res.data.content[0].fillerOrderNumber + " " + "with the status" + " " + DataOfObservation?.orderStatus
                setocr1(ocr1234);
                var Abcd = [];
                for (var i = 0; i < res.data.content.length; i++) {

                    let trail = DataOfObservation.consultingDoctor + " " + "ordered" + " " + (res.data.content[i].identifier) + " " + res.data.content[i].text + " " + res.data.content[i].placeOrderNumber + " " + res.data.content[i].fillerOrderNumber + " requested";
                    Abcd.push(trail)
                }
                setocr2(Abcd);
            })
            dispatch(setLoader(false));
        }
        catch {
        }
    };

    const getdataallrequest = async () => {
        try {
            dispatch(setLoader(true));
            let ID = localStorage.getItem("ObservationId");
            await axios(`${url}api/TDLObservation/GetObservationResultByObservationIdH17?id=${ID}`).then((res) => {
                setobservartinalldatarequest(res.data.content);
            });
            dispatch(setLoader(false));
        }
        catch {
        }
    };

    return (
        <>
            <div className="panel-group">
                <div className="card" >
                    {/* <div className="panel-heading" style={{ backgroundColor: '#172f43' }}>
                            <div className='col-md-10 '>
                                <div className='row'>
                                    <div className='col-md-1'>
                                        <a id='ObservationResultatag' href="#/ObservationResult" className=" fs-2" onClick={() => navigate("/ObservationResult")}>
                                            <i class="fa-solid fa-circle-arrow-left"></i>
                                        </a>
                                    </div>
                                    <div className='col-md-11 justify-content-center' id='hl7report'>
                                        <h4 className='pt-2 fw-bold'><>HL7 Report</></h4>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    <div className='card-header mb-2'>
                        <div className="row">
                            <div className="col">
                                <h5 className='p-2'>HL7 Report </h5>
                            </div>
                            <div className="col text-right">
                                ( {UserData?.firstName} {UserData?.lastName} , {moment(UserData?.birthday).format("DD-MMM-YYYY")} ({UserData?.age}) ,{UserData?.gendercodeName} )

                                &nbsp;    <a id='ObservationResultatag' href="#/ObservationResult" className=" fs-4" onClick={() => navigate("/ObservationResult")}>
                                    <i class="fa-solid fa-circle-arrow-left text-dark"></i>
                                </a></div>
                        </div>
                    </div>
                    <div className="panel-body cardcolor text-dark">
                        <div className="row">
                            <div className="col-md-1 col-lg-1 col-sm-12"></div>
                            <div className="col-md-5 col-lg-5 col-sm-12">
                                <label>Name:</label>&nbsp;
                                {/* {DataOfObservation?.patientgivenname}&nbsp;{DataOfObservation?.patientlastname} */}
                                {DataOfObservation?.patient}
                            </div>
                            <div className="col-md-5 col-lg-5 col-sm-12">
                                <label >Report Produced by:</label>&nbsp;
                                {DataOfObservation?.consultingDoctor}
                            </div>
                            <div className="col-md-1 col-lg-1 col-sm-12"></div>
                        </div>

                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-5">
                                <label for="dob">DOB:</label>&nbsp;
                                {moment(DataOfObservation?.patientdob).format('DD-MMM-YYYY')}
                            </div>
                            <div className="col-md-5">
                                <label for="received">Received:</label>

                            </div>
                            <div className="col-md-1"></div>
                        </div>
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-5">
                                <label for="gender">Gender:</label>&nbsp;
                                {/* <select className="form-select " id="floatingSelect" aria-label="Floating label select example" name="Gender" defaultValue={SendData?.patientgender} > */}
                                {/* <span defaultValue={DataOfObservation?.patientgender}>Female</span> */}
                                {
                                    DataOfObservation?.patientgender === 271400001 ? "Female" :
                                        DataOfObservation?.patientgender === 271400000 ? "Male" :
                                            DataOfObservation?.patientgender === 271400001 ? "Ambiguous" :
                                                DataOfObservation?.patientgender === 271400001 ? "Not applicable" :
                                                    DataOfObservation?.patientgender === 271400001 ? "Unknown" :
                                                        DataOfObservation?.patientgender === 271400001 ? "Other" : null
                                }

                            </div>
                            <div className="col-md-5">
                                <label for="hospital">Hospital No:</label>

                            </div>
                            <div className="col-md-1"></div>
                        </div>


                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-5">
                                <label for="labrefno">Lab Ref No:</label>

                            </div>
                            <div className="col-md-5">
                                <label for="reference">Reference:</label>

                            </div>
                            <div className="col-md-1"></div>
                        </div>

                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-5">
                                <label for="collected">Collected:</label>

                            </div>
                            <div className="col-md-5">
                                <label for="dreport">Report Date:</label>&nbsp;
                                {moment(DataOfObservation?.transactionDateTime).format('DD-MMM-YYYY')}
                            </div>
                            <div className="col-md-1"></div>
                        </div>

                        <hr />

                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-5">
                                <label for="">Visit (PV1):</label>

                            </div>
                            <div className="col-md-6"></div>
                        </div>

                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-5">
                                <label for="cdoctor">Consulting Doctor:</label>
                                {DataOfObservation?.consultingDoctor}
                            </div>
                            <div className="col-md-6"></div>
                        </div>

                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-10">
                                <label for="ocr1">Common Order (ORC):</label>
                                &nbsp;{ocr1}
                            </div>
                            <div className=""></div>
                        </div>

                        <div className="row">
                            {
                                ocr2 ? ocr2.map((data, index) => {
                                    return (
                                        <>
                                            <div className="col-md-1"></div>
                                            <div className="col-md-10">

                                                <label>Observation Request Segment(OBR[{index + 1}])</label>
                                                {data}
                                            </div>
                                            <div classNameName='col-md-1'></div>
                                        </>

                                    );
                                }) : null}
                        </div>

                        <hr />

                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-10 ">


                                <div className='table-responsive'>
                                    <table className="table">
                                        <thead className="thead-light">
                                            <tr>
                                                <td id='Mainheading1'>Test Name</td>
                                                <td id='Mainheading2'>Result</td>
                                                <td id='Mainheading3'>Units</td>
                                                <td id='Mainheading4'>Reference Range</td>
                                                <td id='Mainheading5'>Notes</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {observartinalldatarequest && observartinalldatarequest.map((listValue, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{listValue.text}</td>
                                                        <td>{listValue.observationValue}</td>
                                                        <td>{listValue.units}</td>
                                                        <td>{listValue.referenceRange}</td>
                                                        <td>
                                                            {listValue.notes1}
                                                            {listValue.notes2}
                                                            {listValue.notes3}
                                                            {listValue.notes4}
                                                        </td>

                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-md-1"></div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-10">
                                Formatted by OXDH's OX.assisted reproduction

                            </div>
                            <div className=""></div>
                        </div>
                        <h6 style={{ textAlign: 'center' }}>
                            <p>Authorised By: Clinical Pathology, TDL</p>
                        </h6>

                    </div>
                </div>
            </div>
        </>

    )


}
