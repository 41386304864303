import React, { useEffect } from 'react'
import { Card } from 'react-bootstrap'
import { useState } from "react";
import { useNavigate } from 'react-router';
import axios from "axios";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import { useDispatch } from "react-redux";
import { setLoader } from '../Store/Reducers/UserReducer';
import moment from "moment";
import LoginFun from '../Layout Component/Loginfun';
import Swal from "sweetalert2";
import './ObservationResult.css';
var UserData;
export default function ObservationResult(props) {
  UserData = JSON.parse(localStorage.getItem("UData"));
  const { SetSendData } = props;
  const url = process.env.REACT_APP_OXAR_API_URL;
  const datalogin = LoginFun();
  const [observations, setobservations] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pivotarray, setpivotarray] = useState([]);
  const [settings, setSettings] = useState({});
  const [observationsContactdata, setobservationsContactdata] = useState();

  useEffect(() => {
    dispatch(setLoader(true));
    setTimeout(() => {
      getObservations();
      getObservationsbycontactid();
    }, 1000);

    var currentTime = '';
    var Expiretime = localStorage.getItem("ExpirretokenTime");
    currentTime = (Math.round(new Date().getTime() / 1000));
    if (Expiretime < currentTime) {
      sessionStorage.getItem('isLoggedIn', false)
      Swal.fire({
        title: 'Session Expired',
        text: "Your session has been expired! Please login again",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Okay',
      }).then(result => {
        datalogin.logout();
      })
    }
  }, []);

  const getObservations = async () => {
    debugger
    try {
      dispatch(setLoader(true));
      let MainUserId = localStorage.getItem("UserID");
      await axios(`${url}api/TDLObservation/GetObservationResultsByPatientId?id=${MainUserId}`).then((res) => {
        
        setobservations(res.data.content);
        onload(res.data.content);
      });
      dispatch(setLoader(false));
    }
    catch {
    }
  };


  const getObservationsbycontactid = async () => {
    debugger
    try {
      dispatch(setLoader(true));
      let MainUserId = localStorage.getItem("UserID");
        await axios(`${url}api/TDLObservation/GetObservationByContactId?id=${MainUserId}`).then((res) => {
          
          setobservationsContactdata(res.data.content);
          dispatch(setLoader(false));
        });
    }
    catch {
    }
  };


  const onload = async (results) => {
    debugger
    var pivotarray1 = [];
    var datearray = [];
    function formatDate(date) {
      var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      var day = date.getDate();
      var monthIndex = date.getMonth();
      var year = date.getFullYear();
      var time = date.toLocaleTimeString();

      return day + '-' + monthNames[monthIndex] + '-' + year + ' ' + time;
    }

    for (var i = 0; i < results.length; i++) {
      
      var pivottablem = {
        // Date_Of_Test: formatDate(new Date(results[i]["dateTimeofTransaction"])),
        Date_Of_Test: moment(results[i]["dateTimeofTransaction"]).format("DD-MMM-YYYY"),
        Value: results[i]["observationValue"],
        Test: results[i]["text"],
        Unit: results[i]["units"],
        Range: results[i]["referenceRange"],
        Code: results[i]["identifier"],
        Flag: results[i]["abnormalFlags"],
      }
      pivotarray1.push(pivottablem);
      // setpivotarray(pivotarray1);
      datearray.push(results[i]["dateTimeofTransaction"]);
    }
    setpivotarray(pivotarray1);
  };
  const Test = async (data) => {
    
    const sendata = data;
    var Observationid = data.id;
    localStorage.setItem("ObservationId", Observationid);
    SetSendData(sendata)
    navigate('/HL7ObservationDetail')
  }

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>


        <Card className=' border-0 '>
          {/* <Card.Header className=' colorprofile  text-light'><h5>Observation Result</h5></Card.Header> */}
          <div className='card-header mb-2'>
            <div className="row">
              <div className="col">
                <h5>Observation Result </h5>
              </div>
              <div className="col text-right">
                ( {UserData?.firstName} {UserData?.lastName} , {moment(UserData?.birthday).format("DD-MMM-YYYY")} ({UserData?.age}) ,{UserData?.gendercodeName} )
              </div>
            </div>
          </div>
          <Card.Body>
            <Card.Text>
              {/* step wizard for Medical History */}
              <div className='col-md-12   mt-3'>
                <Card.Body>

                  <div className="">
                    <div className='container px-md-0 table-responsive '>
                      <PivotTableUI
                        data={pivotarray}
                        onChange={(s) => setSettings(s)}
                        rows={["Date_Of_Test"]}
                        cols={["Test", "Unit"]}
                        vals={["Value"]}
                        aggregatorName="Last"
                        rendererName="Table Col Heatmap"
                        showAggregationOnValueField="false"
                        {...settings}
                      />
                    </div>

                  </div>
                  <br></br>

                  <div className='table-responsive'>
                    {observations ?
                      <table className="table  table-hover" id="observationsContactdata">
                        <thead className="thead-light">
                          <tr>
                            <th>Name</th>
                            <th>Observation</th>
                            <th>Order Status</th>
                            <th>Status</th>
                            <th>Date/Time of Transaction</th>
                            <th>Consulting Doctor</th>
                            <th>Created on </th>
                            <th>Doctor's Comments</th>
                            <th>Approve</th>
                          </tr>
                        </thead>
                        <tbody>
                          {observationsContactdata && observationsContactdata.map((listValue, index) => {
                            return (
                              <tr key={index} onClick={() => Test(listValue)}>
                                <td onClick={() => navigate('/HL7ObservationDetail')} style={{ cursor: 'pointer' }} className='text-info'>{listValue.name}</td>
                                <td>{listValue.observationbundle}</td>
                                <td>
                                  {
                                    listValue.orderStatus === 271400002 ? "CM -Order is Completed" :
                                      listValue.orderStatus === 271400001 ? "CA -Order was canceled" :
                                        listValue.orderStatus === 271400000 ? "A -Some but not all results available" :
                                          listValue.orderStatus === 271400003 ? "CM -Order is DC -Order was discontinued" :
                                            listValue.orderStatus === 271400004 ? "ER -Error order not found" :
                                              listValue.orderStatus === 271400005 ? "HD -order is on hold" :
                                                listValue.orderStatus === 271400006 ? "IP -In process unspecified" :
                                                  listValue.orderStatus === 271400007 ? "RP -Order has been replaced" :
                                                    listValue.orderStatus === 271400008 ? "SC -In process scheduled" : null
                                  }
                                </td>
                                <td>
                                  {
                                    listValue.status === 271400000 ? "Final" :
                                      listValue.status === 271400001 ? "NA" : null
                                  }

                                </td>
                                <td>{moment(listValue.transactionDateTime).format('DD-MMM-YYYY')}</td>
                                <td>{listValue.consultingDoctor}</td>
                                <td>{moment(listValue.createdOn).format('DD-MMM-YYYY')}</td>
                                <td>{listValue.doctorsComment}</td>

                                <td>
                                  {
                                    listValue.approve === true ? "Yes" : null
                                  }
                                </td>

                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      : null
                    }
                  </div>


                </Card.Body>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>

      </div>
    </>
  )
}
