import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap'
import { useState } from "react";
import { useLocation, useNavigate } from 'react-router';
import axios from "axios";
import moment from "moment";
import LoginFun from './../../Layout Component/Loginfun';
import Swal from "sweetalert2";
import $ from 'jquery';
import './Prescription.css';
import { useDispatch } from "react-redux";
import { setLoader } from "../../Store/Reducers/UserReducer"

export default function PrescriptionDetail() {
  const dispatch = useDispatch();
  const url = process.env.REACT_APP_OXAR_API_URL;
  const [prescription, setprescription] = useState([]);
  const [prescriptionDetails, setprescriptionDetails] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const data = LoginFun();

  useEffect(() => {
    dispatch(setLoader(true));
    setTimeout(() => {
      getAllprescriptionDetails(location.state.rowId);
      getAllPrescription(location.state.rowId);
    }, 1000);
    var currentTime = ''
    currentTime = (Math.round(new Date().getTime() / 1000));
    var Expiretime = localStorage.getItem("ExpirretokenTime");
    if (Expiretime < currentTime) {
      sessionStorage.getItem('isLoggedIn', false)
      Swal.fire({
        title: 'Session Expired',
        text: "Your session has been expired! Please login again",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Okay',
      }).then(result => {
        data.logout();
      })
    }
  }, []);

  //----------------Api to Get the details,Prescription Data By Id-----------------------------------------
  const getAllPrescription = async (id) => {
    dispatch(setLoader(true));
    try {
        let PrecriptionMainId = localStorage.getItem("PrecriptionId");
        await axios(`${url}api/Prescriptions/GetById?id=${PrecriptionMainId}`).then((res) => {
          if (res.data.content.dispatchedDate === "0001-01-01T00:00:00") {
            res.data.content.dispatchedDate = "";
          }
          else {
            res.data.content.dispatchedDate = res.data.content['dispatchedDate'].substring(0, 10)
          }
          if (res.data.content.deliveredDate === "0001-01-01T00:00:00") {
            res.data.content.deliveredDate = "";
          }
          else {
            res.data.content.deliveredDate = res.data.content['deliveredDate'].substring(0, 10)
          }
          if (res.data.content.approvedDate === "0001-01-01T00:00:00") {
            res.data.content.approvedDate = "";
          }
          else {
            res.data.content.approvedDate = res.data.content['approvedDate'].substring(0, 10)
          }
          if (res.data.content.birthDate) {
            res.data.content.birthDate = res.data.content['birthDate'].substring(0, 10)
          }
          setprescription(res.data.content);
          dispatch(setLoader(false));
        });
    }
    catch {
    }
  };

  //----------------Api to Get the details,Prescription Details By Id-----------------------------------------
  const getAllprescriptionDetails = async (id) => {
    dispatch(setLoader(true));
    try {
        let PrecriptionMainId = localStorage.getItem("PrecriptionId");
        await axios(`${url}api/Prescriptions/GetDrugs?id=${PrecriptionMainId}`).then((res) => {

          if (res.data.content) {

            for (var i = 0; i < res.data.content.length; i++) {
              if (res.data.content[i].drugStartDate === "0001-01-01T00:00:00") {
                res.data.content[i].drugStartDate = "";
              }
              else {
                res.data.content[i].approvedDate = res.data.content[i]['approvedDate'].substring(0, 10)
              }
              if (res.data.content[i].drugEndDate === "0001-01-01T00:00:00") {
                res.data.content[i].drugEndDate = "";
              }
              else {
                res.data.content[i].drugEndDate = res.data.content[i]['drugEndDate'].substring(0, 10)
              }
            }
          }
          setprescriptionDetails(res.data.content);
          $("#PrevTreatmentCycleTable").DataTable();
          dispatch(setLoader(false));
        }); 
    }
    catch {
      dispatch(setLoader(false));
    }
  };

  return (
    <>
      <Card className="  ">
        <Card.Header className="  contactusfont d-flex justify-content-between">
          <div className='col-md-2'>
            <h5> Prescription Detail</h5>
          </div>
          <div className='col-md-2 fs-4 text-end'>
            <a type="submit" onClick={() => navigate(-1)}>
              <i class="fa-solid  fa-arrow-circle-left"></i>
            </a>
          </div>
        </Card.Header>
        <Card.Body>
          <Card.Text>
            {/* step wizard for Medical History */}
            <div className='col-md-12  mt-3'>
              <>
                <div className=' m-4 pb-3  '>
                  <div className='row'>
                    <div className='col-md-5 '>
                      <b> <label>Name : </label></b><label> {prescription?.name}</label>
                    </div>
                    <div className='col-md-3'>
                      <b> <label>Chargable : </label></b><label>{prescription?.chargable}</label>
                    </div>
                    <div className='col-md-4 ps-4'>
                      <b><label>Birth Date :  </label></b> <label>{prescription?.birthDate} </label>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-5 '>
                      <b> <label>Contact Name : </label></b><label> {prescription?.contactFullName}</label>
                    </div>
                    <div className='col-md-3'>
                      <b> <label>Delivered Date : </label></b> <label>{prescription?.deliveredDate} </label>
                    </div>

                    <div className='col-md-4 ps-4'>
                      <b> <label>Dispatched Date : </label></b> <label>{prescription?.dispatchedDate}</label>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-5 '>
                      <b> <label>Contact Email : </label></b> <label>{prescription?.emailAddress}</label>
                    </div>
                    <div className='col-md-3'>
                      <b> <label>Approved : </label></b> <label>{prescription?.approved}</label>
                    </div>
                    <div className='col-md-4 ps-4'>
                      <b> <label>Approved Date : </label></b>  <label>{prescription?.approvedDate}</label>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-5 '>
                      <b> <label>Contact Age : </label></b> <label>{prescription?.contactAge}</label>
                    </div>
                    <div className='col-md-3'>
                      <b> <label>Tracking Number : </label></b> <label>{prescription?.trackingNumber}</label>
                    </div>
                    <div className='col-md-4 ps-4'>
                      <b> <label>Prescriber Name  : </label></b> <label>{prescription?.prescriberName}</label>
                    </div>

                  </div>
                  <div className='row'>
                    <div className='col-md-5 '>
                      <b> <label>Contact Clinic : </label></b> <label>{prescription?.contactClinic}</label>
                    </div>
                    <div className='col-md-3'>
                      <b> <label>Precription Cycle : </label></b><label>{prescription?.prescriptionCycle}</label>
                    </div>
                    <div className='col-md-4 ps-4'>
                      <b> <label>Precription Cycle Name : </label></b> <label>{prescription?.prescriptionCycleName}</label>
                    </div>

                  </div>

                  <div className='row'>
                    <div className='col-md-5 '>
                      <b> <label>Contact Address : </label></b> <label>{prescription?.contactAddress}</label>
                    </div>
                    <div className='col-md-3'>
                      <b> <label>To Be Charged To : </label></b> <label>{prescription?.toBeChargedTo}</label>
                    </div>
                    <div className='col-md-4 ps-4'>
                      <b> <label>To Be Charged To Name  : </label></b><label>{prescription?.toBeChargedToName}</label>
                    </div>

                  </div>
                </div>
                <div className=''>
                  <div className="col text-left bg-white-200 mt-4 ">
                    <div className="table-responsive">
                      <table class="table border-0" id="PrevTreatmentCycleTable">
                        <thead class="">
                          <tr>
                            <th> Drug Name</th>
                            <th>Dosage </th>
                            <th>Drug Duration Unit</th>
                            <th>Frequency</th>
                            <th>Drug Start Date</th>
                            <th>Drug End Date</th>
                            <th>Type of Drug</th>
                            <th>Use</th>
                            <th>Time in hour</th>
                            <th>Time in Min</th>
                          </tr>
                        </thead>
                        <tbody>
                          {prescriptionDetails && prescriptionDetails.map((listValue, index) => {
                            return (
                              <tr>
                                <td>{listValue.drugName}</td>
                                <td>{listValue.bcrm_dosage}</td>
                                <td>{listValue.drugDurationUnitName}</td>
                                <td>{listValue.frequency_name}</td>
                                <td>{moment(listValue.drugStartDate).format("DD-MMM-YYYY")}</td>
                                <td>{moment(listValue.drugEndDate).format("DD-MMM-YYYY")}</td>
                                <td>{listValue.typeofdrug}</td>
                                <td>{listValue.use}</td>
                                <td>{listValue.timeInHour}</td>
                                <td>{listValue.timeInMin}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  )
}
