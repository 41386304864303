import React from 'react';

import ReactDOM from 'react-dom';

import './index.css';

import App from './App';

import reportWebVitals from './reportWebVitals';

import { PublicClientApplication } from "@azure/msal-browser"

import { loginRequest, msalConfig } from "./authconfig";

import { MsalProvider } from '@azure/msal-react';

import axios from 'axios'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from "react-redux";
import Store from "./Store";

/**************INTERCEPTORS*******************************/

// Add a request interceptor

axios.interceptors.request.use(

  config => {
    const token = sessionStorage.getItem("AuthToken")
    if (token) {     
      config.headers['Authorization'] = 'Bearer ' + token
      config.headers['Strict-Transport-Security'] = 'max-age=31536000; includeSubDomains;'
      config.headers['Content-Security-Policy'] = "default-src 'self'; style-src 'self' 'unsafe-inline'"
      config.headers['X-Frame-Options'] = "SAMEORIGIN"
      config.headers['X-XSS-Protection'] = '1; mode=block'
      config.headers['X-Content-Type-Options'] = 'nosniff'
    }

    return config
  },

  error => {
    Promise.reject(error)
  }

)
const msalInstance = new PublicClientApplication(msalConfig);

const AppProvider = () => (
  <React.StrictMode>
    <Provider store={Store}>
      <MsalProvider instance={msalInstance}>
        {/* Check */}
        
          <App></App>

      </MsalProvider>
    </Provider>
  </React.StrictMode>
);

ReactDOM.render(<AppProvider />, document.getElementById('root'))
reportWebVitals();