import React, { useEffect } from 'react'
import { Card } from 'react-bootstrap'
import { useState } from "react";
import axios from "axios";
import { Modal } from 'react-bootstrap';
import $ from 'jquery';
import moment from "moment";
import { useDispatch } from "react-redux";
import { setLoader } from '../Store/Reducers/UserReducer';
import Swal from "sweetalert2";
import LoginFun from '../Layout Component/Loginfun';
import './Invoice.css';
var UserData;
export default function Invoices() {
    UserData = JSON.parse(localStorage.getItem("UData"));
    const dispatch = useDispatch();
    const url = process.env.REACT_APP_OXAR_API_URL;
    const [invoices, setInvoices] = useState([]);
    const [show, setShow] = useState(false);
    const [InvoicesDetails, setInvoicesDetails] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = (data) => {
        
        getInvoicesDetails(data.id)
        setShow(true);
    }
    const logindata = LoginFun();

    useEffect(() => {
        dispatch(setLoader(true));
        setTimeout(() => {
            getInvoices();
        }, 1000);
        sessionCheck();
    }, []);
    const sessionCheck = async () => {
        var currentTime = ''
        currentTime = (Math.round(new Date().getTime() / 1000));
        var Expiretime = localStorage.getItem("ExpirretokenTime");
        if (Expiretime < currentTime) {
            sessionStorage.getItem('isLoggedIn', false)
            Swal.fire({
                title: 'Session Expired',
                text: "Your session has been expired! Please login again",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Okay',
            }).then(result => {
                logindata.logout();
            })
        }
    }

    const getInvoices = async () => {
        try {
            let MainUserId = localStorage.getItem("UserID");
            dispatch(setLoader(true));
            await axios(`${url}api/CrmUser/GetAllInvoice?id=${MainUserId}`).then((res) => {
                setInvoices(res.data.content);
                $("#treatmentCycleTable").dataTable();
            });
            dispatch(setLoader(false));;
        }
        catch {
        }
    };

    const getInvoicesDetails = async (id) => {
        try {
            dispatch(setLoader(true));;
            await axios(`${url}api/CrmUser/GetInvoiceByInvoiceId?id=${id}`).then((ress) => {
                setInvoicesDetails(ress.data.content[0]);
            });
            dispatch(setLoader(false));;
        }
        catch {
        }
    };

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <Card className=' border-0 cardcolor'>
                    {/* <Card.Header className=' colorprofile   text-light'><h5>Invoices</h5></Card.Header> */}
                    <div className='card-header mb-2'>
                        <div className="row">
                            <div className="col">
                                <h5>Invoices </h5>
                            </div>
                            <div className="col text-right">
                                ( {UserData?.firstName} {UserData?.lastName} , {moment(UserData?.birthday).format("DD-MMM-YYYY")} ({UserData?.age}) ,{UserData?.gendercodeName} )
                            </div>
                        </div>
                    </div>
                    <Card.Body>
                        <Card.Text>
                            {/* step wizard for Medical History */}
                            <div className='col-md-12'>
                                {/* <div className='d-flex  '>
                                                            <div className='col-md-6'>
                                                                <label>Search : </label>
                                                                <input class=" ms-2 mb-1" type="search" placeholder="Search" aria-label="Search" onChange={(e) => Search(e.target.value)} />
                                                            </div>
                                                        </div> */}
                                <div className="table-responsive">
                                    <table class="table border-0" id="treatmentCycleTable">
                                        <thead >
                                            <tr>
                                                <th>Invoice Name</th>
                                                <th>Invoice Id</th>
                                                <th>Total Amount</th>
                                                <th>Currency</th>
                                                <th>Status</th>
                                                <th>Created On</th>
                                                <th>Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                invoices && invoices.map((listValue, index) => {
                                                    return (
                                                        <tr>
                                                            <td>{listValue.name}</td>
                                                            <td>{listValue.invoicenumber}</td>
                                                            <td>{listValue.totalamount}</td>
                                                            <td>{listValue.transactioncurrencyidName}</td>
                                                            <td>{listValue.statuscode}</td>
                                                            <td>{moment(listValue.createdon).format("DD-MMM-yyyy") ? moment(listValue.createdon).format("DD-MMM-yyyy") : moment(listValue.createdon).format("DD-MMM-yyyy")}</td>
                                                            <td><a className='pointer' disabled={listValue.envelopeid === ""} onClick={(e) => handleShow(listValue)} style={{ color: '#0dcaf0' }}>Details</a></td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                                <Modal size="lg" show={show} onHide={handleClose} >
                                    <Modal.Header closeButton>
                                        <Modal.Title>Invoice Details</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body >
                                        <div className='ms-3 row '>
                                            <div className='col-md-6 d-flex'>
                                                <p><b>Invoice Name :  </b></p>
                                                <p>{InvoicesDetails?.name}</p>
                                            </div>
                                            <div className='col-md-6 d-flex'>
                                                <p><b>Currency :  </b></p>
                                                <p>{InvoicesDetails?.transactioncurrencyidName}</p>
                                            </div>
                                        </div>
                                        <div className='ms-3 row '>
                                            <div className='col-md-6 d-flex'>
                                                <p><b>Invoice Id :  </b></p>
                                                <p>{InvoicesDetails?.invoicenumber}</p>
                                            </div>
                                            <div className='col-md-6 d-flex'>
                                                <p><b>Total Amount :  </b></p>
                                                <p></p>{InvoicesDetails?.totalamount}
                                            </div>
                                        </div>
                                        <div className='ms-3 row '>
                                            <div className='col-md-6 d-flex'>
                                                <p><b>Invoice Date/Time :  </b></p>
                                                <p></p>{moment(InvoicesDetails?.invoicedate).format('DD-MMM-yyyy')}
                                            </div>
                                            <div className='col-md-6 d-flex'>
                                                <p><b>Price List :  </b></p>
                                                <p>{InvoicesDetails?.pricelevelName}</p>
                                            </div>
                                        </div><div className='ms-3 row '>
                                            <div className='col-md-6 d-flex'>
                                                <p><b>Clinic Name :  </b></p>
                                                <p>{InvoicesDetails?.clinicName}</p>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}
